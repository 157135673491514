import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["chartData", "LabelString2"],
  data() {
    return {
      options: {
        scales: {
          yAxes: [
            { stacked: true,
              ticks: {
                beginAtZero: true
              },scaleLabel:{display:true,labelString:this.LabelString2}
            }
          ],
          xAxes:[{ stacked: true }]
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};