
<template>
  
  <div class="Pagina principal">
   
    <br><br>
    <b-row class="log">
      <b class="TitleFormulari">GENERADOR {{nomInstalacio}}</b>
    </b-row>

    <b-container id="organitzacio">
      <b-row>
        <b-col cols="12" md="6"><Resum v-if="idInstalacio && carregarProps && carregarProps2" :idInstalacio="idInstalacio" :idComunitat="codiCE" :acapacitat="capacitat" :aadreca="adreca" :asistema="sistema" :apotencia="potencia"  consum="0" generador="1"/><!--Informacio de l'instalació --></b-col>
       
        <b-col cols="12" md="6"><EsquemaGenerador v-if="idInstalacio && carregarProps" :idInstalacio="idInstalacio" :asistema="sistema" :idComunitat="codiCE"/></b-col></b-row>
        <b-row>
       <b-col cols="12" md="6"><potenciaGenerador v-if="idInstalacio && carregarProps" :asistema="sistema" :idComunitat="codiCE" :idInstalacio="idInstalacio"/></b-col>
      
        <b-col cols="12" md="6"><EnergiaGeneradorCE v-if="idInstalacio && carregarProps" :asistema="sistema" :idComunitat="codiCE" :idInstalacio="idInstalacio"/></b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <EstatBateria v-if="idInstalacio && carregarProps && capacitat!=0" :asistema="sistema" :idInstalacio="idInstalacio"/>
        </b-col>
        
      </b-row>
      
    </b-container>
  </div>
</template>

<script>

import axios from 'axios'
/////////////////Programas que importa////////////////////////
//import TaulaInstalacions from '../components/TaulaInstalacions.vue'
//import DetallsInstalacio from '../components/DetallsInstalacio.vue'
//import TaulaGraficasGeneracio from '../components/TaulaGraficasGeneracio.vue'
//import FullaDetalls from '../components/FullaDetalls.vue'

import EnergiaGeneradorCE from '../components/EnergiaGeneradorCE.vue'
import EsquemaGenerador from '../components/EsquemaGenerador.vue'
import potenciaGenerador from '../components/potenciaGenerador.vue'
import Resum from '../components/Resum.vue'
import EstatBateria from '../components/EstatBateria.vue'



export default {
  data() {
    return {
      user: 'Prueba',
      isLogged: false,
      NomUsuari:'',
      idInstalacio:'',
      bateria: false,
      resposta: "",

      debug: true,

      capacitat: '',
      adreca: '',
      sistema: '',
      potencia: '',
      nomInstalacio: '',
      DetallsApi: '',
      codiCE : '',
      carregarProps : false,
      carregarProps2 : false,
    }},
///////////////////////////Declaracio dels components per poder llegir/////////////////////////////////////////
  components: { 
    //TaulaInstalacions,
   // DetallsInstalacio,
   // TaulaGraficasGeneracio,
    
    //FullaDetalls,
    EnergiaGeneradorCE,
    EsquemaGenerador,
    potenciaGenerador,
    Resum,
    EstatBateria,
  },
  
  mounted(){////////////////////No funciona el root.on, revisar///////////////////

    this.idInstalacio=this.$route.params.id;
    this.comprovaPermisos();
    this.comprovaBateria();
   
  },
  
  methods:{
    edit:function(){
      console.log(this.user)

    },
    hideModal() {
        this.$refs['my-modal'].hide()
      },
      amagaInautoritzat() {
        this.$refs['modal-inautoritzat'].hide()
      },
    async comprovaBateria() {
                  await axios.get (this.$domini+'comprovaBateria?Instalacio='+this.idInstalacio, { withCredentials: true})
                                  .then((response) => {this.resposta = response.data})

                  //(!) aquesta crida també obté altres dades del generador a més de comprovar la bateria
                  this.bateria=this.resposta.bateria;
                  this.capacitat=this.resposta.capacitat;
                  this.sistema=this.resposta.sistema;
                  this.potencia=this.resposta.potencia;
                  this.adreca=this.resposta.adreca;
                  this.nomInstalacio = this.resposta.nomInstalacio;
                  console.log("IDGENERADOR", this.idInstalacio);
                  console.log("CAPACITATGENERADOR", this.capacitat);
                
                  console.log(this.potencia);
                  console.log(this.adreca);
                  this.carregarProps=true;
                  
    },
    async comprovaPermisos() {
      await axios.get(this.$domini+'comunitatDeInstalacio?instalacio='+this.idInstalacio, {withCredentials: true})
      .then((response) => {this.DetallsApi = response.data})
      .catch((err) => {
        console.error(err);
        window.location.href = "/";
      });
      
      this.codiCE=this.DetallsApi.comunitat;
      console.log(this.codiCE)

      /*await axios.get (this.domini+'obteComunitats', {withCredentials: true})
            .then((response) => {this.respostaApi = response.data})
            .catch((err) => {
                    this.$refs['my-modal'].show();
                    console.error(err);
                });  
          console.log(this.respostaApi);
          if (this.respostaApi.missatge=="redireccionar_comunitat" || this.respostaApi.missatge=="redireccionar_consum"){
            if(this.respostaApi.comunitat!=this.codiCE){
              this.$refs['modal-inautoritzat'].show();
              window.location.href = "/";
            }
          }
          else if (this.respostaApi.missatge=="llista") {
            let fl=false
            for (let i=0; i<this.respostaApi.llista.length; i++) {
              if (this.respostaApi.llista[i][1]==this.codiCE){
                fl=true
              }
            }
            if (!fl){
              this.$refs['modal-inautoritzat'].show();
              window.location.href = "/";
            }

          }*/
          this.carregarProps2=true;
          console.log("carregarPrpops", this.carregarProps2)
    }  
  }
 ///////////////////////////////////////////////////////////////////////////////// 
}
</script>


<style scoped>

@media (max-width: 475.98px) {

.TitleFormulari{
color: #0c4105c7;
font-size: x-large;
text-align: center; 
}


}

@media (min-width: 476px) and (max-width: 991.98px) {

.TitleFormulari{
color: #0c4105c7;
font-size: x-large;
text-align: center; 
}


}


@media (min-width: 992px) and (max-width: 1199.98px) {

.TitleFormulari{
color: #0c4105c7;
font-size: x-large;
text-align: center; 
}

}


@media (min-width: 1200px) {


.TitleFormulari{

color: #0c4105c7;
font-size: x-large;
text-align: center;

}
}

</style>