import Vue from 'vue'
import App from './App.vue'
import router from './router'   
import * as VueGoogleMaps from "vue2-google-maps" // En desús
import VueLayers from 'vuelayers'   //Mapa opensource (fem servir aquest)
import 'vuelayers/dist/vuelayers.css' // needs css-loader


Vue.use(VueLayers)


Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC__FlXDToyQISXoUNdbBsmgwyx0A6tRiQ",
    libraries: "places"
  }
});

export const bus = new Vue();
/*

Vue.prototype.$domini =  "http://192.168.0.21:3311/";

Vue.prototype.$domini =  "http://192.168.0.14:8083/";


Vue.prototype.$domini =  "http://emelviscom.ddns.net:3311/";

Vue.prototype.$domini =  "http://192.168.0.15:3310/";


Vue.prototype.$domini = "http://172.20.10.5:8082/";*/



Vue.prototype.$domini =  "http://emelviscom.ddns.net:3311/";

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
