<template> 
  <div id="Bar">
      <br>
      <ChartBarBase :styles="myStyles" :chart-data="chartData"/>
      <div id="TablaEnergias">
        <table id="Energias">
          <tr>
            <td><p>Energia generada</p></td><td><p v-if="EConsumidaTotalBool">Consum</p> </td></tr>
            <tr><th>{{EgeneradaTotal}} kWh</th><th v-if="EConsumidaTotalBool">{{EConsumidaTotal}} kWh</th>
        <!--<th> <p>Total energia generada:</p> {{EgeneradaTotal}} kWh</th>
       <th><p>Total Consum:</p> {{EConsumidaTotal}} kWh</th>-->
          </tr>
        </table>
        <br>
      </div>
    </div>
</template>

<script>

import ChartBarBase from "./ChartBarBase";
import axios from 'axios';
export default {
   props:['Dades'],
  components: { 
    ChartBarBase,
  },
  data() {
    return {
      chartData: null,
      codiInstalacio:"117",
      any:"2023",
      EgeneradaTotal:0,
      EGeneradaApi:"",
      EGeneradatext:"",
      EGeneradaArray:[],
      EConsumidaArray:[],
      mesosArray:[],
      EConsumidaTotal:0,
      EConsumidaTotalBool:0,
    };
  },
  computed: {
    myStyles () {
      return {width: '750px',height:'200px'}
    }
  },
  mounted() {
    this. EGeneradaAxios();
  },
   methods: {
         async EGeneradaAxios(){
          await axios.get(this.$domini + 'energiaCE2?idInstalacio=' + this.Dades[0][0] + "&tipus=1&dia=31&mes=12"+"&any=" + this.any, { withCredentials: true})
            .then((response) => {this.EGeneradaApi = response.data});
            await axios.get(this.$domini + 'energiaConsumicio?idInstalacio=' + this.Dades[0][0] + "&tipus=1&dia=31&mes=12"+"&any=" + this.any, { withCredentials: true})
            .then((response) => {this.EGeneradaApi2 = response.data});
            //console.log(this.EGeneradaApi)
            //this.EGeneradatext=JSON.parse(this.EGeneradaApi)
            //console.log(this.EGeneradatext) 

            let length = Math.min(this.EGeneradaApi["eGenerada"].length, this.EGeneradaApi2["eConsumida"].length, this.EGeneradaApi["temps"].length);

          for (let x = 0; x < length; x++) {
              this.EGeneradaArray.push(this.EGeneradaApi["eGenerada"][x]);
              this.EConsumidaArray.push(this.EGeneradaApi2["eConsumida"][x]);
              this.mesosArray.push(this.EGeneradaApi["temps"][x]);
              this.EgeneradaTotal += this.EGeneradaApi["eGenerada"][x];
              this.EConsumidaTotal += this.EGeneradaApi2["eConsumida"][x];
          }
         //console.log(this.EGeneradaArray)
         //console.log(this.mesosArray)
            this.EgeneradaTotal=parseFloat(this.EgeneradaTotal)
            this.EgeneradaTotal=this.EgeneradaTotal.toFixed(0)
            this.EConsumidaTotal=parseFloat(this.EConsumidaTotal)
            if (this.EConsumidaTotal==0){
              this.EConsumidaTotalBool=false;
              //console.log(this.EConsumidaTotalBool);
              this.fillDataGen();
            }
            else{
              this.EConsumidaTotalBool=true;
              //console.log(this.EConsumidaTotalBool);
              this.fillData();
            }
            this.EConsumidaTotal=this.EConsumidaTotal.toFixed(0)

         //return this.fillData();
        },


}
};
</script>

<style scoped>
#Bar{
  width: 800px;
  /*height:200px;*/
  text-align: center;
  /*display:block;*/
  margin-left:auto;
  margin-right:auto;
}
table,th{
  margin:0 auto;
  text-align:center;
  size:30%;
  padding:1px;
}
#Energias{
  Border-spacing:2px;
}
p{
  font-size: 14px;
  color: #26a300;
}
th{
    font-size:16px;
    color:#2C312B;
    font-weight:bold;
    background-color: #DCFFD1;
    height:30px;
    margin:0%;
    padding: 0%;
    border: 1px solid #95f877;
}
th,td{
    border-radius: 15px;
}
</style>