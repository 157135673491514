<template>
  <div id="Doughnut" v-if="this.passedyes">
    <!--<p v-if="this.comprobacion">Valor mayor al 50</p>
     <p v-else>Valor menor a 50</p>-->
      <ChartDoughnutBase :chart-data="chartData"/>
      </div>
</template>

<script>
import ChartDoughnutBase from "../components_report/ChartDoughnutBase";

export default {
  props: ['passvalue'],
  components: {
    ChartDoughnutBase,
  },
  data() {
    return {
      chartData: null, 
      codiInstalacio:"117",
      any:"2023",
      CAutoconsumApi:"",
      CAutoconsumtext:"",
      Autoconsum:"", 
      AutoconsumNum:"",
      ComplementariAutoconsumNum:"",
      passedyes:false
    };
  },
  mounted() {
    this.CAutoconsumAxios();
  },
  methods: {
    async CAutoconsumAxios(){

            this.Autoconsum=this.passvalue["percAutoconsum"]
            //console.log(this.Autoconsum)
            this.AutoconsumNum = this.Autoconsum*100
      this.AutoconsumNum=this.AutoconsumNum.toFixed(0)
      this.ComplementariAutoconsumNum=100-this.AutoconsumNum

      this.chartData = {
        labels: ["Autoconsum", "De xarxa"],
        datasets:[
          {
            backgroundColor: ["#26A300", "#B6FF9F"],
            hoverBackgroundColor: ["#008f39", "#d1e3f7"],
            borderColor: "#2DBF00 ",
            borderWidth: 0.5,
            data: [this.AutoconsumNum,this.ComplementariAutoconsumNum]
          }
        ]
        }
      
        this.passedyes=true;}}
  
};
</script>


<style scoped>

#Doughnut{
  width: 300px;
  height: 320px;
  text-align: center;
  display:block;
  margin-left:auto;
  margin-right:auto;
}
</style>