<template>
  <!--
    Mostra la quota d'autoconsum en forma de doughnut
  -->
    <div id="Stack">
      <!--<p v-if="this.comprobacion">Valor mayor al 50</p>
       <p v-else>Valor menor a 50</p>-->
        <ChartDoughnutBase :chart-data="chartDataAutoconsum"/>
        </div>
  </template>
  
  <script>
  import ChartDoughnutBase from "../components/ChartDoughnutBase";
  export default {
    components: {
        ChartDoughnutBase,
    },
    props: {
      chartDataAutoconsum: {}
    },
    data() {
      return {
        chartData: {
            labels: ["Autoconsum", "A xarxa"],
            datasets:[

            {
              
              backgroundColor: ["#26A300", "#B6FF9F"],
              
              hoverBackgroundColor: ["#008f39", "#d1e3f7"],
              data: [30, 70],
            }
            ]

        }, 
        codiInstalacio:"" ,
        any:"",
        CAutoconsumApi:"",
        CAutoconsumtext:"",
        Autoconsum:"", 
        AutoconsumNum:"",
        ComplementariAutoconsumNum:"",
      };
    },
    
  };
  </script>
  
  
  <style scoped>
  #Stack {
  width: 200px; 
  height: 200px; 
  text-align: center;
}
  
  </style>