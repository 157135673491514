<template>
  <div class="power-flow">
    <svg width="500" height="500" viewBox="-35 -10 290 290">
      <!-- Solar Icon -->
      <text x="100" y="70" text-anchor="middle" fill="green" font-size="13">{{ this.formatUnit2(inverterPower) }}</text>
      <image
        x="75"
        y="20"
        width="50"
        height="50"
        href="@/assets/solar.png"
        transform="translate(0, -13)"
      />

      <!-- House Icon -->
      <text x="30" y="230" text-anchor="middle" fill="green" font-size="13">{{ this.formatUnit2(consumptionPower) }}</text>
      <image
        x="5"
        y="155"
        width="50"
        height="50"
        href="@/assets/house.png"
        transform="translate(0, 13)"
      
        />

      <!-- Grid Icon -->
      <text x="170" y="230" text-anchor="middle" fill="green" font-size="13">{{ this.formatUnit2(Math.abs(gridPower)) }}</text>
      <image
        x="145"
        y="155"
        width="50"
        height="50"
        href="@/assets/grid.png"
        transform="translate(0, 13)"
      />
        <!-- Battery Icon -->
<text 
 
v-if="SOC !=0"
 x="-5" 
  y="85" 
  text-anchor="middle" 
  fill="green" 
  font-size="13">
  {{ this.SOC.toFixed(2)*100 }}%
</text>

<text 
  v-if="batteryPower !=0" 
  x="-5" 
  y="70" 
  text-anchor="middle" 
  fill="green" 
  font-size="13">
  {{ this.formatUnit2(Math.abs((batteryPower))) }}
</text>

<image
v-if="batteryPower !=0 || SOC !=0" 
  x="-35"
  y="0"
  width="50"
  height="50"
  href="@/assets/battery.png"
  transform="translate(0, 13)"
/>
      <!-- Panel Icon -->
         <text x="225" y="70" text-anchor="middle" fill="green" font-size="13">{{ this.formatUnit2(Math.abs(panelPower )) }}</text>
      <image
        x="200"
        y="-10"
        width="50"
        height="50"
        href="@/assets/panel.png"
        transform="translate(0, 13)"
      />


      <!-- Leaf Icon at the Center -->
      <image
        v-if="consumptionPower <= solarPower"
        x="100"
        y="120"
        width="70"
        height="70"
        href="@/assets/leaf.png"
        transform="translate(-35, -35)"
      />
      
      <!-- Plug Icon at the Center (Else Condition) -->
      <image
        v-else
        x="100"
        y="120"
        width="70"
        height="70"
        href="@/assets/plug.png"
        transform="translate(-35, -35)"
      />

      <!-- Arrows with Animated Arrowhead -->
      <path
        :d="arcPathSolarToGrid"
        v-if="gridPower > 0"
        fill="none"
        stroke="green"
        stroke-width="1.5"
        marker-end="url(#arrowhead)"
      >
        <animate
          attributeName="stroke-dasharray"
          from="0, 300"
          to="300, 0"
          dur="1.8s"
          repeatCount="indefinite"
        />
      </path>
      <path
        :d="arcPathSolarToConsumption"
        v-if="inverterPower > 0 && consumptionPower > 0"
        fill="none"
        stroke="green"
        stroke-width="1.5"
        marker-end="url(#arrowhead)"
      >
        <animate
          attributeName="stroke-dasharray"
          from="0, 300"
          to="300, 0"
          dur="1.8s"
          repeatCount="indefinite"
        />
      </path>
      <path
        :d="arcPathGridToConsumption"
        v-if="gridPower < 0"
        fill="none"
        stroke="green"
        stroke-width="1.5"
        marker-end="url(#arrowhead)"
      >
        <animate
          attributeName="stroke-dasharray"
          from="0, 300"
          to="300, 0"
          dur="1.8s"
          repeatCount="indefinite"
        />
      </path>
      <path
        :d="arcPathPaneltoSolar"
        v-if="inverterPower>0 && inverterPower != batteryPower"
          fill="none"
        stroke="green"
        stroke-width="1.5"
        marker-end="url(#arrowhead)"
      >
        <animate
          attributeName="stroke-dasharray"
          from="0, 300"
          to="300, 0"
          dur="1.8s"
          repeatCount="indefinite"
        />
      </path>
      <path
        :d="arcPathBatterytoSolar"
     v-if="batteryPower < 0"
        fill="none"
        stroke="green"
        stroke-width="1.5"
        marker-end="url(#arrowhead)"
      >
        <animate
          attributeName="stroke-dasharray"
          from="0, 300"
          to="300, 0"
          dur="1.8s"
          repeatCount="indefinite"
        />
      </path>
      <path
        :d="arcPathSolartoBattery"
        v-if="batteryPower > 0"
        fill="none"
        stroke="green"
        stroke-width="1.5"
        marker-end="url(#arrowhead)"
      >
        <animate
          attributeName="stroke-dasharray"
          from="0, 300"
          to="300, 0"
          dur="1.8s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
</template>

  
  <script>
  export default {
    props: {
      solarPower: {
        type: Number,
        required: true,
      },
      consumptionPower: {
        type: Number,
        required: true,
      }, 
      batteryPower: {
        type: Number,
        required: true,
      },
      SOC: {
        type: Number,
        required: true,
      },
     
    },
    methods: {
      formatUnit2(value) {
            if (value >= 1000) {
                return (value / 1000).toFixed(2) + " MW";
            } else if (value < 1) {
                return (value * 1000).toFixed(2) + " W";
            } else {
                return value.toFixed(2) + " kW";
            }
     } },
    computed: {

      panelPower() {
     
     return this.solarPower;
   
 
 },
 inverterPower() {
        return this.panelPower-this.batteryPower;
      },
      gridPower() {
        return this.inverterPower - this.consumptionPower;
      },
     
      
      arcPathSolarToGrid() {
        return `M 120 40 A 90 90 0 0 1 170 170`;
      },

      arcPathPaneltoSolar() {
  return `M 190 20 A 0 0 0 0 0 120 20`;
}
,
      arcPathBatterytoSolar() {
  return `M 0 20 A 90 90 0 0 1 80 20`;
}
,

arcPathSolartoBattery() {
  return `M 80 20 A 90 90 0 0 0 0 20`;
}
,
      arcPathSolarToConsumption() {
        return `M 80 40 A 90 90 0 0 0 30 170`;
      },
      arcPathGridToConsumption() {
        return `M 150 170 A 90 90 0 0 0 30 170`;
      },
    },
  };
  </script>
  
  <style scoped>
.power-flow {
  display: flex;
  justify-content: center;
  align-items: center;
padding-left: 0px;
padding-right: 0px;
margin-bottom: -50px;
}

svg {
  width: 400px;
  height: 350px;
}
  </style>
  