<template>
  <!---
  Mostra les característiques d'un generador. Mostra:

  -La capacitat de la bateria (si en té)
  -Estat de l'inversor, correcte o si té alarmes. A FER: No està implementat (ara mostra Correcte fixament). S'hauria de passar d'un component superior o obtenir-se per una cirda (encara que en aquest segon cas crec que obtindríem informmació redundant)
  -Sistema d'inversor (Enphase/Huawei/Solax/Datadis)
  -Potencia del generador

  Les dades s'obtenen d'un component superior
-->
  <table id="caracteristiques">
    <tr>
      <td>Sistema</td>
      
      <td>Potència</td>
      
      <td>Capacitat</td>
     
      <td>Estat</td>
    </tr>
    <tr>
      <td>{{asistema}}</td>
      <td>{{potencia}}kW </td>
      <td v-if="acapacitat==0">Sense bateria</td>
      <td v-else>{{acapacitat}}kWh </td>     
      <td>Correcte</td>
    </tr>
  </table>
</template>

<script>
export default {
    props: {
      capacitat: {},
      adreca: {},
      sistema: {},
      potencia: {},

    },
    data() {
    return {
      
      acapacitat: this.capacitat,
      aadreca: this.adreca,
      asistema: this.sistema,
      apotencia: this.potencia,
    }
    },
     mounted(){
        
                  console.log(this.acapacitat);
                  
                  
                  console.log(this.apotencia);
                  console.log(this.aadreca);

                  switch(this.sistema) {
                    //Identificador (int) del sistema, segons està codificat a la base de dades
                    case 1:
                    this.asistema="Enphase";
                      break;
                    case 2:
                    this.asistema="Huawei";
                      break;
                    case 3:
                    this.asistema="Solax";
                      break;
                    case 4:
                    this.asistema="Datadis";
                      break;
                    case 5:
                    this.asistema="Wattabit";
                      break;
                      case 6:
                    this.asistema="X";
                      break;
                      case 7:
                    this.asistema="SunGrow";
                      break;
                  }
    }
}

</script>

<style>
  #caracteristiques {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  border: none;
  border-radius: 10px;

  text-align: center;
  border-collapse: separate;
  table-layout: fixed;
  }

#caracteristiques td, #caracteristiques th {
  border: none;
  border-radius: 10px;

}

#caracteristiques tr:nth-child(even){
  background-color: #B6FF9F;
  font-weight: bold;
}


@media (max-width: 324.98px) {
  #caracteristiques {
    font-size: 10px;
  }

  

}

@media (min-width: 325px) and (max-width: 374.98px) {
    #caracteristiques {
    font-size:14px;
  }

}

@media (min-width: 375px) and (max-width: 575.98px) {
    #caracteristiques {
    font-size:16px;
  }

}

@media (min-width: 576px) and (max-width: 789.98px) {
    #caracteristiques {
    font-size: 18px;
  }
}

/* For medium devices (tablets, 768px to 991.98px wide) */
@media (min-width: 790px) and (max-width: 995.98px) {
    #caracteristiques {
    font-size: 16px;
  }

}

/* For large devices (desktops, 992px to 1199.98px wide) */
@media (min-width: 996px) and (max-width: 1199.98px) {
  #caracteristiques {
  font-size: 16px;
}

}

/* For extra-large devices (large desktops, 1200px and above) */
@media (min-width: 1200px) {
    #caracteristiques {
    font-size: 18px;
  }
}


</style>