<template>
  <div class="home">
    <div class="centered-container">
      <FormulariLogin/>
      <!-- <LoginForm/> -->
    </div>
  </div>
</template>

<script>
import FormulariLogin from '../components/FormLogin23.vue'

export default {
  name: 'Home',
  components: {
    FormulariLogin,
  }
}
</script>

<style scoped>
.home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh; /* Optional: Set a specific height for the container */
}

.centered-container {
  text-align: center;
}
</style>
