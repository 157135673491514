<template>
  <!--
    
Taula amb la que un administrador de comunitat pot realitzar canvis als coeficients de repartiment.
També pot triar la data d'ença la que els canvis que fa són vigents.
La taula comprova que els coeficients de repartiment de tots els consums per a cada generador sumin 1.


FALTA PER FER:
Funcionalitat d'afegir noves instal·lacions a una comunitat, o esborrar-les


  -->
    <div class = "component-wrapper">


        <br><br>
      <TaulaBetes v-if="respostaApi" :idComunitat="codiCE"/>
    </div>
    </template>
    
    <script>
    import axios from 'axios';
    import TaulaBetes from '../components/TaulaBetes.vue';
    export default {
      components: { TaulaBetes },
    data() {
      return {
           Instalacio: "",
           DetallsAPI:"", 
           DadesInst:[], 
           estatInst:0, 
           codiCE: "",
           carregant: false,
           mostraGeneracions: false,
           mostraConsums: false,
           carregaMapa: false,
           respostaApi: false,
      }
    },
    
    mounted(){
      this.codiCE=this.$route.params.id;
      this.comprovaPermisos();
      
    },
    methods:{
      async comprovaPermisos() {
      
        await axios.get (this.$domini+'obteComunitats', {withCredentials: true})
            .then((response) => {this.respostaApi = response.data})
            .catch((err) => {
                    this.$refs['my-modal'].show()
                    console.error(err);
                });  
          /*if (this.respostaApi.missatge=="redireccionar_comunitat"){
            
            if(this.respostaApi.comunitat!=this.codiCE || !this.respostaApi.administrador){
              
              window.location.href = "/";
            }
          }
          if (this.respostaApi.missatge=="llista") {
            let fl=false
            for (let i=0; i<this.respostaApi.llista.length; i++) {
              if (this.respostaApi.llista[i][1]==this.codiCE){
                fl=true;
              }
            }
            if (!fl  || !this.respostaApi.administrador){
           
              window.location.href = "/";
            }

          }
          else if (this.respostaApi.missatge=="no logged") {
            this.$refs['modal-inautoritzat'].show()
              window.location.href = "/";
          }

          /*else if (this.respostaApi.missatge=="redireccionar_consum") {
            this.$refs['modal-inautoritzat'].show()
              window.location.href = "/";
          }
          else {
            window.location.href = "/";
          }*/
          

          
    },
    },

    }
    </script>
    
    <style>
    .component-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80vh; /* Adjust as needed */
    }
    </style>