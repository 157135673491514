<template>
  <div id="esquema" class="encuadrat7">
    <!-- Modal -->
    <b-modal ref="my-modal" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>No s'han trobat les dades sol·licitades</h4>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="hideModal">Tanca</b-button>
    </b-modal>
    <b-modal ref="my-modal2" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>Si us plau, selecciona un any abans de calcular el cost.</h4>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="hideModal2">Tanca</b-button>
    </b-modal>
    <b-container>
      <b-row>
        <b-col>
          <div class="spinner-border text-success" role="status" v-show="carregant" style="width: 1.0rem; height: 1.0rem; border-width: 0.2em;"></div>
          <br>
          <table id="caracteristiques">
            <tr>
              <td><b>Any</b></td>
              <td><b>Mes</b></td>
              <td><b>Cost</b></td>
              <td><b>Estalvi</b></td>
              <td v-if="compensacio === 0"><b>Excedents</b></td>
            </tr>
            <tr>
              <th>
                <div style="width: 100%;">
                  <select v-model="selectedAny" @change="seleccionarAny(selectedAny)" class="btn-success5" style=" padding:8px; width: 100%;">
                    <option v-for="(any, index) in anysDisponibles" :key="index" :value="any" style="font-weight: bold;color: rgb(66, 65, 71);">{{ any }}</option>
                  </select>
                </div>
              </th>
              <th>
                <div style="width: 100%;">
                  <select v-model="selectedNumero" @change="seleccionarNumero(selectedNumero)" class="btn-success5" style="padding: 8px; width: 100%; ">
                    <option value="1-12"  style="font-weight: bold;">1-12</option>
                    <option v-for="numero in 12" :key="numero" :value="numero" style="font-weight: bold; color:rgb(66, 65, 71);">
                      {{ numero }}
                    </option>
                  </select>
                </div>
              </th>
              <td>{{cost }} €</td>
              <td>{{estalvi }} €</td>
              <td v-if="compensacio === 0">{{excedents}} €</td>
            </tr>
            
            
          </table>
        </b-col>
        </b-row>
        <br>
        
        <b-row>  
        
        <b-col >
          <div id="graficalineas2" class="chart-container">
            <div class="chart-wrapper">
              <ChartStackedBar2 :chart-data="chartData" />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col></b-col>
        <b-col></b-col>
        <b-col></b-col>
        <b-col style="text-align: right;">
          <button type="button" class="btn-success3 mb-0" @click="mesSeleccionat === 0 ? calcularCostEnergia2() : calcularCostEnergia()">Calcular</button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
import ChartStackedBar2 from "./ChartStackedBar2.js";
import { ca } from 'vuejs-datepicker/dist/locale';

export default {
  components: {
    ChartStackedBar2,
  },
  props: {
    idInstalacio: {},
  },
  data() {
    return {
      any: "",
      codiInstalacio: this.idInstalacio,
      cost: null,
      estalvi: null,
      tarifa: "",
      ca: ca,
      respostes: [],
      compensacio:-1,
      anysDisponibles: [], 
      anySeleccionat: 0,
      mesSeleccionat: 0,
      infoapi: "",
      carregant: "",
      excedents: null
    };
  },
  mounted(){
    this.calcularAnys();
  },
  methods: {


    
    async calcularAnys() {
      console.log("INST_PREU_CON", this.codiInstalacio);

      try {
        const response = await axios.get(`${this.$domini}anysInstalacio?idInstalacio=${this.codiInstalacio}`, { withCredentials: true},{timeout: 2000000});
        this.anysDisponibles = response.data.anys; 
        console.log(this.anysDisponibles);
      } catch (error) {
        console.error('Error al calcular el cost', error);
      }
    },
    async calcularCostEnergia() {
      this.carregant=true;
      console.log("INST_PREU_CON", this.codiInstalacio);

      if (this.anySeleccionat === 0) {
        this.mostrarModal2();
        this.carregant = false; 
        return; 
      }
      try {
        const response = await axios.get(`${this.$domini}costEnergia?idInstalacio=${this.codiInstalacio}&any=${this.anySeleccionat}&mes=${this.mesSeleccionat}`, { withCredentials: true},{timeout: 2000000});
       
        
        let nf= new Intl.NumberFormat('es-ES');

        this.estalvi = nf.format(response.data.estalvi);
       
        this.cost = nf.format(response.data.costTotal);
 
        this.infoapi = response.data;
        this.tarifa = response.data.tarifa;
     
        this.compensacio = nf.format(response.data.compensacio);



        if (this.compensacio === 0) {

          let nf= new Intl.NumberFormat('es-ES');

          this.excedents = nf.format(response.data.excedents);
        }
      } catch (error) {
        console.error('Error al calcular el cost', error);
      }
      if (this.tarifa === 1) {
        await this.PonerGrafica2();
      } else {
        await this.PonerGrafica();
      }
    },



    async calcularCostEnergia2() {
    this.carregant = true;
    console.log("INST_PREU_CON", this.codiInstalacio);

    if (this.anySeleccionat === 0) {
        this.mostrarModal2();
        this.carregant = false;
        return;
    }

    try {
        const months = Array.from({ length: 12 }, (_, i) => i + 1); // Months from 1 to 12
        const requests = months.map(mes =>
            axios.get(`${this.$domini}costEnergia?idInstalacio=${this.codiInstalacio}&any=${this.anySeleccionat}&mes=${mes}`, { withCredentials: true, timeout: 2000000 })
        );

        const responses = await Promise.all(requests);

        // Initialize accumulation arrays for 6 periods, each with 12 months
        const totalCostperiodes = Array.from({ length: 6 }, () => Array(12).fill(0));
        const totalCostExcedents = Array(12).fill(0);

        let aggregatedCost = 0;
        let aggregatedCompensacio = 0;
        let aggregatedEstalvi = 0;
        let aggregatedTarifa = null;
        let aggregatedExcedents = 0;

        responses.forEach((response, index) => {
            const data = response.data;
            const monthIndex = index; // The index corresponds to the month (0 = January, 1 = February, etc.)

            // Accumulate total costs
            aggregatedCost += parseFloat(data.costTotal);
            aggregatedCompensacio = data.compensacio; // Assuming tarifa is the same for all months
            aggregatedEstalvi += parseFloat(data.estalvi);
            aggregatedTarifa = data.tarifa;

          
                aggregatedExcedents += parseFloat(data.excedents);
                totalCostExcedents[monthIndex] = parseFloat(data.excedents);
            

            data.costperiodes1.forEach((value, dayIndex) => {
                if (dayIndex < data.costperiodes1.length) {
                    totalCostperiodes[0][monthIndex] += parseFloat(value);
                }
            });
            data.costperiodes2.forEach((value, dayIndex) => {
                if (dayIndex < data.costperiodes2.length) {
                    totalCostperiodes[1][monthIndex] += parseFloat(value);
                }
            });
            data.costperiodes3.forEach((value, dayIndex) => {
                if (dayIndex < data.costperiodes3.length) {
                    totalCostperiodes[2][monthIndex] += parseFloat(value);
                }
            });
            data.costperiodes4.forEach((value, dayIndex) => {
                if (dayIndex < data.costperiodes4.length) {
                    totalCostperiodes[3][monthIndex] += parseFloat(value);
                }
            });
            data.costperiodes5.forEach((value, dayIndex) => {
                if (dayIndex < data.costperiodes5.length) {
                    totalCostperiodes[4][monthIndex] += parseFloat(value);
                }
            });
            data.costperiodes6.forEach((value, dayIndex) => {
                if (dayIndex < data.costperiodes6.length) {
                    totalCostperiodes[5][monthIndex] += parseFloat(value);
                }
            });
        });














        let nf= new Intl.NumberFormat('es-ES');





        this.cost = nf.format(aggregatedCost.toFixed(2));
        this.compensacio = aggregatedCompensacio;
        this.estalvi =  nf.format(parseFloat(aggregatedEstalvi).toFixed(2));
        this.tarifa = aggregatedTarifa;
        this.excedents =  nf.format(parseFloat(aggregatedExcedents).toFixed(2));

        // Prepare data for chart
        this.infoapi = {
            temps: months, // Months from 1 to 12
            costperiodes1: totalCostperiodes[0],
            costperiodes2: totalCostperiodes[1],
            costperiodes3: totalCostperiodes[2],
            costperiodes4: totalCostperiodes[3],
            costperiodes5: totalCostperiodes[4],
            costperiodes6: totalCostperiodes[5],
            costExcedents: totalCostExcedents
        };
        console.log("COMPENSACIO", this.compensacio);
        console.log("COST", this.cost);
        console.log("estalvi", this.estalvi);
        console.log("infoapi", this.infoapi);
        console.log("tarifa", this.tarifa);
        console.log("excedents", this.excedents);

        if (this.tarifa === 1) {
            await this.PonerGrafica2();
        } else {
            await this.PonerGrafica();
        }
    } catch (error) {
        console.error('Error al calcular el cost', error);
    } finally {
        this.carregant = false;
    }
}

,
    PonerGrafica() {
      this.chartData = {
        labels: this.infoapi.temps,
        datasets: [],
      };
      
      this.chartData.datasets.push({
        backgroundColor: "#004c00",
        borderWidth: 0.5,
        label: "P1",
        data: this.infoapi.costperiodes1.map(cost => parseFloat(cost.toFixed(2))),
      });

      this.chartData.datasets.push({
        backgroundColor: "#007f00",
        borderWidth: 0.5,
        label: "P2",
        data: this.infoapi.costperiodes2.map(cost => parseFloat(cost.toFixed(2))),
      });
      this.chartData.datasets.push({
        backgroundColor: "#00cc00",
        borderWidth: 0.5,
        label: "P3",
        data: this.infoapi.costperiodes3.map(cost => parseFloat(cost.toFixed(2))),
      });

      this.chartData.datasets.push({
        backgroundColor: "#00ff00", 
        borderWidth: 0.5,
        label: "P4",
        data: this.infoapi.costperiodes4.map(cost => parseFloat(cost.toFixed(2))),
      });
      this.chartData.datasets.push({
        backgroundColor: "#80ff80",
        borderWidth: 0.5,
        label: "P5",
        data: this.infoapi.costperiodes5.map(cost => parseFloat(cost.toFixed(2))),
      });

      this.chartData.datasets.push({
        backgroundColor: "#ccff99",
        borderWidth: 0.5,
        label: "P6",
        data: this.infoapi.costperiodes6.map(cost => parseFloat(cost.toFixed(2))),
      });
      
      this.chartData.datasets.push({
        backgroundColor:`rgba(255, ${170 - 30}, ${80 - 40}, 1)`,
        borderWidth: 0.5,
        label: "Excedents",
        data: this.infoapi.costExcedents.map(cost => parseFloat(cost.toFixed(2))),
      });
      this.configurarTooltips(); 
      this.carregant=false;
    },
    PonerGrafica2() {
      this.chartData = {
        labels: this.infoapi.temps,
        datasets: [],
      };
      
      this.chartData.datasets.push({
        backgroundColor: "#004c00",
        borderWidth: 0.5,
        label: "P1",
        data: this.infoapi.costperiodes1.map(cost => parseFloat(cost.toFixed(2))),
      });

      this.chartData.datasets.push({
        backgroundColor: "#007f00",
        borderWidth: 0.5,
        label: "P2",
        data: this.infoapi.costperiodes2.map(cost => parseFloat(cost.toFixed(2))),
      });
      this.chartData.datasets.push({
        backgroundColor: "#00cc00",
        borderWidth: 0.5,
        label: "P3",
        data: this.infoapi.costperiodes3.map(cost => parseFloat(cost.toFixed(2))),
      });
      
      this.chartData.datasets.push({
        backgroundColor:`rgba(255, ${170 - 30}, ${80 - 40}, 1)`,
        borderWidth: 0.5,
        label: "Excedents",
        data: this.infoapi.costExcedents.map(cost => parseFloat(cost.toFixed(2))),
      });
      this.configurarTooltips(); 
      this.carregant=false;
    },
    configurarTooltips() {
      this.options = {
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              let datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
              let value = parseFloat(tooltipItem.yLabel);
              return datasetLabel + ':€' + value.toFixed(2);
            }
          }
        }
      };
    },
    handleError(err) {
      console.error("Error:", err);
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    hideModal2() {
      this.$refs['my-modal2'].hide();
    },
    mostrarModal2() {
      this.$refs['my-modal2'].show();
    },
    seleccionarAny(any) {
      this.anySeleccionat = any;
      console.log("Any seleccionat", any);
    },
    seleccionarNumero(numero) {
      if (numero==="1-12") numero = 0;
      this.mesSeleccionat = numero;
      
      console.log("mes seleccionat", numero);
    },
  },
};
</script>

<style scoped>
.overflow-row {
  overflow-x: scroll;
  white-space: nowrap;  
 
  border-radius: 5px;
}

.btn-success5 {
  background: #B6FF9F;
  border: none;
  color: rgb(66, 65, 71);
  border-radius: 10px;
  width:100%;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding:0;
  margin:0;
}

.encuadrat7 {
  text-align: center;
  width: 100%;
  padding: 25px;
  margin: 5px;
  border: 3px solid rgb(201, 201, 201);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 374.98px) {
  .btn-success5 {
    font-size: 8px;
  }
}

@media (min-width: 375px) and (max-width: 789.98px) {
  .btn-success5 {
    font-size: 11px;
  }
}

/* For medium devices (tablets, 768px to 991.98px wide) */
@media (min-width: 790px) and (max-width: 995.98px) {
  .btn-success5 {
    font-size: 11px;
  }
  .btn-success3 {
    width: 50%;
    
  }
}

/* For large devices (desktops, 992px to 1199.98px wide) */
@media (min-width: 996px) and (max-width: 1199.98px) {
  .btn-success5 {
    font-size: 12px;
  }
  .btn-success3 {
    width: 50%;
  }
}

/* For extra-large devices (large desktops, 1200px and above) */
@media (min-width: 1200px) {
  .btn-success5 {
    font-size: 15px;
  }
  .btn-success3 {
    width: 50%;
  }
}
</style>
