<template>
  <!--
    Crida els components necessaris per mostrar la quota autàrquica, d'autoconsum i la cobertura solar. Obté  les quotes fent la crida necessària a la API
  -->
<div id="cuotas">
    <div id="tabla">
      <table class="taulaindicadors">
        <tr>
          <th><div class="subtitol">Sobirania Energètica (anual)</div></th>
        </tr>
        <tr id="barra1">
          <td>
            
            <CAutarquica :chartDataAutarquica="chartDataAutarquica"/>
          </td>
        </tr>
        <tr>
          <th><div class="subtitol">Autoconsum (anual)</div></th>
        </tr>
        <tr>
          <td>
            <CAutoconsum :chartDataAutoconsum="chartDataAutoconsum" />
          </td>
        </tr>
        <tr>
          <th><div class="subtitol">Cobertura Solar (anual)</div></th>
        </tr>
        <tr>
          <td>
            <table>
              <tr>
                
                <td>
                  
                  <CSolar :CSolarInt="CSolarInt" />
                </td>
                
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <!-- <br><br> 
        <p>Aqui ponemos el analisis de las graficas anteriores, por ejemplo un par de parrafos explicando y analizando los valores</p>
     <br><br>-->
</div>
</template>

<script>
import axios from 'axios';
import CAutarquica from '../components/CAutarquica.vue'
import CSolar from '../components/CSolar.vue'
import CAutoconsum from '../components/CAutoconsum.vue'

export default {
  components: {
    CAutarquica,
    CSolar,
    CAutoconsum,
  },
  props: ['idInstalacio'],
  data() {
    return {
      comprobacion:false, 
      codiInstalacio: this.idInstalacio,
      any:"",  
      CuotasApi:"",
      text:"",

      CSolartext:"",
      CSolar:"",
      CSolarNum:"",
      CSolarInt:null,

      CAutoconsumtext:"",
      Autoconsum:"", 
      AutoconsumNum:"",
      ComplementariAutoconsumNum:"",
      chartDataAutoconsum: null,

      CAutaquicatext:"", 
      QuotaAutarquica:"",
      ComplementariQAutarquica:"",
      QuotaAutarquicaNum:"",
      chartDataAutarquica: null,
     
    };
  },
  mounted() {
    this.CuotasAxios();
  },
  methods: {

     async CuotasAxios(){
          this.data= new Date;
          this.any=this.data.getYear()-100;
          
          //this.any  = 2023;
          console.log("quotes "+this.codiInstalacio);
           await axios.get (this.$domini+'/energiaanual?Instalacio='+this.codiInstalacio+'&any='+this.any, { withCredentials: true},{timeout: 2000000})//  /DadesInstalacio?instalacio=89
            .then((response) => {this.CuotasApi = response.data});
            //per CSolar
            this.CSolar=this.CuotasApi.CoberturaSolar
            this.CSolarNum=this.CSolar*100 
            this.CSolarInt=parseFloat(this.CSolarNum)
            this.CSolarInt=this.CSolarInt.toFixed(0)

            //per CAutoconsum
            this.Autoconsum=this.CuotasApi.percAutoconsum

            this.AutoconsumNum = this.Autoconsum*100
            this.AutoconsumNum=this.AutoconsumNum.toFixed(0)
            this.ComplementariAutoconsumNum=100-this.AutoconsumNum
            this.chartDataAutoconsum = {
            labels: [""],
            datasets:[

            {
              label: "Autoconsum: "+this.AutoconsumNum.toString()+"%",
              backgroundColor: "#26A300",
              
              hoverBackgroundColor: "#008f39",
              data: [this.AutoconsumNum],
            },
            {
              label: "Venuda a xarxa: "+this.ComplementariAutoconsumNum.toString()+"%",
              backgroundColor: "#B6FF9F",
           
              hoverBackgroundColor: "#d1e3f7",
              data: [this.ComplementariAutoconsumNum],
            }
            ]

            };  

            //per CAutarquica
            this.QuotaAutarquica=this.CuotasApi.QuotaAutarquica

            this.QuotaAutarquicaNum = this.QuotaAutarquica*100
            this.QuotaAutarquicaNum=this.QuotaAutarquicaNum.toFixed(0)
            this.ComplementariQAutarquica=100-this.QuotaAutarquicaNum

            this.chartDataAutarquica = {
              labels: [""],
              datasets:[
            {
              label: "Autoconsum: "+this.QuotaAutarquicaNum.toString()+"%",
              backgroundColor: "#26A300",
           
              hoverBackgroundColor: "#008f39",
              data: [this.QuotaAutarquicaNum],
            },
            {
              label: "Consumida xarxa: "+this.ComplementariQAutarquica.toString()+"%",
              backgroundColor: "#B6FF9F",
              
              hoverBackgroundColor: "#d1e3f7",
              data: [this.ComplementariQAutarquica],
            }
        ]
      };
      console.log(this.QuotaAutarquicaNum);
        },


  }
 
    }
  
</script>
<style scoped>
/*#cuotas{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;}*/

td{
  text-align: center;
 
  border-color:white;
}

.taulaindicadors{
  text-align: center;
  /*border-collapse: collapse;*/
  border-spacing: 0;
  margin:0 auto;
  width: 300px;
  border-spacing: 0;
  padding:5px;
  }

.subtitol{
  font-size: 12px;
}

 #chartJS {
      image-rendering: optimizeSpeed;             /* Older versions of FF */
      image-rendering: -moz-crisp-edges;          /* FF 6.0+ */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non standard naming) */
      image-rendering: -o-crisp-edges;            /* OS X & Windows Opera (12.02+) */
      image-rendering: crisp-edges;               /* Possible future browsers. */
      -ms-interpolation-mode: nearest-neighbor;   /* IE (non standard naming) */
    }
</style>