<!--
    Tanca la sessió de l'usuari, mentres es fa mostra un missatge i un spinner. Una vegada s'ha fet es redirecciona l'usuari
-->
<template>
    
  <div class="Missatge"><br><br><br><b>Tancant la sessió...</b><br> <br><div class="spinner-border text-success" role="status" style="color: #0c4105c7;width: 2.0rem; height: 2.0rem; border-width: 0.2em;"></div></div>

</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
          respostaApi:"",
        };
    },
    mounted(){
        this.logoutEVC();
  
    },
    methods:{
        async logoutEVC(){
            await axios.get (this.$domini+"logoutEVC", {withCredentials: true})
            .then((response) => {this.respostaApi = response.data})
            
            if (this.respostaApi.exit) {
                window.location.href = "/";
            }
        }
    }
}
</script>

<style>

.Missatge{
  margin:auto;
  color: #0c4105c7;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  font-size:x-large;
  text-align: center;
}

.spinner-border.text-success {
  text-align: center;
  color: #0c4105c7; 
}

@media (max-width: 575.98px) {
  .Missatge {
    width: 90%;
    max-width: 100%;
  }
}


@media (min-width: 576px) and (max-width: 991.98px) {
  .Missatge {
    width: 70%;
    max-width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .Missatge {
    width: 60%;
    max-width: 100%;
    height: 400px;
  }
}


@media (min-width: 1200px) {
  .Missatge {
    width: 50%;
    max-width: 100%;
  }
}
</style>