///////////////////////////////////////////Programa de muntatge i opcions de les grafiques de Doughnut /////////////////////////
import { Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: ["chartData"],
  data() {
    return {
      options: {
        /*legend: {display: false},*/
          scales: {
            y:{ticks:{stepSize:5,}},
          /*xAxes:[{gridLines: {display:false }}],*/
          yAxes: [{ticks:{display:false},gridLines:{drawBorder:false,display:false}}],  
        },
        responsive: true,
        maintainAspectRatio: true,
      }
    };
  },
  mounted() {
    this.addPlugin({
      id: 'center',
      afterDraw: function(chart) {
        var width = chart.chart.width;
        var height = chart.chart.height;
        var ctx = chart.chart.ctx;

        ctx.restore();
        var fontSize = (height / 100).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";
        var text = '';
        if (chart.data != null) {
          if (chart.data.datasets[0] != null) {
            text = chart.data.datasets[0].data[0] + "%";
          } 
        }
        
        var textX = Math.round((width - ctx.measureText(text).width) / 1.82);
        var textY = height / 1.6;

         ctx.fillText(text, textX, textY);
         ctx.save();
       }
    })
    this.renderChart(this.chartData, this.options);
  }
};

/*import { Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: ["chartData"],
  data() {
    return {
      options: {
        responsive: true
      }
    };
  },
  mounted() {
    this.addPlugin({
      id: 'center',
      afterDraw: function(chart) {
        var width = chart.chart.width;
        var height = chart.chart.height;
        var ctx = chart.chart.ctx;

        ctx.restore();
        var fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";
        var text = '';
        if (chart.data != null) {
          if (chart.data.datasets[0] != null) {
            text = chart.data.datasets[0].data[0] + "%";
          } 
        }
        
        var textX = Math.round((width - ctx.measureText(text).width) / 2);
        var textY = height / 2;

         ctx.fillText(text, textX, textY);
         ctx.save();
       }
    })
    this.renderChart(this.chartData, this.options);
  }
}; */