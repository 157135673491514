<template>
  <b-modal v-model="isModalOpen" title="Generadors Disponibles">
    <div class="d-block">
      <b-form-group label="Selecciona el generador:">
        <b-form-radio-group v-model="selectedNumber">
          <b-form-radio v-for="number in availableNumbers" :key="number" :value="number">
            &nbsp;&nbsp;{{ number }}
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </div>
    <div slot="modal-footer" class="w-100">
      <b-button @click="closeModal" variant="secondary">Tanca</b-button>
      <b-button @click="confirmSelection" variant="primary">Confirma</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    availableNumbers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isModalOpen: false,
      selectedNumber: null
    };
  },
  watch: {
    show(newVal) {
      this.isModalOpen = newVal;
    }
  },
  mounted() {
    this.isModalOpen = this.show;
  },
  methods: {
    closeModal() {
      this.isModalOpen = false;
      this.$emit('update:show', false);
    },
    confirmSelection() {
      if (this.selectedNumber !== null) {
        this.$emit('numberSelected', this.selectedNumber);
        this.closeModal();
      } else {
        console.error("No s'ha seleccionat cap generador");
      }
    }
  }
};
</script>
