<template>
  <div id="esquema" class="encuadrat2">
    <table>
      <tr>
        <th class="fixed-width">Any:</th>
        <th class="fixed-width2">
          <div style="width: 100%;">
            <select v-model="selectedAny" @change="seleccionarAny(selectedAny)" class="btn-success5 custom-select" style="padding: 8px; width: 100%; font-weight: bold; color: rgb(66, 65, 71);">
              <option v-for="(any, index) in anysDisponibles" :key="index" :value="any" style="font-weight: bold; text-align: center">{{ any }}</option>
            </select>
          </div>
        </th>
        <th class="fixed-width">Mes:</th>
        <th class="fixed-width2">
          <div style="width: 100%;">
            <select v-model="selectedNumero" @change="seleccionarNumero(selectedNumero)" class="btn-success5 custom-select" style="padding: 8px; width: 100%; font-weight: bold; color: rgb(66, 65, 71);">
              <option v-for="numero in 12" :key="numero" :value="numero" style="font-weight: bold;text-align: center; color:rgb(66, 65, 71);">{{ numero }}</option>
            </select>
          </div>
        </th>
      </tr>
      <tr>
        <th colspan="4" v-if="consum == 1 && generador == 1">
          <Quotes :key="quotesKey" :idInstalacio="codiInstalacio" :anySeleccionat="anySeleccionat" :mesSeleccionat="mesSeleccionat" />
        </th>
        <th colspan="4" v-if="consum == 0 && generador == 1"></th>
      </tr>
      <tr>
        <th colspan="4">
          <caracGenerador v-if="generador == 1" :capacitat="capacitat" :adreca="adreca" :sistema="sistema" :potencia="potencia" />
        </th>
      </tr>
      <tr>
        <th colspan="4" v-if="consum == 0 && generador == 1">
          <QuotesGenerador :key="quotesGeneradorKey" :idInstalacio="codiInstalacio" :sistema="sistema" :idComunitat="codiCE" :anySeleccionat="anySeleccionat" :mesSeleccionat="mesSeleccionat" />
        </th>
        <th colspan="4" v-if="consum == 1 && generador == 0">
          <QuotesConsum :key="quotesConsumKey" :idInstalacio="codiInstalacio" :idComunitat="codiCE" :anySeleccionat="anySeleccionat" :mesSeleccionat="mesSeleccionat" />
        </th>
      </tr>
      <tr v-if="consum == 1 && generador == 0">
        <th colspan="4">
          <consumicio :key="consumicioKey" :idInstalacio="codiInstalacio" :sistema="sistema" :anySeleccionat="anySeleccionat" :mesSeleccionat="mesSeleccionat" />
        </th>
      </tr>
      <tr v-if="consum == 1 && generador == 0">
        <th colspan="4">
          <Assignacio :key="assignacioKey" :idInstalacio="codiInstalacio" :idComunitat="codiCE" :sistema="sistema" :anySeleccionat="anySeleccionat" :mesSeleccionat="mesSeleccionat" />
        </th>
      </tr>
      <tr>
        <th colspan="4" v-if="consum == 0 && generador == 1 && codiInstalacio">
          <Produccio :key="produccioKey" :idInstalacio="codiInstalacio" :sistema="sistema" :anySeleccionat="anySeleccionat" :mesSeleccionat="mesSeleccionat" />
        </th>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
import Quotes from './Quotes.vue';
import QuotesConsum from './QuotesConsum.vue';
import Produccio from './Produccio.vue';
import QuotesGenerador from './QuotesGenerador.vue';
import consumicio from './consumicio.vue';
import Assignacio from './Assignacio.vue';
import CaracGenerador from './caracGenerador.vue';

export default {
  props: {
    idInstalacio: {},
    consum: {},
    generador: {},
    acapacitat: {},
    aadreca: {},
    asistema: {},
    apotencia: {},
    idComunitat: {},
    
  },
  components: {
    Quotes,
    QuotesConsum,
    QuotesGenerador,
    Assignacio,
    Produccio,
    consumicio,
    CaracGenerador,
  },
  data() {
    const now = new Date();
    const anyActual = now.getFullYear();
    const mesActual = now.getMonth() + 1; // El mes comença des de zero, per tant s'afegeix 1
    return {
      codiInstalacio: this.idInstalacio,
      generadorsApi: '',
      value: '',
      ids: '',
      anySeleccionat: anyActual,
      mesSeleccionat: mesActual,
      capacitat: this.acapacitat,
      adreca: this.aadreca,
      sistema: this.asistema,
      potencia: this.apotencia,
      codiCE: this.idComunitat,
      selectedAny: anyActual,
      selectedNumero: mesActual,
      quotesKey: 0,
      quotesGeneradorKey: 0,
      quotesConsumKey: 0,
      consumicioKey: 0,
      assignacioKey: 0,
      produccioKey: 0,
      anysDisponibles: [],
    };
  },
  watch: {
    anySeleccionat() {
      this.refreshComponents();
    },
    mesSeleccionat() {
      this.refreshComponents();
    },
  },
 
  mounted() {
    console.log('RESUM_INS', this.codiInstalacio);
    console.log('RESUM_codiCE', this.codiCE);
    console.log(this.capacitat);
    console.log(this.potencia);
    console.log(this.adreca);
    this.calcularAnys();
    
  },

  methods: {
    async calcularAnys() {
      console.log('INST_PREU_CON', this.codiInstalacio);
      try {
        const response = await axios.get(`${this.$domini}anysInstalacio?idInstalacio=${this.codiInstalacio}`, { withCredentials: true }, { timeout: 2000000 });
        this.anysDisponibles = response.data.anys;
        console.log(this.anysDisponibles);
      } catch (error) {
        console.error('Error al calcular el cost', error);
      }
    },
    seleccionarAny(any) {
      this.anySeleccionat = any;
      console.log('Any seleccionat', any);
    },
    seleccionarNumero(numero) {
      this.mesSeleccionat = numero;
      console.log('mes seleccionat', numero);
    },
    updateDateAndTime() {
      const now = new Date();
      const newAny = now.getFullYear();
      const newMes = now.getMonth() + 1;
      if (newAny !== this.anySeleccionat || newMes !== this.mesSeleccionat) {
        this.anySeleccionat = newAny;
        this.mesSeleccionat = newMes;
        this.selectedAny = newAny;
        this.selectedNumero = newMes;
      }
    },
    refreshComponents() {
      this.quotesKey += 1;
      this.quotesGeneradorKey += 1;
      this.quotesConsumKey += 1;
      this.consumicioKey += 1;
      this.assignacioKey += 1;
      this.produccioKey += 1;
      console.log("quotes_key", this.quotesKey);
    },
  },
};
</script>

<style>
#titolGA {
  background-color: "#B6FF9F";
}
.encuadrat2 {
  text-align: center;
  width: 100%;
  height: auto;
  padding: 0px;
  margin: 0px;
  border: 3px solid rgb(201, 201, 201);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fixed-width {
  width: 15%;
}
.fixed-width2 {
  width: 35%;
  justify-content: center;
}
.custom-select {
  background-color: #B6FF9F;
  border-radius: 10px;
  padding: 8px;
  width: 100%;
}
@media (max-width: 475.98px) {
  .encuadrat2 {
    width: 70%;
    max-width: 100%;
  }
}
@media (min-width: 476px) and (max-width: 991.98px) {
  .encuadrat2 {
    width: 90%;
    max-width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .encuadrat2 {
    width: 90%;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .encuadrat2 {
    width: 90%;
    max-width: 100%;
  }
}
</style>
