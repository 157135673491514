///////////////////////////////////////////Programa de muntatge i opcions de les grafiques de Barres superposades /////////////////////////
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["chartData"],
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            { stacked: true,
              scaleLabel:{display:true,labelString:'Energia'},
              ticks: {
                beginAtZero: true
              }
            }
          ],
          xAxes:[{ stacked: true, 
            scaleLabel:{display:true,labelString:'Temps'}
          
          }]
          
        },
        
      }
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};