<template> 
  <div id="Bar" v-if="this.passedyes" >
      <br>
      <ChartBarStackedBase :chart-data="chartData" :LabelString2="this.labelstring"/>

      <table id="comparativaConsum"><tr>
          <td><p>Energia Autoconsum</p></td><td></td><td><p>Consum de xarxa</p> </td></tr>
          <tr>
  <th> {{ Consum + ' ' + labelstring }} </th> <td></td>
  <th> {{ ConsumXarxa + ' ' + labelstring }} </th>
</tr></table>

     <!-- <table>
        <tr> <th><p>Total Autoconsum: </p> {{Consum}} kWh</th>
       <th><p>Total consum xarxa:</p> {{ConsumXarxa}} kWh </th></tr>
       </table>-->
      <br>
    </div>
</template>
<script>
//axios//
import ChartBarStackedBase from "../components_report/ChartBarStackedBase";
import axios from 'axios'
export default {
  props:['Dades','anyR','mesR','tipusR'],
  components: {ChartBarStackedBase,},
  data() {
    return {
      codiInstalacio:"117",
      any:"",
      mes:"",
      tipus:"",
      chartData: null,
      Consum:0,
      ConsumXarxa:0,
      CompConsumApi:"",
      text:"",
      labelstring:"KWh",
      ConsumArray:[],
      ConsumXarxaArray:[],
      mesosArray:[],
      passedyes:false,
      toggle:0
    };
  },
  mounted() {
    this.any=this.anyR;
    this.mes=this.mesR;
    this.tipus=this.tipusR;
    this.CompConsumAxios();
  },
      methods: { 
        
        
        formatUnit(value) {
            if (value >= 10000 & this.toggle===0) {
              this.toggle=3;
              this.labelstring="MWh";
              value = value / 1000;
            } else if (value < 1 & this.toggle===0) {
                this.toggle=1;
                this.labelstring="Wh";
                value = value * 1000;
            }  else if (value > 1 & this.toggle===0) {
                this.toggle=2;
                this.labelstring="KWh";
            }
            else if (this.toggle===1) {
                this.labelstring="Wh";
                  value = value * 1000;
                }
            else if (this.toggle===2) {
                this.labelstring="KWh";
          }
          else if (this.toggle===3) {
            this.labelstring="MWh";
            value = value / 1000;
          }
          return value.toFixed(2)
          },
         async CompConsumAxios(){


          await axios.get(this.$domini + 'energiaCE2?idInstalacio=' +this.Dades[0][0] + "&tipus="+ this.tipus +"&dia=31&mes="+this.mes+"&any=" + this.any, { withCredentials: true})
            .then((response) => {this.EGeneradaApi = response.data});
            await axios.get(this.$domini + 'energiaConsumicio?idInstalacio=' + this.Dades[0][0] + "&tipus="+ this.tipus +"&dia=31&mes="+this.mes+"&any=" + this.any, { withCredentials: true})
            .then((response) => {this.EGeneradaApi2 = response.data});
            await axios.get(this.$domini + 'energiaCE2_rep?idInstalacio=' + this.Dades[0][0] + "&tipus="+ this.tipus +"&dia=31&mes="+this.mes+"&any=" + this.any, { withCredentials: true})
            .then((response) => {this.EGeneradaApi3 = response.data});
            //console.log(this.EGeneradaApi)
            //this.EGeneradatext=JSON.parse(this.EGeneradaApi)
            //console.log(this.EGeneradatext) 
            let max=0;
            for (let x = 0; x < this.EGeneradaApi3.temps.length; x++) {
              
             if (max<this.EGeneradaApi2["eConsumida"][x]- this.EGeneradaApi["eGenerada"][x]+this.EGeneradaApi3["eEntXar"][x])
              {
                max=this.EGeneradaApi2["eConsumida"][x]- this.EGeneradaApi["eGenerada"][x]+this.EGeneradaApi3["eEntXar"][x]
              }
              else if (max<this.EGeneradaApi["eGenerada"][x]-this.EGeneradaApi3["eEntXar"][x])
              {
                max=this.EGeneradaApi["eGenerada"][x]-this.EGeneradaApi3["eEntXar"][x]
              }
            }
            this.formatUnit(max)
            console.log("MAX:",max)
            for (let x = 0; x < this.EGeneradaApi3.temps.length; x++) {
            if (this.EGeneradaApi3["temps"][x])
             {
            this.ConsumArray.push(this.formatUnit(this.EGeneradaApi["eGenerada"][x]-this.EGeneradaApi3["eEntXar"][x]))
            this.ConsumXarxaArray.push(this.formatUnit(this.EGeneradaApi2["eConsumida"][x]- this.EGeneradaApi["eGenerada"][x]+this.EGeneradaApi3["eEntXar"][x]))
            this.mesosArray.push(this.EGeneradaApi3["temps"][x]);
            this.Consum = this.Consum + parseFloat(this.formatUnit(this.EGeneradaApi["eGenerada"][x]-this.EGeneradaApi3["eEntXar"][x]))
            this.ConsumXarxa =this.ConsumXarxa + parseFloat(this.formatUnit( this.EGeneradaApi2["eConsumida"][x]- this.EGeneradaApi["eGenerada"][x]+this.EGeneradaApi3["eEntXar"][x]))
          }
          }

         console.log("View new::")
         console.log(this.ConsumArray)
         console.log(this.ConsumXarxaArray)
         this.Consum=parseFloat(this.Consum)
         this.Consum=this.Consum.toFixed(2)
         this.ConsumXarxa=parseFloat(this.ConsumXarxa)
         this.ConsumXarxa=this.ConsumXarxa.toFixed(2)
         this.chartData = {
        labels: this.mesosArray,
        datasets: [
          {
            backgroundColor:"#3CFF00",
            borderColor: "#2DBF00",
            borderWidth: 0.5,
            data: this.ConsumArray,
            label: "Autoconsum"
          },
           {
            backgroundColor:"#CBD0CA",
            borderColor:"#BAC1B9",
            borderWidth: 0.5,
            data:this.ConsumXarxaArray,
            label: "Consum Xarxa"
           },
        ]
      };

      this.passedyes=true;
    this.toggle=0;},
}
};

</script>

<style scoped>

#Bar{
  width: 420px;
  height: 250px;
  text-align: center;
  display:block;
  margin-left:auto;
  margin-right:auto;
  border-color: white;
}
th,table{
  margin:0 auto;
  text-align:center;
  size:70%;
  padding:7px;
  border-color: white;
}
#comparativaConsum{
  border-spacing: 10px;
  border-color: white;
}
p{
  font-size: 16px;
  color: #26a300;
  border-color: white;
}
th{
    font-size:25px;
    color:#2C312B;
    font-weight:bold;
    background-color: #DCFFD1;
    height:40px;
    margin:5px;
    padding: 5px;
    border: 1px solid #95f87700;
    border-color: white;
}
th,td{
    border-radius: 15px;
    border-color: white;
}
</style>