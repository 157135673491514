<template>
    <div id="aaa"  @click="handleClick">
      <svg class="aab" viewBox="0 0 344 494">
        <path id="aab" d="M 78 0 L 266 0 C 309.0782165527344 0 344 34.92178726196289 344 78 L 344 416 C 344 459.0782165527344 309.0782165527344 494 266 494 L 78 494 C 34.92178726196289 494 0 459.0782165527344 0 416 L 0 78 C 0 34.92178726196289 34.92178726196289 0 78 0 Z"></path>
      </svg>
      <div id="aac" @click="handleClick">
        <svg v-if="admi" class="aae" @click="handleClick2">
          <rect id="aae" rx="26.5" ry="26.5" x="0" y="0" width="129" height="53"></rect>
        </svg>
        <div id="aaf" @click="handleClick">
          <span>{{ codiCE[1] }}</span>
        </div>
      </div>
  
      <div v-if="admi" id="aah" @click="handleClick2">
        <span>administra</span>
      </div>
  
      <img
        :id="imageId"
        :src="imageSrc"
        :srcset="imageSrcset"
        :alt="imageAlt"
      />
    </div>
  </template>
  
  <script>
  export default {
    props: ['idComunitat', 'administra'],
    data() {
      return {
        codiCE: this.idComunitat,
        admi: this.administra,
        imageId: 'aai',
        imageSrc: '', // This will be set dynamically
        imageSrcset: '', // This will be set dynamically
        imageAlt: 'aai'
      };
    },
    created() {
      // Assuming these values are fetched or computed dynamically
      const baseName = this.codiCE[0];
      this.imageSrc = require(`@/assets/${baseName}.png`);
      this.imageSrcset = `${require(`@/assets/${baseName}.png`)} 1x, ${require(`@/assets/${baseName}@2x.png`)} 2x`;
    },
    methods: {
        handleClick() {
        // Construct the URL for navigation
        const url = '/llistaComunitats/' + this.codiCE[0];
        // Navigate programmatically
        this.$router.push(url);
        console.log('Component clicked!');
      },
      handleClick2() {
        console.log('Valueadmi:', this.admi);
        // Construct the URL for navigation
        const url = '/administra/' + this.codiCE[0];
        // Navigate programmatically
        this.$router.push(url);
        console.log('Component clicked!');
      }
    }
  }
  </script>
  
  <style>
  #aaa {
    position: relative;
    width: 300px;
    height: 500px;
    left: 0px;
    right: 10px;
    top: 0px;
    overflow: visible;
  }
  #aab {
    fill: rgba(54, 219, 48, 1);
  }
  .aab {
    filter: drop-shadow(7px 7px 15px rgba(20, 81, 2, 1));
    overflow: visible;
    position: absolute;
    width: 350px;
    height: 500px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  #aac {
    position: absolute;
    width: 278px;
    height: 142px;
    left: 33px;
    top: 320px;
    overflow: visible;
  }
  #aad {
    fill: rgba(73, 154, 48, 1);
  }
  .aad {
    filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.878));
    position: absolute;
    overflow: visible;
    width: 144px;
    height: 68px;
    left: 0px;
    top: 89px;
  }
  #aae {
    fill: rgba(73, 154, 48, 1);
  }
  .aae {
    filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 1));
    position: absolute;
    overflow: visible;
    width: 144px;
    height: 68px;
    left: 149px;
    top: 89px;
  }
  #aaf {
    border: 0;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 278px;
    height: 60px;
    text-align: left;
    font-family: Times New Roman;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(8, 6, 6, 1);
  }
  #aag {
    border: 0;
    left: 74px;
    top: 425px;
    position: absolute;
    overflow: visible;
    width: 46px;
    white-space: nowrap;
    text-align: left;
    font-family: Times New Roman;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255, 255, 255, 1);
    text-transform: uppercase;
  }
  #aah {
    border: 0;
    left: 192px;
    top: 425px;
    position: absolute;
    overflow: visible;
    width: 108px;
    white-space: nowrap;
    text-align: left;
    font-family: Times New Roman;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255, 255, 255, 1);
    text-transform: uppercase;
  }
  #aai {
    position: absolute;
    width: 275px;
    height: 241px;
    left: 36px;
    top: 46px;
    overflow: visible;
  }

  @media (max-width: 475.98px) {
  .aaa {
    width: 400px;
  }



}

@media (min-width: 476px) and (max-width: 991.98px) {
  .aaa {
    width: 90%;
    max-width: 100%;
  }

 
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .aaa {
    width: 90%;
    max-width: 100%;
  }
  
}

@media (min-width: 1200px) {
  .aaa {
    width: 90%;
    max-width: 100%;
  }
  
}
  </style>
  