<template>

  <div id="map"></div>
</template>

<script>
// Import Leaflet styles and scripts
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';

// Import your custom marker icons
import customMarkerIcon2 from '@/assets/custom-marker-icon2.png';
import customMarkerIcon3 from '@/assets/custom-marker-icon3.png';

export default {
  props: {
    idInstalacio: {},
    detalls: {},
    ampla: {},
  },
  name: 'LeafletMap',
  data() {
    return {
      map: null,
      lat: null,
      long: null,
      userMarker: null, // Add user marker property
      
      dynamicText:[],
      dynamicText2:0,
      zoom: 15.5,
      center: [],
      C_G1:[],
      C_G2:[],
      rotation: 0,
      geolocPosition: undefined,
      amplada: this.ampla,
      coordenades: "",
      accX: 0,
      accY: 0,
      centreComunitat: "",
      comptador: 0,
      showToolbox: true,
      shownewToolbox: false, // Variable to control the visibility of the toolbox
      toolboxTop: 0, // Variable to store the top position of the toolbox
      toolboxLeft:0, // Variable to store the left position of the toolbox
    };
  },
  mounted() {
    // Initialize the map
    this.trobarAdreca();
  

    // Get user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.lat = position.coords.latitude;
          this.long = position.coords.longitude;

          // Add user marker after getting location
          this.addUserMarker();
        },
        error => {
          console.error('Error getting user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
   
  },
  methods: {
    async opennewlinkgen(index) {
              this.$router.push({ name: 'Generador', params: { id: index } });   
        },
        async opennewlinkcon(index) {
              this.$router.push({ name: 'Consum', params: { id: index } });   
        },
          
    async trobarAdreca(){
      var comptador=0;
      var accX=0;
      var accY=0;
     
      for (let i=0; i<=this.detalls.length-1; i++) {
       
        //console.log("Iddebug:",i,this.detalls[i].id);
        //this.dynamicText.push(this.detalls[i].id);
        await axios.get ('https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?address='+this.detalls[i].CoordinatesA+'&maxLocations=1&f=json&token=AAPK4a63f0964ac549a5908b63d9436dfe2bdYq7H8s0WRoYEKYXyZyqAQYpVzVkvpPEcbng84g2AgWmwRE7phsJlFujbCZfwgA5', {timeout: 2000000})
          .then((response) => {this.coordenadesApi = response.data});
        this.coordenades=this.coordenadesApi.candidates[0].location;
        this.center.push([this.coordenades.x, this.coordenades.y]);
        this.C_G1.push(this.coordenades.x)
        this.C_G2.push(this.coordenades.y)
        
        accX=accX+this.coordenades.x;
        accY=accY+this.coordenades.y;
        comptador++;
        //console.log("Iddebug:",i,this.detalls[i].id);
        //this.dynamicText.push(this.detalls[i].id.toString());
        this.dynamicText.push(this.detalls[i].nom.toString());
     
       
      }
      

      this.centreComunitat=[accX/comptador, accY/comptador];
      this.initMap();
    }, 
    

          
    initMap() {
      // Initialize the map
      this.map = L.map('map').setView([this.centreComunitat[1], this.centreComunitat[0]], 10);

      // Add the tile layer - OpenStreetMap as the base layer
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        maxZoom: 18,
      }).addTo(this.map);

      // Define custom icon options
      const customIcon2 = L.icon({
        iconUrl: customMarkerIcon2,
        iconSize: [96, 96],
        iconAnchor: [48, 96],
        popupAnchor: [0, -96]
      });
      const customIcon3 = L.icon({
        iconUrl: customMarkerIcon3,
      

        iconSize: [64, 64],
        iconAnchor: [32, 64],
        popupAnchor: [0, -64]
      });


      
      let popupContent 
      for (let i=0; i<=this.detalls.length-1; i++) {
        
       console.log("Iddebug:",i,this.detalls[i].id, this.C_G1[i],this.detalls.length-1);
    

       console.log("Coordinates_Type", this.detalls[i].tipus,this.C_G1[i], this.C_G2[i]);
       
  
    // Add other markers

    if (this.detalls[i].tipus == 2) {
        popupContent = `<div><a href="#" class="popup-link" data-id="${this.detalls[i].id}" data-type="con">${this.dynamicText[i]}</a></div>`;
      
        let marker = L.marker([this.C_G2[i], this.C_G1[i]], { icon: customIcon2 }).addTo(this.map)
            .bindPopup(popupContent);
        
        marker.on('dblclick', () => {
            this.opennewlinkcon(this.detalls[i].id);
        });
    } 
      
     }
     for (let i=0; i<=this.detalls.length-1; i++) {
        
        console.log("Iddebug:",i,this.detalls[i].id, this.C_G1[i],this.detalls.length-1);
     
 
        console.log("Coordinates_Type", this.detalls[i].tipus,this.C_G1[i], this.C_G2[i]);
        
   
     // Add other markers
     if (this.detalls[i].tipus == 1) {
        popupContent = `<div><a href="#" class="popup-link" data-id="${this.detalls[i].id}" data-type="con">${this.dynamicText[i]}</a></div>`;
          
         let marker = L.marker([this.C_G2[i], this.C_G1[i]], { icon: customIcon3 }).addTo(this.map)
             .bindPopup(popupContent);
         
         marker.on('dblclick', () => {
             this.opennewlinkgen(this.detalls[i].id);
         });
     }
     
       
      }
    // Attach click event listener to popup links
    this.map.on('popupopen', (e) => {
        const links = e.popup._contentNode.querySelectorAll('.popup-link');
        links.forEach(link => {
          link.addEventListener('click', (event) => {
            event.preventDefault();
            const id = link.getAttribute('data-id');
            const type = link.getAttribute('data-type');
            if (type === 'con') {
              this.opennewlinkcon(id);
            } else if (type === 'gen') {
              this.opennewlinkgen(id);
            }
          });
        });
      });
    
    
    }
     ,

     addLegend() {
      const legend = L.control({ position: 'bottomright' });

      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'legend');
        div.innerHTML += '<i style="background: url(path/to/icon2.png) no-repeat; background-size: contain; width: 18px; height: 18px; display: inline-block;"></i><span>Type 2</span><br>';
        div.innerHTML += '<i style="background: url(path/to/icon3.png) no-repeat; background-size: contain; width: 18px; height: 18px; display: inline-block;"></i><span>Type 1</span><br>';
        return div;
      };

      legend.addTo(this.map);
    }


,


    addUserMarker() {
      // Define custom icon for user marker
      const customIcon3 = L.icon({
        iconUrl: customMarkerIcon3,
        iconSize: [128, 128],
        iconAnchor: [16, 128],
        popupAnchor: [0, -32]
      });

      // Remove previous user marker if exists
      if (this.userMarker) {
        this.map.removeLayer(this.userMarker);
      }

      // Add user marker to the map
      this.userMarker = L.marker([this.lat,this.long], { icon: customIcon3 }).addTo(this.map)
        .bindPopup('You are here')
        .openPopup();
    }
  }
};
</script>

<style>
#map {
  height: 350px;
  width: 750px;
}
</style>
