<template> 
  <div id="Bar">
      <br>
      <ChartBarBase :styles="myStyles" :chart-data="chartData" :LabelString2="this.labelstring"/>
      <div id="TablaEnergias">
        <table id="Energias">
          <tr>
            <td><p>Energia generada</p></td><td></td><td></td><td></td><td><p v-if="EConsumidaTotalBool">Consum</p> </td></tr>
            <tr><th>{{ EgeneradaTotal + ' ' + labelstring }}</th><td></td><td></td><td></td><th v-if="EConsumidaTotalBool">{{ EConsumidaTotal + ' ' + labelstring }}</th>
        <!--<th> <p>Total energia generada:</p> {{EgeneradaTotal}} kWh</th>
       <th><p>Total Consum:</p> {{EConsumidaTotal}} kWh</th>-->
          </tr>
        </table>
        <br>
      </div>
    </div>
</template>

<script>

import ChartBarBase from "./ChartBarBase";
import axios from 'axios';
export default {
  props:['Dades','anyR','mesR','tipusR'],
  components: { 
    ChartBarBase,
  },
  data() {
    return {
      chartData: null,
      codiInstalacio:"",
      any:"",
      mes:"",
      tipus:"",
      EgeneradaTotal:0,
      EGeneradaApi:"",
      EGeneradatext:"",
      EGeneradaArray:[],
      EConsumidaArray:[],
      mesosArray:[],
      EConsumidaTotal:0,
      EConsumidaTotalBool:0,
      labelstring:"KWh",
      toggle:0
    };
  },
  computed: {
    myStyles () {
      return {width: '750px'}
    }
  },
  mounted() {
    this.any=this.anyR;
    this.mes=this.mesR;
    this.tipus=this.tipusR;
    this. EGeneradaAxios();
  },
   methods: {

    formatUnit(value) {
            if (value >= 10000 & this.toggle===0) {
              this.toggle=3;
              this.labelstring="MWh";
                value = value / 1000;
                return value.toFixed(2)
            } else if (value < 1 & this.toggle===0) {
                this.toggle=1;
                this.labelstring="Wh";
                value = value * 1000;
            }  else if (value > 1 & this.toggle===0) {
                this.toggle=2;
                this.labelstring="KWh";
                return value.toFixed(2)
            }
            else if (this.toggle===1) {
                this.labelstring="Wh";
                  value = value * 1000;
                }
            else if (this.toggle===2) {
                this.labelstring="KWh";
          }
          else if (this.toggle===3) {
          this.labelstring="MWh";
          value = value / 1000;
          }
          return value.toFixed(2)
          },
         async EGeneradaAxios(){
          await axios.get(this.$domini + 'energiaCE2?idInstalacio=' + this.Dades[0][0] + "&tipus="+ this.tipus +"&dia=31&mes="+this.mes+"&any=" + this.any, { withCredentials: true})
            .then((response) => {this.EGeneradaApi = response.data});
            await axios.get(this.$domini + 'energiaConsumicio?idInstalacio=' + this.Dades[0][0] + "&tipus="+ this.tipus +"&dia=31&mes="+this.mes+"&any=" + this.any, { withCredentials: true})
            .then((response) => {this.EGeneradaApi2 = response.data});
            //console.log(this.EGeneradaApi)
            //this.EGeneradatext=JSON.parse(this.EGeneradaApi)
            //console.log(this.EGeneradatext) 

            let length = Math.min(this.EGeneradaApi["eGenerada"].length, this.EGeneradaApi2["eConsumida"].length, this.EGeneradaApi["temps"].length);
            let max=0;
          
            for (let x = 0; x < length; x++) {
              
             if (max<this.EGeneradaApi["eGenerada"][x])
              {
                max=this.EGeneradaApi["eGenerada"][x]
              }
             else if (max<this.EGeneradaApi2["eConsumida"][x])
             {
              max=this.EGeneradaApi2["eConsumida"][x]
             }
            
            }
            this.formatUnit(max)
            console.log("MAX:",max)
          for (let x = 0; x < length; x++) {
              this.EGeneradaArray.push(this.formatUnit(this.EGeneradaApi["eGenerada"][x]));
            
              this.EConsumidaArray.push(this.formatUnit(this.EGeneradaApi2["eConsumida"][x]));
              this.mesosArray.push(this.EGeneradaApi["temps"][x]);
              this.EgeneradaTotal += parseFloat(this.formatUnit(this.EGeneradaApi["eGenerada"][x]));
              this.EConsumidaTotal += parseFloat(this.formatUnit(this.EGeneradaApi2["eConsumida"][x]));
          }
         //console.log(this.EGeneradaArray)
         //console.log(this.mesosArray)
            this.EgeneradaTotal=parseFloat(this.EgeneradaTotal)
            this.EgeneradaTotal=this.EgeneradaTotal.toFixed(2)
            this.EConsumidaTotal=parseFloat(this.EConsumidaTotal)
         
            if (this.EConsumidaTotal==0){
              this.EConsumidaTotalBool=false;
              //console.log(this.EConsumidaTotalBool);
              this.fillDataGen();
            }
            else{
              this.EConsumidaTotalBool=true;
              //console.log(this.EConsumidaTotalBool);
              this.fillData();
            }
            this.EConsumidaTotal=this.EConsumidaTotal.toFixed(2)

         //return this.fillData();
        },


    fillData:function() {
      this.chartData = {
        labels: this.mesosArray,
        datasets: [
          {
            backgroundColor: "#3CFF00",
            borderColor: "#2DBF00",
            data: this.EGeneradaArray,
            borderWidth: 0.5,
            label: "Energia Generada "
          },
          {
            backgroundColor: "#F9DC4E",
            borderColor: "#F9D000",
            borderWidth: 0.5,
            data: this.EConsumidaArray,
            label: "Consum "
          },
        ]
      };
    },
    fillDataGen:function() {
      this.chartData = {
        labels: this.mesosArray,
        datasets: [
          {
            backgroundColor: "#3CFF00",
            borderColor: "#2DBF00",
            data: this.EGeneradaArray,
            borderWidth: 0.5,
            label: "Energia Generada "
          },
        ]
      };
    },
    
}
};
</script>

<style scoped>
#Bar{
  width:800px;
  height:320px;
  text-align: center;
  /*display:block;*/
  margin-left:auto;
  margin-right:auto;
  border-color: white;
}
table,th{
  margin:0 auto;
  text-align:center;
  size:70%;
  padding:5px;
  border-color: white;
}
#Energias{
  Border-spacing:10px;
}
p{
  font-size: 16px;
  color: #26a300;
  border-color: white;
}
th{
    font-size:25px;
    color:#2C312B;
    font-weight:bold;
    background-color: #DCFFD1;
    height:40px;
    margin:0%;
    padding: 0%;
    border-color: white;
}
th,td{ 
    border: 3px;
    border-radius: 15px;
    border-color: white;
}
</style>