<template>
    <div>
        <table id="caracteristiques">
            <tr>
                <td>Consum anual</td> 
                <td>Consum mensual</td> 
                <td>Consum diària</td> 
         
            </tr>
            <tr>
                <td>{{ this.formatUnit(this.e_any) }}</td>
                <td>{{ this.formatUnit(this.e_mes) }}</td>
                <td>{{ this.formatUnit(this.e_dia) }}</td>
            </tr> 
        
        </table>
    </div>
</template>

<script>
import axios from 'axios';

export default {

    props:{
        idInstalacio: {},
        consum: {},
        anySeleccionat: {},
        mesSeleccionat:{}

    },
    data() {
        return {
            e_dia: 0,
            p_diat: "0",
            any: this.anySeleccionat,
            mes: this.mesSeleccionat,
            e_mes: 0,
            p_mest: "0",

            e_any: 0,
            p_anyt: "0",
            formattedDate: "",
            formattedDay: "",
            formattedMonth: "",
            formattedYear: "",
            infoapi1: "",
            infoapi2: "",
            infoapi3: "",
       
        }
    },
    mounted(){
        this.Generacions();
    },

    methods: {
        
        async Generacions(){
            var d = new Date();
            console.log(this.idInstalacio);
            this.formattedDate=d.getUTCDate() +"/"+ (d.getUTCMonth()+1) +"/"+ d.getUTCFullYear();
            this.formattedDay=d.getUTCDate();
            //this.formattedMonth=d.getUTCMonth()+1;
            
            console.log("ANY_consumicio",this.any);
            console.log("mes_consumicio",this.mes);
            //this.formattedYear=2023;
            try {
                const response1 = await axios.get(
                    `${this.$domini}energiaConsumicio?idInstalacio=${this.idInstalacio}&tipus=1&dia=${this.formattedDay}&mes=${this.mes}&any=${this.any}`,
                    { withCredentials: true},{timeout: 2000000}
                );
                this.infoapi1 = response1.data;
                console.log("consINFOapi1: ", this.infoapi1)
                if (this.infoapi1 && this.infoapi1.eConsumida) {
                    const eGeneradaArray = Object.values(this.infoapi1.eConsumida);
                    const suma = eGeneradaArray.reduce((acc, valor) => acc + valor, 0);
                    this.p_anyt += suma;
                } else {
                    console.error("Dades anuals no disponibles o no definides.");
                }
            } catch (err) {
                this.handleError(err);
            }

            try {
                const response2 = await axios.get(
                    `${this.$domini}energiaConsumicio?idInstalacio=${this.idInstalacio}&tipus=2&dia=${this.formattedDay}&mes=${this.mes}&any=${this.any}`,
                    { withCredentials: true},{timeout: 2000000}
                );
                this.infoapi2 = response2.data;
                console.log("consINFOapi2: ", this.infoapi2)
                if (this.infoapi2 && this.infoapi2.eConsumida) {
                    const eGeneradaArray2 = Object.values(this.infoapi2.eConsumida);
                    const suma2 = eGeneradaArray2.reduce((acc, valor) => acc + valor, 0);
                    this.p_mest += suma2;
                } else {
                    console.error("Dades mensuals no disponibles o no definides.");
                }
            } catch (err) {
                this.handleError(err);
            }

            try {
                const response3 = await axios.get(
                    `${this.$domini}energiaConsumicio_daily?idInstalacio=${this.idInstalacio}&tipus=3&dia=${this.formattedDay}&mes=${this.mes}&any=${this.any}`,
                    { withCredentials: true},{timeout: 2000000}
                );
                this.infoapi3 = response3.data;
                console.log("consINFOapi3: ", this.infoapi3)
                if (this.infoapi3 && this.infoapi3.eConsumida) {
                    const eGeneradaArray3 = Object.values(this.infoapi3.eConsumida);
                    const suma3 = eGeneradaArray3.reduce((acc, valor) => acc + valor, 0);
                    this.p_diat += suma3;
                } else {
                    console.error("Dades diàries no disponibles o no definides.");
                }
            } catch (err) {
                this.handleError(err);
            }

            this.e_any = parseFloat(this.p_anyt).toFixed(2);
            this.e_dia = parseFloat(this.p_diat).toFixed(2);
            this.e_mes = parseFloat(this.p_mest).toFixed(2);
        },
        formatUnit(value) {
            if (value >= 1000) {
                return (value / 1000).toFixed(2) + " MWh";
            } else if (value < 1) {
                return (value * 1000).toFixed(2) + " Wh";
            } else {
                return (value / 1).toFixed(2) + " KWh";
            }
        },
        handleError(err) {
            this.carregant = 0;
            alert("\nError:\n\nNo s'han trobat dades per a la data seleccionada.\nSi us plau, torni-ho a intentar o seleccioni una altra data.");
            console.error(err);
        },


    }



}
</script>

<style>


.titol{
  text-align: center;
  /*border-collapse: collapse;*/
  border-spacing: 0;
  border-radius:10px;
  padding:10px;
  font-size:15px;
  
  
}

.valores{
 background-color: #B6FF9F;
 border-radius: 10px;

}


@media (max-width: 285.98px) {
  .valores{
    font-size: 9px;
  }
  .unitats3{
    font-size: 9px;
  }
}

@media (min-width: 286px) and (max-width: 379.98px) {
  .valores{
    font-size: 13px;
  }
  .unitats3{
    font-size: 13px;
  }
}
@media (min-width: 380px) {
    .valores{
    font-size: 15px;
  }
  .unitats3{
    font-size: 15px;
  }
}

</style>