<template>
  <!--
    Dibuixa una gràfica (donut) que mostra la quota autàrquica. EEl valor ve d'un component superior.


    A FER: CAutarquica i CAutoconsum són components idèntics. No és urgent però es poden generalitzar per fer-ne servir només un per a ambdues coses
  -->
  <div id="Stack">
     <!--<p v-if="this.comprobacion">Valor mayor al 50</p>
     <p v-else>Valor menor a 50</p>-->
      <ChartHorizontalStackedBar :chart-data="chartDataAutarquica"  :height="100"/>
      </div>
</template>

<script>
import ChartHorizontalStackedBar from "../components/ChartHorizontalStackedBar";
export default {
  components: {
    ChartHorizontalStackedBar,
  },
  props: {
    chartDataAutarquica: {},
    idInstalacio: {},
    anyProp: {},
  },
  data() {
    return {
      chartData: null,  
      codiInstalacio: this.idInstalacio,
      any: this.anyProp,
      CAutarquicaApi:"",
      CAutaquicatext:"", 
      QuotaAutarquica:"",
      ComplementariQAutarquica:"",
      QuotaAutarquicaNum:"",
      
    };
  },
  
};
</script>

<style scoped>
#Stack {
  width: 175px;
}



@media (max-width: 374.98px) {
  #Stack {
    width:180px;
  }

}

@media (min-width: 375px) and (max-width: 539.98px) {
  #Stack {
    width:240px;
  }

}

/* For medium devices (tablets, 768px to 991.98px wide) */
@media (min-width: 540px) and (max-width:767.98px) {
  #Stack {
    width:345px;
  }

}

/* For large devices (desktops, 992px to 1199.98px wide) */
@media (min-width: 768px) and (max-width: 1199.98px) {
  #Stack {
    width:240px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  #Stack {
    width:300px;
  }
}


/* For extra-large devices (large desktops, 1200px and above) */
@media (min-width: 1200px) {
  #Stack {
    width:170px;
  }
}
</style>

