import { render, staticRenderFns } from "./Assignacio.vue?vue&type=template&id=cc9ffc44&"
import script from "./Assignacio.vue?vue&type=script&lang=js&"
export * from "./Assignacio.vue?vue&type=script&lang=js&"
import style0 from "./Assignacio.vue?vue&type=style&index=0&id=cc9ffc44&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports