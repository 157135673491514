<template>
  <div id="app">
    <div id="nav" v-if="!isPrintPage">
      <nav class="navbar navbar-expand-sm fixed-top navbar-light" style="background-color: #3cff00;">
        <div class="container-fluid justify-content"> 

          <router-link to="/" class="navbar-brand">
            <img src="../src/images/LogoEmelcat.png" width="90%">
          </router-link>

          <ul class="navbar-nav">
            <li class="nav-item" v-if="autenticat && !user">
              <router-link to="/seleccio" class="nav-link active" aria-current="page">Inici</router-link>
            </li>
            <li class="nav-item" v-if="isBackButtonVisible">

              <button @click="goBack" class="router-link nav-link" style="border: none; background-color: transparent;">
                <strong>Comunitat</strong>
              </button>

            </li>
            <li class="nav-item">
              <router-link v-if="autenticat" to="/logout" class="nav-link active" aria-current="page">Tanca la sessió</router-link>
              <router-link v-else to="/" class="nav-link active" aria-current="page">Inicia la sessió</router-link>
            </li>
            
          </ul>
        </div>
      </nav>
    </div>
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios';


export default {
  computed: {
    isBackButtonVisible() {
      return this.$route.name === 'Generador' || this.$route.name === 'Consum';
    },
    isPrintPage() {
      return this.$route.name === 'Informe';
    }
  },
  data() {
    return {
      respostaApi: "",
      respostaApi2: "",
      user: false,
      autenticat: false,
      missatge: ""
    };
  },
  mounted() {
    this.comprovaAutenticacio();
    this.comprovaPermis();
    window.addEventListener("unload", this.handleUnload);
  },
  destroyed() {
    window.removeEventListener("unload", this.handleUnload);
  },
  methods: {
    goBack() {
      window.history.back();
    },
    handleUnload(event) {
      event.preventDefault();
      this.tancaSessio();
    },
    async tancaSessio() {
      try {
        await axios.get(this.$domini + "logoutEVC", { withCredentials: true });
      } catch (error) {
        console.error("Error cerrando sesión:", error);
      }
    },
    async comprovaAutenticacio() {
      try {
        const response = await axios.get(this.$domini + "comprovaSessio", { withCredentials: true });
        this.respostaApi = response.data;
        this.autenticat = this.respostaApi.autenticacio;
        console.log("AUTENTICAT", this.autenticat);
      } catch (error) {
        console.error("Error in comprovaAutenticacio:", error);
      }
    },
    async comprovaPermis() {
      try {
        const response = await axios.get(this.$domini + "obteComunitats", { withCredentials: true });
        this.respostaApi2 = response.data;
        this.missatge = this.respostaApi2.missatge;
        this.user = this.missatge === "redireccionar_consum";
        console.log("USER", this.user);
      } catch (error) {
        console.error("Error in comprovaPermis:", error);
      }
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 1%;
}

.navbar-brand {
  max-width: 200px; 
  overflow: hidden; 
  white-space: nowrap;  
  justify-content: left;
}

#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #0c4105c7;
}
#nav a.router-link-exact-active {
  color: #42b983;
}

@media (max-width: 298.98px) {
  .navbar-nav {
    font-size: 5px;
  }
}

@media(min-width: 299px) and (max-width: 337.98px) {
  .navbar-nav {
    font-size: 8px;
  }
}

@media (min-width: 338px) and (max-width: 379.98px) {
  .navbar-nav {
    font-size: 8px;
  }
}

@media (min-width: 380px) and (max-width: 575.98px) {
  .navbar-nav {
    font-size: 10px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .navbar-nav {
    font-size: 11px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-nav {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .navbar-nav {
    font-size: 14px;
  }
}
</style>
