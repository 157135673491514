///////////////////////////////Muntatge del sistema de router per navegacio entre vistas////////////////////////
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import BetaManagement from '../views/BetaManagement.vue'
import Informe from '../views/Informe.vue'

import Comunitats from '../views/Comunitats.vue'
import Consum from '../views/Consum.vue'
import Generador from '../views/Generador.vue'
import AdminComunitat from '../views/AdminComunitat.vue'
import SeleccioComunitats from '../views/SeleccioComunitats.vue'
import Logout from '../views/Logout.vue'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'



import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)

import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

Vue.use(IconsPlugin)
Vue.use(VueRouter)

const routes = [
  {
    //Pàgina principal de l'aplicació. En la versió actual mostra el formulari de login
    path: '/',
    name: 'Principal',
    component: Home
  },


  {
    //Hi trobem la llista (taula d'instal·acions a una comunitt, així com el resum de les seves característiques)
    path: '/llistaComunitats/:id',
    name: 'Instal·lacions de la comunitat',
    component: Comunitats,
    params: true
  },
  {
    //Hi trobem la llista (taula d'instal·acions a una comunitt, així com el resum de les seves característiques)
    path: '/BetaManagement/:id',
    name: 'BetaManagement',
    component: BetaManagement,
    props: true
  },
  {
    path: '/Informe/:id',
    name: 'Informe',
    component: Informe,
    props: route => ({
      id: route.params.id,
      anyR: route.query.anyR,
      mesR: route.query.mesR,
      tipusR: route.query.tipusR
    })
  },
  {
    //Vista dels consums
    path: '/vistaConsum/:id',
    name: 'Consum',
    component: Consum,

  },

  {
    //Vista dels generadors
    path: '/vistaGenerador/:id',
    name: 'Generador',
    component: Generador,

  },

  {
    //Taula on l'instal·lador pot fer canvis als coeficients de repartiment
    path: '/administra/:id',
    name: 'Administra la comunitat',
    component: AdminComunitat,
  },
  {
    //Pantalla (falta decorar) on es tria la comunitat  a que es vol accedir d'entre les que se'n té permís. També redirecciona els usuaris de permisos únics auomàticament a la pantalla que estiguin autoritzats a veure
    path: '/seleccio',
    name: 'Selecció de comunitats',
    component: SeleccioComunitats,
  },
  {
    //Pantalla que no mostra cap informació, només serveix de pas per tancar la sessió
    path: '/logout',
    name: 'Tanca la sessió',
    component: Logout,
  },
  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});

export default router
