<template>
    <!--
        Resum (pàgina principal) d'una comunitat, amb un mapa que mostra les localitzacions de les comunitats, quotes de rendiment, resum de la potència i energia,
        i la llista d'instal·lacions que la formen amb les seves principals característiques.
    -->



    <div>

    <b-modal ref="my-modal" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>No s'han trobat les dades sol·licitades</h4>
   </div>
      <b-button class="mt-3" variant="outline-success" block @click="hideModal">Tanca</b-button>
    </b-modal>
   

        <b-container v-if="trigger1" id="produccions">
   

        
            <b-row class="d-flex">
                <b-col class="align-items-center">
                    <h4 class="title">Producció <br>anual</h4>
                    <div class="valores2">{{this.formatUnit(e_any)}}<br><span class="unitats2"></span></div>
                </b-col>
                
                <b-col class="align-items-center">
                    <h4 class="title">Producció<br> mensual</h4>
                    <div class="valores2">{{this.formatUnit(e_mes)}}<br><span class="unitats2"></span></div>
                </b-col>

                <b-col class="align-items-center">
                    <h4 class="title">Producció <br> diària</h4>
                    <div class="valores2">{{this.formatUnit(e_dia)}}<br></div>
                </b-col>
            
                <b-col class="align-items-center">
                    <h4 class="title">Potència<br> generada</h4>
                    <div class="valores2">{{this.formatUnit2(p_generada)}}<br></div>
                </b-col>
               
                <b-col class="align-items-center">
                    <h4 class="title">Potència <br>consumida</h4>
                    <div class="valores2">{{this.formatUnit2(p_consumida)}}<br></div>
                </b-col>
              
                <b-col class="align-items-center">
                    <h4 class="title">Estalvi <br> emissions</h4>
                    <div class="valores2">{{EmissionsCO2acc}}<span class="unitats2"> tones/any</span></div>
                </b-col>


            
              </b-row>
              <b-row class="d-flex">
                <b-col class="align-items-right">
     


                </b-col>
            </b-row>
        </b-container>
      
       
                
              
    </div>
</template>

<script>
import axios from 'axios';
//
export default {
  components: {
            //
        },
    props:{
    
        consum: {},
        codiCE: {},
        date2:new Date(),
        model: {
      type: Object,
      default: () => ({ date: new Date() }) // Set default value for model
    },

    },
    data() {
        return {
            e_dia: 0,
            e_diat: "0",
            trigger1: true,
            e_mes: 0,
            e_mest: "0",
            totaldays: 0,
            e_any: 0,
            e_anyt: "0",

            formattedDate: "",
            formattedDay: "",
            formattedMonth: "",
            formattedYear: "",
            var_pconsum: 0,
            var_pgen: 0,
            var_anyt: 0,
            var_mest: 0,
            var_diat: 0,
            p_generada:0,
            p_consumida: 0,
            emissions: 0,
            instalacionsapi: "",
            EmissionsApi: "",
            EmissionsCO2: "",
            EmissionsCO2acc: "0",
            factorCO2:0.25,

            potenciesApi: "",


            data: "",
            any: "",
            mes: "",
            dia: "",
            hora: "",
            minuts: "",

        }
    },
    watch: {
    'model.date': {
      handler() {
        this.Generacions(); // Call Generacions method when model.date changes
      },
      immediate: true // Call handler immediately when component mounts
    }
  },
    mounted(){
    
      this.idComunitat = this.$route.params.id;
  
      
  
                        
     
    },

    methods: {        
      
      formatUnit(value) {
        console.log("value", value);
            if (value >= 1000) {
                return (value / 1000).toFixed(2) + " MWh";
            } else if (value < 1) {
                return (value * 1000).toFixed(2) + " Wh";
            } else {
                return value.toFixed(2) + " kWh";
            }
        },
        formatUnit2(value) {
            if (value >= 1000) {
                return (value / 1000).toFixed(2) + " MW";
            } else if (value < 1) {
                return (value * 1000).toFixed(2) + " W";
            } else {
                return value.toFixed(2) + " kW";
            }
        },  
      calculateDaysInMonth() {
      if (this.formattedYear && this.formattedMonth) {
        this.totalDays = new Date(this.formattedYear, this.formattedMonth, 0).getDate();
      } 
      },
        hideModal() {
            this.$refs['my-modal'].hide()
        },
        async Generacions() {
    try {
        console.log("COMM", this.idComunitat);

        this.data = new Date(this.model.date);
        console.log("ARW", this.data, this.model.date);

        this.any = this.data.getFullYear();
        this.mes = this.data.getMonth() + 1;
        this.dia = this.data.getDate();
        this.hora = this.data.getHours();
        this.minuts = this.data.getMinutes();

        this.formattedDate = `${this.data.getUTCDate()}/${this.data.getUTCMonth() + 1}/${this.data.getUTCFullYear()}`;
        this.formattedDay = this.data.getUTCDate();
        this.formattedMonth = this.data.getUTCMonth() + 1;
        this.formattedYear = this.data.getUTCFullYear();

        const [ response] = await Promise.all([
           axios.get(`${this.$domini}generadorsComunitat?idComunitat=${this.idComunitat}`, { withCredentials: true, timeout: 2000000 })
        ]);

        this.instalacionsapi = response.data;
        console.log(this.instalacionsapi.generadors);
        console.log("COMM2", this.idComunitat);

        this.e_anyt = 0;
        this.e_diat = 0;
        this.e_mest = 0;
        this.EmissionsCO2acc = 0;
        this.var_pconsum = 0;
        this.var_pgen = 0;
        this.var_anyt = 0;
        this.var_mest = 0;  
        this.var_diat = 0;

        this.data2 = new Date();

    

      

        const apiCalls = this.instalacionsapi.generadors.map((generador) => 
            axios.get(`${this.$domini}esquemaGenerador_main?Instalacio=${generador}&codiCE=${this.idComunitat}&any1=${this.data2.getFullYear()}&mes1=${String(this.data2.getMonth() + 1).padStart(2, '0')}&dia1=${String(this.data2.getDate()).padStart(2, '0')}&hora1=${String(this.data2.getHours()).padStart(2, '0')}&minut1=${String(this.data2.getMinutes()).padStart(2, '0')}`, { withCredentials: true, timeout: 2000000 })
        );

        const esquemaGeneradorResponses = await Promise.all(apiCalls);

        for (let i = 0; i < esquemaGeneradorResponses.length; i++) {
            const response = esquemaGeneradorResponses[i];
            this.esquemaApi = response.data;
            this.var_pconsum += this.esquemaApi.pConsumida;
            this.var_pgen += this.esquemaApi.pGenerada;
           

            const generador = this.instalacionsapi.generadors[i];
            console.log("COMM3", this.idComunitat);
            this.idInstalacio = generador;
            console.log(this.idInstalacio);

            const [infoapiResponse, infoapi2Response, infoapi3Response] = await Promise.all([
                axios.get(`${this.$domini}energiaCE?idInstalacio=${this.idInstalacio}&tipus=1&dia=${this.formattedDay}&mes=${this.formattedMonth}&any=${this.formattedYear}`, { withCredentials: true, timeout: 2000000 }),
                axios.get(`${this.$domini}energiaCE?idInstalacio=${this.idInstalacio}&tipus=2&dia=${this.formattedDay}&mes=${this.formattedMonth}&any=${this.formattedYear}`, { withCredentials: true, timeout: 2000000 }),
                axios.get(`${this.$domini}energiaCE?idInstalacio=${this.idInstalacio}&tipus=3&dia=${this.formattedDay}&mes=${this.formattedMonth}&any=${this.formattedYear}`, { withCredentials: true, timeout: 2000000 })
            ]);

            const infoapi = infoapiResponse.data;
            if (infoapi && infoapi.eGenerada) {
                console.log("anual", infoapi.eGenerada);
                const eGeneradaArray = Object.values(infoapi.eGenerada);
                const suma = eGeneradaArray.reduce((acc, valor) => acc + valor, 0);
                console.log("La suma és:", suma);
                this.var_anyt += suma;
            } else {
                console.error("Dades anuals no disponibles o no definides.");
            }

            const infoapi2 = infoapi2Response.data;
            if (infoapi2 && infoapi2.eGenerada) {
                console.log("mensual", infoapi2.eGenerada);
                const eGeneradaArray2 = Object.values(infoapi2.eGenerada);
                const suma2 = eGeneradaArray2.reduce((acc, valor) => acc + valor, 0);
                console.log("La suma és:", suma2);
                this.var_mest += suma2;
            } else {
                console.error("Dades mensuals no disponibles o no definides.");
            }

            const infoapi3 = infoapi3Response.data;
            if (infoapi3 && infoapi3.eGenerada) {
                console.log("dia", infoapi3.eGenerada);
                const eGeneradaArray3 = Object.values(infoapi3.eGenerada);
                const suma3 = eGeneradaArray3.reduce((acc, valor) => acc + valor, 0);
                console.log("La suma és:", suma3);
                this.var_diat += suma3;
            } else {
                console.error("Dades diàries no disponibles o no definides.");
            }
        }

        this.e_anyt = this.var_anyt;
        this.e_mest = this.var_mest;
        this.e_diat = this.var_diat;



        this.p_consumida = this.var_pconsum;
        this.p_generada = this.var_pgen;
        this.calculateDaysInMonth();
        console.log("logA:", this.totalDays);
        this.e_dia = parseFloat(this.e_mest / this.totalDays);

        this.e_mes = parseFloat(this.e_mest);
        this.e_any = parseFloat(this.e_anyt);
        this.EmissionsCO2 = this.e_any * this.factorCO2;
        this.EmissionsCO2acc = (this.EmissionsCO2 / 1000).toFixed(2); // a tones

        this.trigger1 = true;
        this.var_pconsum = 0;
        this.var_pgen = 0;
        this.var_anyt = 0;
        this.var_mest = 0;  
        this.var_diat = 0;
    } catch (err) {
        this.carregant = 0;
        this.$refs['my-modal'].show();
        console.error(err);
    }
}

                 
    }



}
</script>

<style>


.title{

  /*border-collapse: collapse;*/
  border-spacing: 0;
  margin:0 auto;

  border-spacing: 0;
  border-radius:10px;
  padding:5px;
  font-size: 17px;
  font-weight: bold;
  
}
.valores2 {
    background-color: #B6FF9F;
    border-radius: 10px;
    
    font-size:17px;
    font-weight: bold;
    padding: 10px;
    margin-top: 15px; 
    margin-bottom: 15px;
    margin-right: 5px;
    margin-left:5px;
    width: 100%;
    max-width:100%;
    text-align: center; 
}

.unitats2 {
  
    display: inline;
    width: 100%; 
    max-width:100%;
    text-align: center; 
}


@media (max-width: 374.98px) {
  h4 {
    font-size: 12px;
  }
  .title {
    font-size: 12px;
  }
  .valores2 {
    font-size: 12px;
  }
}
@media (min-width: 375px) and (max-width: 575.98px) {
    .unitats2 {
    font-size: 12px;
  }
  .title {
    font-size: 12px;
  }

  .valores2 {
    font-size: 12px;
  }
}

@media (min-width: 576px) and (max-width: 789.98px) {
    .unitats2 {
    font-size: 12px;
  }
  .title {
    font-size: 12px;
  }
  .valores2 {
    font-size: 12px;
  }

}

/* For medium devices (tablets, 768px to 991.98px wide) */
@media (min-width: 790px) and (max-width: 995.98px) {
    .unitats2 {
    font-size: 13px;
  }
  .title {
    font-size: 13px;
  }
  .valores2 {
    font-size: 13px;
  }
}

/* For large devices (desktops, 992px to 1199.98px wide) */
@media (min-width: 996px) and (max-width: 1199.98px) {
    .unitats2 {
    font-size: 14px;
  }
  .title {
    font-size: 14px;
  }

  .valores2 {
    font-size: 14px;
  }
}

/* For extra-large devices (large desktops, 1200px and above) */
@media (min-width: 1200px) {
    .unitats2 {
    font-size: 15px;
  }
  .title {
    font-size: 15px;
  }
  .valores2 {
    font-size: 15px;
  }
}

</style>