<template>
    <!--
        Per a un generador, mostra el que produeix en un any, mes i dia. 

        FALTA FER: Fer la mitjana dels dies i mesos, ara mateix es pren el darrer dia/mes i això és una mostra molt petita.
    -->
    <div>
    <b-modal ref="my-modal" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>No s'han trobat les dades sol·licitades</h4>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="hideModal">Tanca</b-button>
    </b-modal>

    <table id="caracteristiques">
        <tr>
            <td>Producció<br> anual</td>
            
            <td>Producció <br>mensual</td>
            
            <td>Producció<br> diària</td>
        
        </tr>
        <tr>
            <td>{{ this.formatUnit(this.e_any) }}</td>
            <td>{{ this.formatUnit(this.e_mes) }}</td>
            <td>{{ this.formatUnit(this.e_dia) }}</td>

        </tr>
        
    </table>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        idInstalacio: {},
        consum: {},
        anySeleccionat: {},
        mesSeleccionat:{}
    },
    data() {
        return {
            e_dia: 0,
            p_diat: "0",

            e_mes: 0,
            p_mest: "0",

            e_any: 0,
            p_anyt: "0",
            formattedDate: "",
            formattedDay: "",
            formattedMonth: "",
            formattedYear: "2023",
            infoapi1: "",
            infoapi2: "",
            infoapi3: "",
            any: this.anySeleccionat,
            mes: this.mesSeleccionat
        };
    },
    mounted() {
        this.Generacions();
    },
    methods: {
        hideModal() {
            this.$refs['my-modal'].hide();
        },
        async Generacions() {
            var d = new Date();
            this.formattedDate = d.getUTCDate() + "/" + (d.getUTCMonth() + 1) + "/" + 2023;
            this.formattedDay = d.getUTCDate();
            //this.formattedMonth = d.getUTCMonth() + 1;
            this.formattedMonth = this.mes;
            this.formattedYear =this.any;
           
            console.log("ANY_prod",this.any);
            console.log("mes_prod",this.mes);
            //this.formattedYear = 2023;
            try {
                const response1 = await axios.get(
                    `${this.$domini}energiaCE?idInstalacio=${this.idInstalacio}&tipus=1&dia=${this.formattedDay}&mes=${this.mes}&any=${this.any}`,
                    { withCredentials: true},{timeout: 2000000}
                );
                this.infoapi1 = response1.data;

                if (this.infoapi1 && this.infoapi1.eGenerada) {
                    const eGeneradaArray = Object.values(this.infoapi1.eGenerada);
                    const suma = eGeneradaArray.reduce((acc, valor) => acc + valor, 0);
                    this.p_anyt += suma;
                } else {
                    console.error("Dades anuals no disponibles o no definides.");
                }
            } catch (err) {
                this.handleError(err);
            }

            try {
                const response2 = await axios.get(
                    `${this.$domini}energiaCE?idInstalacio=${this.idInstalacio}&tipus=2&dia=${this.formattedDay}&mes=${this.mes}&any=${this.any}`,
                    { withCredentials: true},{timeout: 2000000}
                );
                this.infoapi2 = response2.data;

                if (this.infoapi2 && this.infoapi2.eGenerada) {
                    const eGeneradaArray2 = Object.values(this.infoapi2.eGenerada);
                    const suma2 = eGeneradaArray2.reduce((acc, valor) => acc + valor, 0);
                    this.p_mest += suma2;
                } else {
                    console.error("Dades mensuals no disponibles o no definides.");
                }
            } catch (err) {
                this.handleError(err);
            }

            try {
                const response3 = await axios.get(
                    `${this.$domini}energiaCE_daily?idInstalacio=${this.idInstalacio}&tipus=3&dia=${this.formattedDay}&mes=${this.mes}&any=${this.any}`,
                    { withCredentials: true},{timeout: 2000000}
                );
                this.infoapi3 = response3.data;
                console.log(this.infoapi3)
                if (this.infoapi3 && this.infoapi3.eGenerada) {
                    const eGeneradaArray3 = Object.values(this.infoapi3.eGenerada);
                    const suma3 = eGeneradaArray3.reduce((acc, valor) => acc + valor, 0);
                    this.p_diat += suma3;
                } else {
                    console.error("Dades diàries no disponibles o no definides.");
                }
            } catch (err) {
                this.handleError(err);
            }

            this.e_any = parseFloat(this.p_anyt);
            this.e_dia = parseFloat(this.p_diat);
            this.e_mes = parseFloat(this.p_mest);
        },
        formatUnit(value) {
            if (value >= 1000) {
                return (value / 1000).toFixed(2) + " MWh";
            } else if (value < 1) {
                return (value * 1000).toFixed(2) + " Wh";
            } else {
                return value.toFixed(2) + " kWh";
            }
        },

        handleError(err) {
            this.carregant = 0;
            alert("\nError:\n\nNo s'han trobat dades per a la data seleccionada.\nSi us plau, torni-ho a intentar o seleccioni una altra data.");
            console.error(err);
        },
    },
};
</script>

<style>


#caracteristiques {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  border: none;
  border-radius: 10px;
  font-size: 15px;
  text-align: center;
  border-collapse: separate;
  table-layout: fixed;

}

#caracteristiques td, #caracteristiques th {
  border: none;
  border-radius: 10px;
}

#caracteristiques tr:nth-child(even){
  background-color: #B6FF9F;
  font-weight: bold;
}


@media (max-width: 285.98px) {
  
}

@media (min-width: 286px) and (max-width: 379.98px) {
 
}
@media (min-width: 380px) {
  
}

</style>