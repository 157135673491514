<template>
  <!--
    Mostra el percentatge de cobertura solar (el valor ve d'un component superior)

    En realitat no és més que un marcador de percentatges, que es pot fer servir amb altres propòsits
  -->
   <table id="caracteristiques">
      <tr>
        <td>{{CSolarInt }} kWh</td>
      </tr>
  
    </table>
      
</template>

<script>
//import ChartDoughnutBase from "../components/ChartDoughnutBase";
export default {
  components: {
    //ChartDoughnutBase,
  },

  props: {
    CSolarInt: {}
  },
  data() {
    return {
      chartData: null, 
      comprobacion:false, 
        
    }
  }
  
};
</script>

<style  scoped>






</style>