///////////////////////////////////////////Programa de muntatge i opcions de les grafiques de Barres apilades /////////////////////////
import { HorizontalBar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: ["chartData"],
  data() {
    return {
      options: {
        
        scales: {
          yAxes: [
            
            { stacked: true,
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: false,
              },
            }
          ],
          xAxes:[{ stacked: true, 
            gridLines: {
              display: false,
            },
            scaleLabel:{display:true,labelString:'%'}
          
          }]
          
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};