<template>
<div id="cuotas">
    <div id="tabla">
      <table class="taulaindicadors" style="max-width:700px;">
        <tr>
          <th><div class="subtitol">Quota Autarquica</div></th>
          <th><div class="subtitol">Quota Autoconsum</div></th>
          <th><div class="subtitol">Cobertura Solar</div></th>
        </tr>
        <tr v-if="this.passedyes">
          <td><CAutarquica :passvalue="this.passvalue"/></td>
          <td><CAutoconsum :passvalue="this.passvalue"/></td>
          <td><CSolar :passvalue="this.passvalue"/></td>
        </tr>
        <tr style="align:center">
          <td>
            <table style="align:center;max-width:170px;">
              <tr><td class="numerador">Energia Autoconsum</td></tr>
              <tr><td class="denominador">Consum</td></tr>
            </table>
          </td>
          <td>
            <table style="align:center;max-width:170px;">
              <tr><td class="numerador">Energia Autoconsum</td></tr>
              <tr><td class="denominador">Energia generada</td></tr>
            </table>
          </td>
          <td>
            <table style="align:center;max-width:170px;">
              <tr><td class="numerador">Energia generada</td></tr>
              <tr><td class="denominador">Consum</td></tr>
            </table>
          </td>
        </tr>
        <tr>
          <td class="aclariment">L’autosuficiència elèctrica o independència de la xarxa.</td>
          <td class="aclariment">El grau d'aprofitament de l'energia generada.</td>
          <td class="aclariment">El dimensionament de la instal·lació respecte el consum. </td>
        </tr>
      </table>
    </div>
    <!-- <br><br> 
        <p>Aqui ponemos el analisis de las graficas anteriores, por ejemplo un par de parrafos explicando y analizando los valores</p>
     <br><br>-->
</div>
</template>

<script>
import axios from 'axios';
import CAutarquica from '../components_report/CAutarquica.vue'
import CSolar from '../components_report/CSolar.vue'
import CAutoconsum from '../components_report/CAutoconsum.vue'

export default {
  props:['comunitatnom',"anyR","mesR","id","tipusR"],
  components: {
    CAutarquica,
    CSolar,
    CAutoconsum,
  },
  data() {
    return {
      chartData: null, 
      comprobacion:false,   
      CuotasApi:"",
           text:"",
      passvalue:[],
     
      passedyes:false
    };
  },
  
  
  
  mounted(){
   
    this.axioscoutas()
    

  }, methods:{

    
    async axioscoutas()
    { 
      await axios.get(this.$domini + 'energiaanual_Comunitat_ID?idComunitat=' + this.comunitatnom[0][0] + "&any=" + this.anyR + "&month=" + this.mesR + "&idInstalacio=" + this.id+ "&MensualQ=" + this.tipusR, { withCredentials: true})
        .then((response) => {
          this.dataApi = response.data;
        });
      this.passvalue=this.dataApi
    
      this.passedyes=true;
    }
  }

 
    }
  
</script>
<style scoped>
/*#cuotas{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;}*/
th{
  size:5;
}
td{
  text-align: center;
  size:10;
  border-color: white;
}

.aclariment{
 /*border-style:hidden;*/
 font-size: 15px;
 font-weight: bold;
 color: darkolivegreen;
 padding:12px;
}
.taulaindicadors{
  text-align: center;
  /*border-collapse: collapse;*/
  border-spacing: 0;
  margin:0 auto;
  width:70%;
  border-spacing: 0;
  padding:5px;
  border: 3px solid rgb(7, 177, 7);
  max-width: 800px;
  border-radius:10px;
}
.numerador{
 border-style:hidden hidden solid hidden;
 border-color: black;
 border-width:1px;
 font-size: 15px;
 font-weight: bold;
 
}

.denominador{
 border-style:solid hidden hidden hidden;
 border-color: black;
 border-width:1px;
 font-size: 15px;
 font-weight: bold; 
}
table{
  text-align:center;
  margin:0 auto;
  width:100%;
}
</style>
