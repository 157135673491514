
<template>
  
  <div class="Pagina principal">
  
    <b-modal ref="my-modal" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>No s'han trobat les dades sol·licitades</h4>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="hideModal">Tanca</b-button>
    </b-modal>

    <b-modal ref="modal-inautoritzat" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>No tens permís per a accedir a aquesta vista</h4>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="amagaInautoritzat">Tanca</b-button>
    </b-modal>
    <br><br>
    <b-row class="log">
      <b class="TitleFormulari">CONSUM USUARI {{nomInstalacio}}</b>
    </b-row>

    
    
   
    <b-container id="organitzacio">
      <b-row class="equal-height-cols">
        <b-col cols="13" md="6" ><Resum v-if="idInstalacio && carregarProps" :idInstalacio="idInstalacio" :idComunitat="codiCE" consum="1" generador="0"/><!--Informacio de l'instalació --></b-col>
     
        <b-col cols="13" md="6"><EsquemaConsum v-if="idInstalacio && carregarProps" :asistema="sistema" :idInstalacio="idInstalacio" :idComunitat="codiCE"/><!--Component per montar les quotes-->
        <h1 v-else-if="carregarProps">No hi ha dades instantànies</h1></b-col>
      </b-row>
      
      <b-row class="equal-height-cols">
        <b-col cols="13" md="6"><potenciaConsum v-if="idInstalacio && carregarProps" :asistema="sistema" :idInstalacio="idInstalacio" :idComunitat="codiCE"/></b-col>
     
        <b-col cols="13" md="6"><EnergiaConsumCE v-if="idInstalacio && carregarProps" :idInstalacio="idInstalacio" :idComunitat="codiCE" :asistema="sistema"/></b-col>
      </b-row>
        
      <b-row class="equal-height-cols">
        <b-col cols="13" md="13"><preu v-if="idInstalacio && carregarProps" :idInstalacio="idInstalacio" /></b-col>
      </b-row>
    
    </b-container>
    <br><br>
 <b-row class="d-flex justify-content-center align-content-center">  <b-col></b-col>
  <b-col>
<select v-model="value" @change="seleccionartipus(value)" class="btn-success5" style=" padding:12px; width: 100%;">
  <option v-for="(any, index) in tipus_type" :key="index" :value="any" st  style="font-weight: bold;color: rgb(66, 65, 71);;">{{ any }}</option>
</select>
</b-col>
  <b-col>
<select v-model="selectedAny" @change="seleccionarAny(selectedAny)" class="btn-success5" style=" padding:12px; width: 100%;">
  <option v-for="(any, index) in anysDisponibles" :key="index" :value="any" st  style="font-weight: bold;color: rgb(66, 65, 71);;">{{ any }}</option>
</select>
</b-col>
<b-col v-if="buttonshow">
<select  v-model="selectedNumero" @change="seleccionarMes(selectedNumero)" class="btn-success5" style="padding: 12px; width: 100%; ">

  <option v-for="numero in 12" :key="numero" :value="numero" style="font-weight: bold; color:rgb(66, 65, 71);">
    {{ numero }}
  </option>
</select>
</b-col>
<b-col>
<button @click="goToReportPage" class="btn-secondary custom-button2" style=" width: 100%;">Print</button>
</b-col><b-col></b-col>
</b-row>   
  </div>
  
</template>

<script>
/////////////////Programes que importa////////////////////////
//import TaulaInstalacions from '../components/TaulaInstalacions.vue'
//import DetallsInstalacio from '../components/DetallsInstalacio.vue'
//import TaulaGraficasGeneracio from '../components/TaulaGraficasGeneracio.vue'
//import FullaDetalls from '../components/FullaDetalls.vue'

import EnergiaConsumCE from '../components/EnergiaConsumCE.vue'
import axios from 'axios';
import EsquemaConsum from '../components/EsquemaConsum.vue'
import potenciaConsum from '../components/potenciaConsum.vue'
import Resum from '../components/Resum.vue'
import preu from '../components/preu.vue'

export default {

  data() {
    return {
      user: 'Prueba',
      isLogged: false,
      NomUsuari:'',
      idInstalacio:'',
      nomInstalacio: '',
      sistema: '',
      DetallsApi: '', 
      DetallsApi2: '',
      codiCE : '',
      carregarProps : false,
      anyR: "2023",
      mesR: "12",
      tipusR:"1",
      anysDisponibles:['2023','2024'],
      tipus_type:['Anual','Mensual'],
      buttonshow:false
    }},
///////////////////////////Declaracio dels components per poder llegir/////////////////////////////////////////
  components: { 
    //TaulaInstalacions,
   // DetallsInstalacio,
   // TaulaGraficasGeneracio,
    
    //FullaDetalls,
    EnergiaConsumCE,
    EsquemaConsum,
    potenciaConsum,
    Resum,
    preu

  },
  
  

  mounted(){////////////////////No funciona el root.on, revisar///////////////////
     this.idInstalacio=this.$route.params.id;
     
     console.log("instalacio "+this.idInstalacio);

  
      this.comprovaPermisos();
     
  },
  
  methods:{ 


    seleccionartipus(value){
      
      if (value===this.tipus_type[0])
        {
          this.tipusR="1";
          this.buttonshow=false;
          console.log("debugbutton,",this.buttonshow)
        }
      if (value===this.tipus_type[1])
      {
        this.tipusR="2";
        this.buttonshow=true;
        console.log("debugbutton,",this.buttonshow)
      }
    

    }
    , 
    seleccionarAny(any) {
    this.anyR=any;
  },
  seleccionarMes(mes) {
    this.mesR=mes;
  },
  async goToReportPage() {
  const url = this.$router.resolve({
    name: 'Informe',
    params: { id: this.idInstalacio },
    query: {
      anyR: this.anyR,
      mesR: this.mesR,
      tipusR: this.tipusR
    }
  }).href;

  // Open the URL in a new tab
  window.open(url, '_blank');
}
,
    hideModal() {
        this.$refs['my-modal'].hide()
      },
      amagaInautoritzat() {
        this.$refs['modal-inautoritzat'].hide()
      }, 
    async comprovaPermisos() {
      await axios.get(this.$domini+'comunitatDeInstalacio?instalacio='+this.idInstalacio, {withCredentials: true})
      .then((response) => {this.DetallsApi = response.data})
      .catch((err) => {
        console.error(err);
        window.location.href = "/";
      });
      this.codiCE= this.DetallsApi.comunitat;
      console.log("codiCEconsum",this.codiCE);

      await axios.get(this.$domini+'sistemaConsum?instalacio='+this.idInstalacio, {withCredentials: true})
      .then((response) => {this.DetallsApi2 = response.data})
      .catch((err) => {
        console.error(err);
        window.location.href = "/";
      });
      this.nomInstalacio= this.DetallsApi2.nomInstalacio;
      
      this.sistema= this.DetallsApi2.sistema;
      console.log("sitemaCON", this.sistema);
      console.log("nom_CON", this.nomInstalacio);
      
      this.carregarProps=true;
          
    },

    edit:function(){
      console.log(this.user)

    }
  }
 ///////////////////////////////////////////////////////////////////////////////// 
}
</script>


<style scoped>

.btn-success5 {
  background: #B6FF9F;
  border: none;
  color: rgb(66, 65, 71);
  border-radius: 10px;
  width:100%;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding:0;
  margin:0;
}


h2{
  color: #0c410598;
  align-content: center;
  font-weight: bold;
  font-size: x-large;
  margin-bottom: 15px;
  text-align: center;
}

.log {
  align-items: center;
  align-content: center;
}

@media (max-width: 475.98px) {

  .TitleFormulari{
  color: #0c4105c7;
  font-size: x-large;
  text-align: center; 
  align-items: center;
  align-content: center;
}
  }



@media (min-width: 476px) and (max-width: 991.98px) {

  .TitleFormulari{
  color: #0c4105c7;
  font-size: x-large;
  text-align: center; 
  align-items: center;
  align-content: center;
}


}


@media (min-width: 992px) and (max-width: 1199.98px) {

  .TitleFormulari{
  color: #0c4105c7;
  font-size: x-large;
  text-align: center; 
  align-items: center;
  align-content: center;
  }

}


@media (min-width: 1200px) {
  

  .TitleFormulari{

  color: #0c4105c7;
  font-size: x-large;
  text-align: center;
  align-items: center;
  align-content: center;
  }
}


</style>