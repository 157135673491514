<template>
  <div id="informe">
    <div id="Logogran" v-if="SmartMeter">
      <img class="Logo2" src="../components/components_report/images/LogoEmelcat.png" width="350" height="70">
    </div>
    <div id="Logopetit" v-else>
      <img class="Logo2" src="../components/components_report/images/LogoEmelcat.png" width="260" height="50">
    </div>

    <div id="titolinforme">
      <table id="tabletitol">
        <tr>
          <th>
            <div id="titol" v-if="SmartMeter">
              <h1>Comunitat: {{ this.comunitatnom[0][1] }} - Informe anual</h1>
            </div>
            <div id="titol2" v-else>
              <h2>Impuls solar Barcelonès - Informe anual</h2>
            </div>
          </th>
        </tr>
      </table>
    </div>
    <hr>
    <div id="DetallsIns" v-if="SmartMeter">
      <br>
      <DetallsInstalacio :DadesInst="this.DadesInst" :anyR="this.any2" />
      <br>
    </div>
    <div id="DetallsIns2" v-else>
      <DetallsInstalacio2 :DadesInst="this.DadesInst" :anyR="this.any" />
    </div>
    <hr>
    <div id="Cuotas" v-if="SmartMeter">
      <br>
      <Cuotas :comunitatnom="this.comunitatnom" :anyR="this.any2" :mesR="this.mes" :id="this.id" :tipusR="this.tipus" />
    </div>
   
    <div id="DivTableEn">
      <table id="TableEn">
        <tr>
          <th colspan="2">
            <div class="subtitol">Energia generada</div>
          </th>
        </tr>
        <tr height="300px">
          <th colspan="2">
            <div class="Generada" v-if="SmartMeter">
              <EnergiaGenerada :Dades="this.DadesInst" :anyR="this.any2" :mesR="this.mes2" :tipusR="this.tipus" />
              <br><br><br><br><br><br><br><br><br><br><br><br>
            </div>
            <div class="Generada2" v-else>
              <EnergiaGenerada2 :Dades="this.DadesInst" />
            </div>
          </th>
        </tr>
        
        
      </table>
    </div>
    <div id="salt-de-pagina" v-if="SmartMeter">
      <p style="page-break-after: always"></p>
    </div>
    <div id="DivTableEn">
      <table id="TableEn">
        
        <tr>
          <th>
            <div class="subtitol" v-if="SmartMeter">Comparativa consums</div>
          </th>
          <th>
            <div class="subtitol" v-if="SmartMeter">Excedents</div>
          </th>
        </tr>
        <tr class="trBorde">
          <th>
            <div id="ComparativaConsum" v-if="SmartMeter">
              <ComparativaConsum :Dades="this.DadesInst" :anyR="this.any2" :mesR="this.mes2" :tipusR="this.tipus" />
            </div>
          </th>
          <th>
            <div id="Excedents" v-if="SmartMeter">
              <Excedents :Dades="this.DadesInst" :anyR="this.any2" :mesR="this.mes2" :tipusR="this.tipus" />
            </div>
          </th>
        </tr>
        <tr>
          <div id="Espais" v-if="SmartMeter">
            <br><br><br><br><br><br><br><br><br><br><br><br><br><br>
          </div>
        </tr>
    
        <tr height="300px">
          <th colspan="2">
            <div id="Generada" v-if="SmartMeter">
              <preu_reports :Dades="this.DadesInst" :anyR="this.any2" :mesR="this.mes2" :tipusR="this.tipus" />
            </div>
          </th>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import DetallsInstalacio from '../components/components_report/DetallsInstalacio.vue'
import DetallsInstalacio2 from '../components/components_report/DetallsInstalacio2.vue'
import EnergiaGenerada from '../components/components_report/EnergiaGenerada.vue'
//import Indicadors from '../components/components_report/Indicadors.vue'
import Cuotas from '../components/components_report/Cuotas.vue'

import ComparativaConsum from '../components/components_report/ComparativaConsum.vue'
import Excedents from '../components/components_report/Excedents.vue'
import EnergiaGenerada2 from '../components/components_report/EnergiaGenerada2.vue'
import preu_reports from '../components/preu_reports.vue';
export default {
  props: ['id','anyR','mesR','tipusR'],
  components:{
    Cuotas,
    DetallsInstalacio,
    //Indicadors,
    EnergiaGenerada,
    EnergiaGenerada2,
    ComparativaConsum,
    Excedents,
    DetallsInstalacio2,
    preu_reports
  },
  data(){
    return{

    Instalacio: '',
    codiInstalacio:"",
    any:"",
    any2:"",
    tipus:"",
    mes:'',
    mes2:'',
    variable:0,
    SmartMeterApi:"",
    SmartMeter:1,
    IndicadorSmartMeter:0,
    DadesInst:[],
    comunitatnom:[],}
  },
   
  mounted()
  { this.codiInstalacio=this.id;
    this.any=this.anyR;
    this.any2=this.anyR;
    this.mes2=this.mesR;
    this.tipus=this.tipusR;
    this.mes=this.mesR;
    console.log("Logi:",this.codiInstalacio, this.any,this.mes);
    this.ComunitatsAxios();
     },
  methods:{
    async ComunitatsAxios() {
      this.data = new Date;
      this.any = this.data.getYear() - 100;
      console.log("ANY_Comun", this.any);

      this.carregant = true;
      await axios.get(this.$domini + 'llistaComunitats_reports?ID=' + this.codiInstalacio, { withCredentials: true})
        .then((response) => {


          console.log("responseABC", response);
          this.DetallsApi = response.data;
        })
        .catch((err) => {
          console.log("responseerr", err);
          this.carregant = 0;
          alert("\nError:\n\nNo s'han trobat dades.\nSi us plau, torni-ho a intentar o seleccioni una altra data.");
          console.error(err);
        });

      if (this.DetallsApi.exit == "unauthorized") {
        this.$refs['modal-inautoritzat'].show();
      }

      this.DadesInst = this.DetallsApi[0];
      this.comunitatnom=this.DetallsApi[1];
      
    },

  }
}
</script>

<style>#informe {
  font-family: 'Calibri', 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.Logo2 {
  display: block;
  margin: 20px auto;
}

#titolinforme {
  text-align: center;
  margin-bottom: 20px;
}

h1, h2 {
  color: #333;
  margin: 0;
}

h1 {
  font-size: 34px;
}

h2 {
  font-size: 32px;
}

.subtitol {
  font-size: 25px;
  color: #555;
  margin-bottom: 15px;
}

#DivTableEn {
  text-align: center;
  margin: 0 auto;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

#TableEn {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

#TableEn th, #TableEn td {
  border: none; /* Remove borders */
  padding: 10px;
  text-align: center;
}

.trBorde {
  border-top: 1px solid #ddd; /* This adds a border only to the top of specific rows */
}

.peudepagina, .peudepagina2 {
  font-size: 18px;
  color: #ffffff;
}

.peuF {
  margin-top: 10px;
}
.generada {
  padding-right: 10px;
}
.peuF2 {
  float: right;
  padding: 0;
}
@media print {
  body {
    margin: 0;
    padding: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
  th, td {
    border: 1px solid #000;
    padding: 8px;
  }
}

</style>