<template>
  <div id="doughnut" v-if="passedYes">
    <div class="container">
      <div class="value-box">
        <h2>{{ CSolarInt }}%</h2>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['passvalue'],
  data() {
    return {
      CSolarInt: null,
      passedYes: false,
    };
  },
  mounted() {
    this.getCSolar();
  },
  methods: {
    async getCSolar() {
      this.CSolarInt = (this.passvalue["CoberturaSolar"] * 100).toFixed(2);
      this.passedYes = true;
    },
  }
};
</script>

<style scoped>
#doughnut {
  width: 300px;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #00000000;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.container {
  text-align: center;
}

.value-box {
  background-color: #79f183;

  border-radius: 10px;
  padding: 20px;
  width: 100%;
  box-shadow: 0 0 5px rgb(6, 6, 6);
}

h2 {
  font-size: 50px;
  margin: 0;
  color: #000000;
}
</style>
