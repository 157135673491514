<template>


    
  <div class="container">
    <b-modal ref="my-modal" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>No s'han trobat les dades sol·licitades</h4>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="hideModal">Tanca</b-button>
    </b-modal>

    <br><br>
  
              
                 
    <b-row>
            
        <b-col v-for="comunitat in comunitats" :key="comunitat[0]">
          <Graphicscommunity :idComunitat="comunitat" :administra="administrador" /><br><br><br></b-col>
        
      </b-row>
              
        
  </div>
</template>

<script>
import axios from 'axios';

import Graphicscommunity from '../components/Graphicscommunity.vue';

export default {
  components: { Graphicscommunity },
    data(){
       
      return {
            
            comunitats: "",
            respostaApi: "",
            administrador: "",
        }
    },
    mounted(){
        this.obteComunitats2();
    },
    methods:{
        hideModal() {
            this.$refs['my-modal'].hide()
        },
        async obteComunitats2(){
            await axios.get (this.$domini+'obteComunitats2', {withCredentials: true})
            .then((response) => {this.respostaApi = response.data})
            .catch((err) => {
                    this.$refs['my-modal'].show();
                    console.error(err);
                });

            console.log("THIS.API", this.respostaApi)
            if (this.respostaApi.missatge=="no logged") {
                window.location.href = "/";
            }

            else if (this.respostaApi.missatge=="redireccionar_comunitat") {
                window.location.href = "/llistaComunitats/" + this.respostaApi.comunitat;
            }
            
            else if (this.respostaApi.missatge=="llista" ) {
                this.comunitats=this.respostaApi.llista
                console.log("selecciooo", this.comunitats)
            }
            this.administrador=this.respostaApi.administrador;
        }
    }
}

</script>

<style scoped>

.llista {
  align-content: center;
  border-spacing: 0;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.llista tr:nth-child(odd) {
  background: #0c4105c7; 
  color: #d9ffd9;
}

.llista tr:nth-child(even) {
  background: #d9ffd9;
  color: #0c4105c7; 
}

.llista td {
  align-content: center;
  padding: 10px;
 
}


.llista a {
  color: inherit; 
 
}

.llista tr:first-child td:first-child {
  border-top-left-radius: 20px; 
}

.llista tr:first-child td:last-child {
  border-top-right-radius: 20px; 
}

.llista tr:last-child td:first-child {
  border-bottom-left-radius: 20px; 
}

.llista tr:last-child td:last-child {
  border-bottom-right-radius: 20px; 
}

.form-label{
  font-size: 1.25em; 
  text-align: center;
  align-content: center;
}

.TitleFormulari{
  color: #0c4105c7;
  font-size: 1.5em;
  text-align: center; 
  text-shadow: 0.125em 0.125em 0.25em rgba(0, 0, 0, 0.3); 
}

</style>
