///////////////////////////////////////////Programa de muntatge i opcions de les grafiques de Linies /////////////////////////
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["chartData"],
  data() {
    return {
      
      options: {
        maintainAspectRatio: false,
        responsive: true,
        
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              scaleLabel:{display:true,labelString:'Potència'}
            }
          ],
          xAxes:[{ stacked: true, 
            scaleLabel:{display:true,labelString:'Temps'}
          
          }]
        },

      }
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};