<template>
  <div id="esquema" class="encuadrat2">
    <!-- Modal -->
    <b-modal ref="my-modal" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>No s'han trobat les dades sol·licitades</h4>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="hideModal">Tanca</b-button>
    </b-modal>

    <b-container id="taulaBarLine7" fluid>
      <div id="ElemSuperior">
        <b-row>
          <b-col>
            <datepicker id="datepickerD" input-class="triadates" :placeholder="dateString" v-model="model.date" @input="formatPicker()" :language="ca" :format="DatePickerFormat"></datepicker>
          </b-col>
         
        </b-row>
      </div>
      <br>
      <b-row>
        <div id="graficalineas2" class="chart-container">
          
          <div class="chart-wrapper">
            <canvas id="myChart" ref="myChart"></canvas>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import datepicker from 'vuejs-datepicker';
import { ca } from 'vuejs-datepicker/dist/locale';
import Chart from 'chart.js';
import axios from 'axios';

export default {
  components: {
    datepicker,
  },
  props: {
    idInstalacio: {},
    idComunitat: {}
  },
  data() {
    return {
      codiCE: this.idComunitat,
      carregant: 0,
      ca: ca,
      formattedDate: '',
      formattedDay: '',
      formattedMonth: '',
      formattedYear: '',
      formattedHour: '',
      infoapi: '',
      dateString: '',
      model: {
        date: '',
      },
      date: {
        dateString: '',
        day: null,
        month: null,
        year: null,
      },
      DatePickerFormat: 'dd/MM/yyyy',
      myChart: null,
    };
  },
  mounted() {
    this.iniciaAxios();
  },
  methods: {
    showDate(date) {
      this.date = date;
    },
    formatPicker() {
      var d = new Date(this.model.date);
      this.formattedDate = `${d.getDate()}/${d.getMonth() + 1 }/${d.getFullYear()}`;
      this.formattedDay = d.getDate();
      this.formattedMonth = d.getMonth() + 1;
      this.formattedYear = d.getFullYear();
      console.log("ANY_estatBat2",this.formattedYear);
      //this.formattedYear =2023;
      return this.BuscarAxios();
    },
    iniciaAxios() {
      var d = new Date();
      this.formattedDate = `${d.getDate()}/${d.getMonth() + 1 }/${d.getFullYear()}`;
      this.formattedDay = d.getDate();
      this.formattedMonth = d.getMonth() + 1 ;
      this.formattedYear = d.getFullYear();
      console.log("ANY_estatBat",this.formattedYear);
      //this.formattedYear = 2023;
      this.dateString = `${this.formattedDay.toString().padStart(2, '0')}/${this.formattedMonth.toString().padStart(2, '0')}/${this.formattedYear.toString()}`;
      this.tipus = 3;
      return this.BuscarAxios();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    async BuscarAxios() {
      this.carregant = 1;
      console.log('BAT_INS', this.idInstalacio);
      console.log('BAT_DIA', this.formattedDay);
      console.log('BAT_MES', this.formattedMonth);
      console.log('BAT_ANY', this.formattedYear);

      await axios
        .get(
          `${this.$domini}estatBateria?id=${this.idInstalacio}&dia=${this.formattedDay}&mes=${this.formattedMonth}&any=${this.formattedYear}`,
          { withCredentials: true},{timeout: 2000000}
        )
        .then((response) => {
          this.infoapi = response.data;
          console.log('potenciaG ', this.infoapi);
        })
        .catch((err) => {
          this.carregant = 0;
          alert(
            '\nError:\n\nNo s\'han trobat dades per a la data seleccionada.\nSi us plau, torni-ho a intentar o seleccioni una altra data.'
          );
          console.error(err);
        });
      this.carregant = 0;

      this.createChart('myChart', 'Potència Rebuda (W)', 'SOC (%)', this.infoapi.temps, this.infoapi.pEntregada, this.infoapi.SOC);
    },
    createChart(id, label1, label2, labels, data1, data2) {
    const ctx = this.$refs[id].getContext('2d');
    const chartContainer = document.getElementById("graficalineas2");
    
    // Ajusta l'amplada i l'altura de la gràfica segons les dimensions del contenidor
    ctx.canvas.width = chartContainer.clientWidth;
    ctx.canvas.height = 400;

    this.myChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: label1,
            yAxisID: 'y-axis-1',
            backgroundColor: ['rgba(222, 144, 60, 0.4)'],
            borderColor: ['#f06000'],
            data: data1,
          },
          {
            label: label2,
            yAxisID: 'y-axis-2',
            backgroundColor: ['rgba(182, 255, 159, 0.4)'],
            borderColor: ['#0c4105c7'],
            data: data2,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false, 
        scales: {
          xAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Temps' 
          }
        }],
          yAxes: [
            {
              id: 'y-axis-1',
              type: 'linear',
              position: 'left',
              scaleLabel: {
              display: true,
              labelString: 'Potència Rebuda (W)' 
            }
            },
            {
              id: 'y-axis-2',
              type: 'linear',
              position: 'right',
              scaleLabel: {
              display: true,
              labelString: 'SOC (%)'
            }
            },
          ],
        },
      },
    });


  },

  },
};
</script>

<style>
#tablalinia {
  height: 590px;
  width: 540px;
  border: 3px solid rgb(201, 201, 201);
  border-radius: 10px;
  border-collapse: separate;
  border-spacing: 0px;
}

.triadates2 {
  border: none !important;
  border-bottom: 1px solid #00c76a !important;
  height: 40px;
  text-align: center;
}

</style>
