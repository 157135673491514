<template>
  <b-container class="form-container">
    <h2 class="form-title"><b class="TitleFormulari">Afegir Instalació</b></h2>
    <form @submit.prevent="submitForm" class="instalacions-form">
      <b-row>
        <b-col>
          <div class="form-group">
            <label for="nomInstalacio">Nom:</label>
            <input type="text" id="nomInstalacio" v-model="form.nomInstalacio" required>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="idSistema">Sistema:</label>
            <select id="idSistema" v-model="form.idSistema" required>
              <option v-for="sistema in sistemesDisponibles" :key="sistema" :value="sistema">{{ sistema }}</option>
            </select>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="potencia">Potència:</label>
            <input type="number" id="potencia" v-model="form.potencia" required>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="form-group">
            <label for="adreca">Adreça:</label>
            <input type="text" id="adreca" v-model="form.adreca" required>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="ombres">Ombres:</label>
            <select id="ombres" v-model="form.ombres" required>
              <option value="0">No</option>
              <option value="1">Si</option>
            </select>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="Coordinates">Coordenades:</label>
            <input type="text" id="Coordinates" v-model="form.Coordinates" required>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="form-group">
            <label for="capacitatBateria">Capacitat bateria:</label>
            <input type="number" id="capacitatBateria" v-model="form.capacitatBateria" required>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="idUsuari">ID Usuari:</label>
            <input type="number" id="idUsuari" v-model="form.idUsuari" required>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="idLloc">ID Lloc:</label>
            <input type="number" id="idLloc" v-model="form.idLloc" required>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="form-group">
            <label for="idInversor">ID Inversor:</label>
            <input type="number" id="idInversor" v-model="form.idInversor" required>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="idPowerSensor">ID Sensor potència:</label>
            <input type="number" id="idPowerSensor" v-model="form.idPowerSensor" required>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="idBateria">ID Bateria:</label>
            <input type="number" id="idBateria" v-model="form.idBateria" required>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="smartMeter">Smart Meter:</label>
            <input type="text" id="smartMeter" v-model="form.smartMeter" required>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="form-group">
            <label for="idestaciometeocat">ID Estació Meteocat:</label>
            <input type="number" id="idestaciometeocat" v-model="form.idestaciometeocat" required>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="idComunitat">ID Comunitat:</label>
            <input type="number" id="idComunitat" v-model="form.idComunitat" required>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="idTarifa">Tarifa:</label>
            <select id="idTarifa" v-model="form.idTarifa" required>
              <option v-for="tarifa in tarifesDisponibles" :key="tarifa" :value="tarifa">{{ tarifa }}</option>
            </select>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="idComercialitzadora">ID Comercialitzadora:</label>
            <select id="idComercialitzadora" v-model="form.idComercialitzadora" required>
              <option v-for="comercialitzadora in comercialitzadoresDisponibles" :key="comercialitzadora" :value="comercialitzadora">{{ comercialitzadora }}</option>
            </select>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="form-group">
            <label for="generacioteorica">Generació Teòrica:</label>
            <input type="text" id="generacioteorica" v-model="form.generacioteorica" required>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="tipus">Tipus:</label>
            <select id="tipus" v-model="form.tipus" required>
              <option value="consumidor">Consumidor</option>
              <option value="generador">Generador</option>
            </select>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="dataPostaEnMarxa">Data posta en marxa:</label>
            <input type="date" id="dataPostaEnMarxa" v-model="form.dataPostaEnMarxa" required>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="dataCreacio">Data creació:</label>
            <input type="date" id="dataCreacio" v-model="form.dataCreacio" required>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <div class="form-group">
          <label for="comentaris">Comentaris:</label>
          <textarea id="comentaris" v-model="form.comentaris" required></textarea>
        </div>
      </b-row>
      <b-row class="justify-content-end">
        <b-col cols="auto">
          <button type="submit" class="btn-succes4 custom-button2">Guardar</button>
        </b-col>
      </b-row>
    </form>

    <number-selector-modal 
      :show="showNumberSelectorModal" 
      :availableNumbers="availableNumbers" 
      @numberSelected="handleNumbersSelected"
      @update:show="closeNumberSelectorModal">
    </number-selector-modal>
  </b-container>
</template>

<script>
import axios from 'axios';
import NumberSelectorModal from '../components/NumberSelectorModal.vue';

export default {
  components: {
    NumberSelectorModal
  },
  props: ['id'],
  data() {
    return {
      form: {
        nomInstalacio: '',
        idSistema: null,
        potencia: null,
        capacitatBateria: null,
        idUsuari: null,
        idLloc: null,
        idInversor: null,
        idPowerSensor: null,
        idBateria: null,
        smartMeter: '',
        comentaris: '',
        adreca: '',
        ombres: null,
        generacioteorica: '',
        idestaciometeocat: null,
        dataPostaEnMarxa: null,
        dataCreacio: null,
        tipus: '',
        idComunitat: this.id,
        idTarifa: null,
        idComercialitzadora: null,
        Coordinates: '',
        selectedNumbers: []
      },
      requiredFields: ['nomInstalacio', 'idSistema', 'potencia', 'dataPostaEnMarxa', 'dataCreacio', 'capacitatBateria', 'idUsuari', 'idLloc', 'idInversor', 'idPowerSensor', 'idBateria', 'smartMeter', 'comentaris', 'adreca', 'ombres', 'generacioteorica', 'idestaciometeocat', 'idComunitat', 'idTarifa', 'idComercialitzadora', 'tipus', 'Coordinates'],
      tarifesDisponibles: [],
      comercialitzadoresDisponibles: [],
      sistemesDisponibles: [],
      showNumberSelectorModal: false,
      availableNumbers: []
    };
  },
  created() {
    this.fetchTarifes();
    this.fetchComercialitzadora();
    this.fetchSistemes();
    this.loadAvailableNumbers();
    console.log("beta_comunitat", this.id);
  },
  methods: {
    async loadAvailableNumbers() {
      try {
        const response = await axios.get(`${this.$domini}generadorsComunitat?idComunitat=${this.id}`, { withCredentials: true });
        this.availableNumbers = response.data.generadors;
      } catch (error) {
        console.error('Error fetching available numbers:', error);
      }
    },
    async fetchTarifes() {
      try {
        const response = await axios.get(`${this.$domini}tarifes_dispo`, { withCredentials: true });
        this.tarifesDisponibles = response.data.tarifes;
      } catch (error) {
        console.error('Error fetching tarifes:', error);
      }
    },
    async fetchSistemes() {
      try {
        const response = await axios.get(`${this.$domini}sistema_dispo`, { withCredentials: true });
        this.sistemesDisponibles = response.data.sistemes;
        console.log("sistemesDis", this.sistemesDisponibles);
      } catch (error) {
        console.error('Error fetching sistemes:', error);
      }
    },
    async fetchComercialitzadora() {
      try {
        const response = await axios.get(`${this.$domini}comercialitzadora_dispo`, { withCredentials: true });
        this.comercialitzadoresDisponibles = response.data.comercialitzadores;
      } catch (error) {
        console.error('Error fetching comercialitzadores:', error);
      }
    },
    async submitForm() {
      for (const field of this.requiredFields) {
        document.getElementById(field).classList.remove('error-border');
      }
      const emptyFields = this.requiredFields.filter(field => !this.form[field]);

      if (emptyFields.length > 0) {
        emptyFields.forEach(field => {
          document.getElementById(field).classList.add('error-border');
        });

        return;
      }

      console.log("Form data before submit:", this.form);

      try {
        await axios.post(`${this.$domini}SubmitForm`, this.form, { withCredentials: true });
        this.resetForm();
      } catch (error) {
        console.error('Error submitting form', error);
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.$emit('update:show', false);  
    },

    resetForm() {
      for (const field of this.requiredFields) {
        document.getElementById(field).classList.remove('error-border');
      }

      for (const key in this.form) {
        if (typeof this.form[key] === 'string') {
          this.form[key] = '';
        } else if (typeof this.form[key] === 'number') {
          this.form[key] = null;
        }
      }
      this.form.idComunitat = this.id;
    },
    handleNumbersSelected(number) {
      console.log("Number selected:", number);
      this.form.selectedNumbers = [number];
      console.log("Updated selectedNumbers in form:", this.form.selectedNumbers);
      this.showNumberSelectorModal = false;
    },
    closeNumberSelectorModal() {
      this.showNumberSelectorModal = false;
    }
  },
  watch: {
    'form.tipus'(newVal) {
      if (newVal === 'consumidor') {
        this.showNumberSelectorModal = true;
      } else {
        this.showNumberSelectorModal = false;
      }
    }
  }
};
</script>
<style scoped>
.form-container {
  max-width: 80%;
  margin: 0 auto;
}

.form-title {
  text-align: center;
  margin: 20px;
  color: #0c4105c7;
}

.instalacions-form {
  background-color: #0c4105c7;
  padding: 20px;
  border-radius: 10px;
}

.form-group {
  margin: 5px;
}

label {
  display: block;
  font-weight: bold;
  color: #fff;
}

input[type="text"],
input[type="number"],
input[type="date"],
textarea,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #fcfcfc;
  border-radius: 5px;
  color: #ffffff;
  background-color: #0c410544;
  min-width: 175px;
}

textarea {
  height: 100px;
}

.error-border {
  border-color: red !important;
}

.btn-succes4 {
  text-align: center;
  background: #27c512;
  color: #ffffff;
  border-radius: 5px;
  font-size: 16px;
  margin: 5px;
  padding: 5px;
  height: 37px;
  width: 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
</style>
