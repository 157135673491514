<template>
    <div id="RDades">
        <table id="TaulaDetalls">
            <tr>
                <td class="fixestaula"  style="width:15%;">Instal·lació:</td>
                <td class="infotaula"><p>{{Instalacio}}</p></td>
                <td class="fixestaula" style="width:15%;left-padding:20px;">Any:</td>
                <td class="infotaula"><p>{{this.any}}</p></td>
            </tr>
            <tr>
                <td class="fixestaula" style="width:15%;">Adreça:</td>
                <td class="infotaula" colspan="3"><p>{{Adreça}}</p></td>
            </tr>
            <tr>
                <td class="fixestaula"  style="width:15%">Potencia:</td>
                <td class="infotaula"><p>{{Potencia}} kW</p></td>
                <td class="fixestaula" v-if="variableCapacitat"  style="width:15%;left-padding:20px">Capacitat:</td>
                <td class="infotaula" v-if="variableCapacitat"><p>{{Capacitat}} kWh</p></td>
            </tr>
            <tr>
                <td class="fixestaula">Posta en marcha:</td>
                <td class="infotaula" ><p>{{DataPosta}}</p></td>
            </tr>
        </table>
    </div> 
</template>

<script>

export default {
    props: ['DadesInst','anyR'],


    data() {
        return {
           Instalacio: "",
           Potencia: "",
           Adreça:"",
           Capacitat:"",
           DataPosta:"",
           any:"",
           DetallsApi:null,
           Apitext:null ,
           CodiInstalacio:"",
        
           variableCapacitat:0,
        };
    },
    mounted(){
        this.any=this.anyR
        this.Instalacio=this.DadesInst[0][1];
        this.Potencia= this.DadesInst[0][3];
        this.Adreça=this.DadesInst[0][12];
        this.Capacitat=this.DadesInst[0][4];     
        this.DataPosta=this.DadesInst[0][16];
    }
}
</script>

<style  scoped>
#TaulaDetalls{
    text-align: center;
    width: 70%;
    margin:0 auto;
    padding: 0px;
    border-spacing: 10px;
    max-width:800px;
    /*max-height:300px ;*/
    /*border: 1px solid rgb(201, 201, 201);*/
    /*border-collapse: collapse;*/
    /*padding: 100%;*/
}
table, th, td { 
    margin:10px;
    text-align:center;
    /*border: 1px solid grey;*/
    /*border-collapse: collapse;*/
    /*border-spacing:5px;*/
}
th,td{
    border-radius: 15px;
    border:0px;
    border-color: white;
}
/*tr{
    /* height: 60px;} */

.fixestaula{
    font-size:20px;
    color:#26a300;
    font-weight:bold;
    background-color: rgb(255, 255, 255);
    height:40px;
    margin:0%;
    padding: 0%;
    text-align: left;
}
.infotaula{
    font-size:30px;
    color:#2C312B;
    font-weight:bold;
    background-color: #DCFFD1;
    height:40px;
    margin:0%;
    padding: 0%;
    border: 3px solid #ffffff;
}
p{
    padding: 0px;
    margin: 0px;
    border: 0px;
    border-color: white;
};
</style>
