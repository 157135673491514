<template>
  <div class = "text-center">
    <table class="taulabetes" :key="updateTaulaBetes">
      <tr>
        <th height="60px"></th>
        <th v-for="k in lenVectorConsums" :key="k">Consum {{ vectorConsums[k-1] }}</th>
        <th>Total</th>
      </tr>
      <tr v-for="(generador, index) in vectorGeneradors" :key="index">
        <th>Generador {{ generador[0] }}</th>
        <td v-for="(value, i) in generador.slice(1)" :key="i">
          <input type="number" step="0.01" v-model="vectorGeneradors[index][i + 1]" @input="computaSumes" class="inputBeta">
        </td>
        <td :class="sumesVertical[index][lenVectorConsums] === '1.00' ? 'text-success' : 'text-danger'">
          {{ sumesVertical[index][lenVectorConsums] }}
        </td>
      </tr>
    </table>
  <br>
  <table class="taulaDatepicker">
    <tr>
      <th>Data vigència canvis: &nbsp;</th> <datepicker id= "datepickerD"  input-class="triadates" :placeholder=dateString v-model="model.date"  @input="formatPicker()"  :language="ca" :format="DatePickerFormat"> </datepicker> 
      <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>  

      <th> 
        <div class="spinner-border text-success" role="status" v-show="spinner" style="width: 1.0rem; height: 1.0rem; border-width: 0.2em;">
          <span class="sr-only"></span>
        </div>
      </th>
      
    </tr>
  </table>
  
  
  <br>
  
  <div class="buttons2">

    <button @click="submitChanges" class="btn-success2 custom-button2" v-if="sumaValida">Introdueix</button> 

    <button @click="goToNewPage" class="btn-secondary custom-button2">Gestió</button>

    

  </div>
  
  <br>
  
  </div>
</template>

<script>
/*
rebre un diccionari de diccionaris del python tipus: {generador : {consum: beta}, {consum: beta}, {consum: beta}}
{'isb001': {'isb001' : 0.24}, {'isb002' : 0.42}, {'isb003' : 0.12},
'isb002': {'isb001' : 0.13}, {'isb002' : 0.22}, {'isb003' : 0.62},
'isb003': {'isb001' : 0.14}, {'isb002' : 0.52}, {'isb003' : 0.32}
}

una altra opció és fer una taula: sembla una opció més flexible
[   
    [isb001, isb002, isb003]
    
    
    
]
*/
import axios from 'axios';
import datepicker from 'vuejs-datepicker';
import {ca} from 'vuejs-datepicker/dist/locale'; 
export default{
  components: {
    datepicker,
  },
  props: ['idComunitat'],
  data() {
    return {
    vectorConsums: "",
    vectorGeneradorsOriginal: [],
    vectorGeneradors: [],
    sumesVertical: [], // Initialize as an empty array
    lenGenerador: 0,
    lenvecGeneradors: 0,
    lenVectorConsums: 0,
    sumesHoritzontal: "",



    variable: "0",
    lenSumesVertical: "",
    lenSumesHoritzontal: "",

    cellText: 'Initial cell text',

    updateTaulaBetes: 0,
    sumaValida: false,
    sumaValida2: false,
    spinner: false,
    
    codiCE: this.idComunitat,

    respostaApi: "",

    dateString: "",
    DatePickerFormat: "dd/MM/yyyy",
    model: {
        date: ''
    },
    ca: ca,
    }
    
  },
  
  mounted() {
    this.printInstallations();
    this.obteBetesActuals();
    //this.vectorGeneradors=this.vectorGeneradorsOriginal;
    var d = new Date();
      console.log(d);
      this.formattedDate=d.getUTCDate() +"/"+ (d.getUTCMonth()+1) +"/"+ d.getUTCFullYear();
      this.formattedDay=d.getUTCDate();
      this.formattedMonth=d.getUTCMonth()+1;
      this.formattedYear=d.getUTCFullYear(); //provisional
      
      console.log("ANY_taulaBeta",this.formattedYear);
      //this.formattedYear= 2023;
    
      this.dateString=this.formattedDay.toString().padStart(2, '0')+"/"+this.formattedMonth.toString().padStart(2, '0')+"/"+this.formattedYear.toString();


  },
  methods: {
    printInstallations() {
      for (let n = 0; n < this.lenvecGeneradors; n++) {
        const generator = this.vectorGeneradors[n];
        const generatorName = generator[0];
        const betaValues = generator.slice(1, this.lenGenerador);

        console.log(`Generador ${generatorName}:`);
        for (let i = 0; i < betaValues.length; i++) {
          const consumer = this.vectorConsums[i];
          const betaValue = betaValues[i];

          console.log(`  Consum ${consumer}: Beta Value = ${betaValue}`);
        }

        
       
        console.log(" Total (Vertical Sum):", this.sumesVertical[n]);
      }
    },
   
   
      async ChangeBetas() {
      try {
    await axios.post(this.$domini + 'ChangeBetas', this.form, { withCredentials: true })
    .then((response) => {
        console.log('Form submitted successfully', response.data);
        this.resetForm();
      });

  } catch (error) {
    console.error('Error submitting form', error);
  }
 },
    async goToNewPage() {
      this.$router.push({ name: 'BetaManagement'  }); 
    },

    submitChanges() {
      this.spinner=true;
      console.log("submit");

      for (let i=0; i<this.vectorGeneradors.length; i++) {
        for (let n=0; n<=this.vectorConsums.length; n++) {
          if (i>0){ //La primera iteració és el nom de la instalacio, per això no es pot passar a float
            this.vectorGeneradors[i][n]=parseFloat(this.vectorGeneradors[i][n]);
          }
          this.vectorGeneradorsOriginal[i][n]=this.vectorGeneradors[i][n];
        }
      }

      console.log(this.vectorGeneradorsOriginal);
      this.computaSumes();

      this.updateTaulaBetes= !this.updateTaulaBetes; 

      this.pujaTaula();

      setTimeout(() => { this.spinner=false; }, 200);
    
      
    },



    cancelChanges() {
      console.log(this.vectorGeneradorsOriginal);
      console.log("cancel·la");
      this.vectorGeneradors=[];
      for (let i=0; i<this.vectorGeneradorsOriginal.length; i++) {
        this.vectorGeneradors.push([]);
        for (let n=0; n<this.vectorGeneradorsOriginal.length+2; n++) {
          
          if (n>0){ //La primera iteració és el nom de la instalacio, per això no es pot passar a float
            this.vectorGeneradorsOriginal[i][n]=parseFloat(this.vectorGeneradorsOriginal[i][n]);
          }
          
            this.vectorGeneradors[i].push(this.vectorGeneradorsOriginal[i][n]);
        }
      }
      

      this.computaSumes();

      this.updateTaulaBetes= !this.updateTaulaBetes;      
    },



    computaSumes() {
    this.sumaValida = true;
    let sumatoriaHoritz = [];

    // Initialize sumesVertical as a 2D array
    this.sumesVertical = Array.from({ length: this.vectorGeneradors.length }, () => []);

    for (let i = 0; i < this.vectorGeneradors.length; i++) {
      let sumatoriaVertical = 0;

      for (let n = 0; n < this.vectorConsums.length; n++) {
        const value = parseFloat(this.vectorGeneradors[i][n + 1]);
        sumatoriaVertical += value;

        if (i === 0) {
          sumatoriaHoritz.push(value);
        } else {
          sumatoriaHoritz[n] += value;
        }

        // Update the 2D array for sumesVertical
        this.sumesVertical[i][n] = value;
      }

      this.sumesVertical[i][this.vectorConsums.length] = parseFloat(sumatoriaVertical).toFixed(2);

      if (this.sumesVertical[i][this.vectorConsums.length] !== "1.00") {
        this.sumaValida = false;
      }
    }

    this.sumesHoritzontal = sumatoriaHoritz.map(value => parseFloat(value).toFixed(2));
  },

    formatPicker: function(){
      var d = new Date(this.model.date);
      this.formattedDate=d.getUTCDate() +"/"+ (d.getUTCMonth()+1) +"/"+ d.getUTCFullYear();
      this.formattedDay=d.getUTCDate();
      this.formattedMonth=d.getUTCMonth()+1;
      this.formattedYear=d.getUTCFullYear();
      
      console.log("ANY_taulaBeta",this.formattedYear);
      //this.formattedYear=2023;
      console.log(this.formattedDay, this.formattedMonth, this.formattedYear);
    },


    async obteBetesActuals() {
      await axios.get (this.$domini+'taulaAdmin?codiCE='+this.codiCE, { withCredentials: true})
        .then((response) => {this.respostaApi = response.data})
      this.vectorConsums = this.respostaApi.vConsums;
  
      this.vectorGeneradors = this.respostaApi.vGeneradors;
      
      this.vectorGeneradorsOriginal=[];
     
      for (let i=0; i<this.vectorGeneradors.length; i++) {
        this.vectorGeneradorsOriginal.push([]);
        //for (let n=0; n<this.vectorGeneradors.length+2; n++) {
        for (let n=0; n<this.vectorGeneradors.length; n++) {
          
          if (n>0){ //La primera iteració és el nom de la instalacio, per això no es pot passar a float
            this.vectorGeneradors[i][n]=parseFloat(this.vectorGeneradors[i][n]);
          }
          
            this.vectorGeneradorsOriginal[i].push(this.vectorGeneradors[i][n]);
        }
      }

      console.log("here3",this.vectorConsums, this.vectorGeneradors, this.vectorGeneradorsOriginal);
      this.lenVectorConsums=this.vectorConsums.length;
      //consum mes 1
      this.lenGenerador=this.vectorGeneradors[0].length;
      //generadors
      this.lenvecGeneradors=this.vectorGeneradors.length;

      this.lenSumesHoritzontal=this.sumesHoritzontal.length;
      this.lenSumesVertical=this.sumesVertical.length;

      console.log(this.lenGenerador);
      this.computaSumes();
      this.updateTaulaBetes= !this.updateTaulaBetes;
    },

    async pujaTaula() {
      let info = {
        "codiCE" : this.codiCE,
        "vConsums" : this.vectorConsums,
        "vGeneradors" : this.vectorGeneradors,
        "dataInici" : this.formattedYear.toString() + "-" + this.formattedMonth.toString().padStart(2, '0') + "-" + this.formattedDay.toString().padStart(2, '0')
      };
      console.log(info);
      await axios.post (this.$domini+'actualitzaTaula', info, {withCredentials: true})
            .then((response) => {this.respostaApi = response.data})
    }

  }
}


</script>

<style>

.custom-button2 {
    height: auto; 
    width: 27%; 
    align-items: center;
  }


  .btn-secondary {
    background:  #8b8383 ;
    color: #ffffff;
    border-radius: 5px;
    font-size: 16px;
    height:35px;
    margin: 5px;
    padding: 5px;
    height:37px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }



.btn-success2 {
    
    background:  #0c4105c7 ;
    color: #ffffff;
    border-radius: 5px;
    font-size: 16px;
    height:35px;
    margin: 5px;
    padding: 5px;
    height:37px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
.taulabetes {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border-spacing: 0;
 

}

.taulabetes th, td {
  border: 1px solid #030806;
  padding: 8px;
 
}
  

.taulabetes tr td:last-child {
  border-bottom-right-radius: 20px;
 
}

.taulabetes th {
  background-color: #0c4105c7;
  font-weight: bold;
  color: rgb(255, 255, 255);
}

.taulabetes tr:nth-child(even) td {
  background-color: #d9ffd9;
  
  
}


.inputBeta{
  border: 2px solid gray;
  border-radius:8px;
  text-align: center;
  background-color: #ffffffff;
}

@media only screen and (min-width: 768.05px) {

  .taulaDatepicker {
    
    font-size: 18px;
    
  }

  .btn-secondary {
    font-size: 18px;
  }

  .btn-success2 {
    font-size: 18px;
  }

  .taulabetes th, .taulabetes td {
    padding: 20px;
    font-size: 18px;
    width: 110px;
   
  }
  .inputBeta {
    width: 110px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 768px) and (min-width: 480.05px) {

  .taulaDatepicker  {
  
    font-size: 16px;
  
  }
  .btn-secondary  {
  
    font-size: 16px;

  }

  .btn-success2 {
    
    font-size: 16px;
    
  }
  .taulabetes th, .taulabetes td {
    padding: 7px; 
    font-size: 16px;
    width: 90px;
  }

  .inputBeta {
    width: 90px; 
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) and (min-width: 280.05px) {

  .taulaDatepicker {
   
    font-size: 12px;
    
  }
  .btn-secondary{
 
    font-size: 12px;
 
  }

  .btn-success2 {
    
    font-size: 12px;
    
  }
  .taulabetes th, .taulabetes td {
    padding: 3px; 
    font-size: 12px;
    width: 40px;
  }

  .inputBeta {
    width: 50px; 
    font-size: 10px;
  }
}

@media only screen and (max-width: 280px) {

.taulaDatepicker{
 
  font-size: 8px;
  
}
.btn-secondary{
 
 font-size: 8px;
 
}
.btn-success2 {
    
    font-size: 8px;
    
}
.taulabetes th, .taulabetes td {
  padding: 3px; 
  font-size: 8px;
  width: 20px;
}

.inputBeta {
  width: 35px; 
  font-size: 8px;
  }
}
</style>