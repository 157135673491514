<template>
  <!--
    Mostra una petita taula amb les alarmes d'una instal·lació, classificades segons el grau de serietat.
    El nombre d'alarmes ve donat d'un component superior
  -->
  <div>
      <b-container id="alarmes">
         
      
          <b-row id="Pendents">
            <b-col>Pendents: {{Pendents}}</b-col>
          </b-row>
         
      
      </b-container>
  </div>
</template>

<script>
export default {
  props: {
    Pendents: {},
   
  

  },
    data () {
        return {
          
        }
    }

}
</script>

<style>

#alarmes{
  font-size: 11px;
  text-align:center;

}

#Pendents{
background-color: #ff8800;
color: #010101c7;
}

#en_progres{
background-color: #ffad61;
color: #010101c7;
}


@media (max-width: 475.98px) {
  #alarmes{
    font-size: 8px;
    text-align:center;
  } 
}

@media (min-width: 476px) and (max-width: 768px) {
  #alarmes{
    font-size: 8px;
    text-align:center;
  }
}

</style>