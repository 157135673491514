<template>
    <!---
    Mostra l'energia que té assignada un consumidor dins una comunitat energètica, anual, mensual i diàriament
    -->
    <div>
        <b-modal ref="my-modal" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
            <div class="d-block text-center">
                <h4>No s'han trobat les dades sol·licitades</h4>
            </div>
            <b-button class="mt-3" variant="outline-success" block @click="hideModal">Tanca</b-button>
        </b-modal>
        <table id="caracteristiques">
            <tr> 
                <td>Assignació anual</td> 
                <td>Assignació mensual</td> 
                <td>Assignació diària</td> 
                  
            </tr>   
           
           <tr>
            <td>{{ this.formatUnit(this.e_any) }}</td>
            <td>{{ this.formatUnit(this.e_mes) }}</td>
            <td>{{ this.formatUnit(this.e_dia) }}</td>
           </tr> 
           
        </table>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        idInstalacio: {},
        consum: {},
        idComunitat: {},
        anySeleccionat: {},
        mesSeleccionat:{}

    },
    data() {
        return {
            e_dia: 0,
            p_diat: "0",

            e_mes: 0,
            p_mest: "0",

            e_any: 0,
            p_anyt: "0",
           
            formattedDate: "",
            formattedDay: "",
            formattedMonth: "",
            formattedYear: "",
            infoapi: "",
            infoapi1: "",
            infoapi2: "",
            infoapi3: "",
            generador: "",
            any: this.anySeleccionat,
            mes: this.mesSeleccionat
        };
    },
    mounted() {
        this.Generacions();
    },
    methods: {
        hideModal() {
            this.$refs['my-modal'].hide();
        },
        async Generacions() {
            var d = new Date();
            this.formattedDate = d.getUTCDate() + "/" + (d.getUTCMonth() + 1) + "/" + d.getUTCFullYear();
            this.formattedDay = d.getUTCDate();
            //this.formattedMonth = d.getUTCMonth() + 1;
           
            console.log("ANY_ASSIG",this.any);
            console.log("mes_ASSIG",this.mes);
            //this.formattedYear = 2023;
            console.log("instalacioconsum", this.idInstalacio);
            console.log("comunitat", this.idComunitat);

        
                    
            this.p_anyt = 0;
            this.p_diat = 0;
            this.p_mest = 0;
            this.EmissionsCO2acc = 0;

                    
            try {
                const response1 = await axios.get(
                    `${this.$domini}energiaCE2?idInstalacio=${this.idInstalacio}&tipus=1&dia=${this.formattedDay}&mes=${this.mes}&any=${this.any}`,
                    { withCredentials: true},{timeout: 2000000}
                );
                this.infoapi1 = response1.data;
                console.log("ASSIGinfoapi1", this.infoapi1)
                if (this.infoapi1 && this.infoapi1.eGenerada) {
                    const eGeneradaArray = Object.values(this.infoapi1.eGenerada);
                    console.log("eGeneradaArray", eGeneradaArray)
                    let suma = eGeneradaArray.reduce((acc, valor) => acc + valor, 0); // Canvi de const a let
                    console.log("suma", suma)
                    this.p_anyt += suma;
                    console.log("p_anyt", this.p_anyt)
                } else {
                    console.error("Dades anuals no disponibles o no definides.");
                }
            } catch (err) {
                this.handleError(err);
            }

            try {
                const response2 = await axios.get(
                    `${this.$domini}energiaCE2?idInstalacio=${this.idInstalacio}&tipus=2&dia=${this.formattedDay}&mes=${this.mes}&any=${this.any}`,
                    { withCredentials: true},{timeout: 2000000}
                );
                this.infoapi2 = response2.data;
                console.log("ASSIGinfoapi1", this.infoapi2)
                if (this.infoapi2 && this.infoapi2.eGenerada) {
                    console.log("this.infoapi2.eGenerada", this.infoapi2.eGenerada)
                    const eGeneradaArray2 = Object.values(this.infoapi2.eGenerada);
                    console.log("eGeneradaArray2", eGeneradaArray2)
                    let suma2 = eGeneradaArray2.reduce((acc, valor) => acc + valor, 0);
                    console.log("suma2", suma2) 
                    this.p_mest += suma2;
                } else {
                    console.error("Dades mensuals no disponibles o no definides.");
                }
            } catch (err) {
                this.handleError(err);
            }


            try {
                const response3 = await axios.get(
                    `${this.$domini}energiaCE2_daily?idInstalacio=${this.idInstalacio}&tipus=3&dia=${this.formattedDay}&mes=${this.mes}&any=${this.any}`,
                    { withCredentials: true},{timeout: 2000000}
                );
                this.infoapi3 = response3.data;
                console.log("ASSIGinfoapi1", this.infoapi3)
                if (this.infoapi3 && this.infoapi3.eGenerada) {
                    const eGeneradaArray3 = Object.values(this.infoapi3.eGenerada);
                    let suma3 = eGeneradaArray3.reduce((acc, valor) => acc + valor, 0); // Canvi de const a let
                    this.p_diat += suma3;
                } else {
                    console.error("Dades diàries no disponibles o no definides.");
                }
            } catch (err) {
                this.handleError(err);
            }

        this.e_any = parseFloat(this.p_anyt).toFixed(2);
        this.e_dia = parseFloat(this.p_diat).toFixed(2);
        this.e_mes = parseFloat(this.p_mest).toFixed(2);
        console.log("ASSIGinfoapi1", this.e_any);
        console.log("ASSIGinfoapi1", this.e_mes);
        console.log("ASSIGinfoapi1", this.e_dia);
        
        },
        formatUnit(value) {
            if (value >= 1000) {
                return (value / 1000).toFixed(2) + " MWh";
            } else if (value < 1) {
                return (value * 1000).toFixed(2) + " Wh";
            } else {
                return (value / 1).toFixed(2) + " KWh";
            }
        },
        handleError(err) {
            this.carregant = 0;
            alert("\nError:\n\nNo s'han trobat dades per a la data seleccionada.\nSi us plau, torni-ho a intentar o seleccioni una altra data.");
            console.error(err);
        },
    },
};
</script>


<style>


.produccio{
  text-align: center;
  /*border-collapse: collapse;*/
  border-spacing: 0;

  border-radius:10px;

}

.valores{
 background-color: #B6FF9F;
 border-radius: 10px;

}

@media (max-width: 285.98px) {
  .valores{
    font-size: 9px;
  }
  .unitats3{
    font-size: 9px;
  }
}

@media (min-width: 286px) and (max-width: 379.98px) {
  .valores{
    font-size: 13px;
  }
  .unitats3{
    font-size: 13px;
  }
}
@media (min-width: 380px) {
    .valores{
    font-size: 15px;
  }
  .unitats3{
    font-size: 15px;
  }
}

</style>