<template>
  <div id="Doughnut" v-if="this.passedyes">  
     <!--<p v-if="this.comprobacion">Valor mayor al 50</p>
     <p v-else>Valor menor a 50</p>-->
      <ChartDoughnutBase :chart-data="this.chartData"/>
      </div>
</template>

<script>
import ChartDoughnutBase from "../components_report/ChartDoughnutBase";
export default {
  props: ['passvalue'],
  components: {
    ChartDoughnutBase,
  },
  data() {
  
    return {
      chartData: [],  
      any:"2023",
      CAutarquicaApi:"",
      CAutaquicatext:"", 
      QuotaAutarquica:"",
      ComplementariQAutarquica:"",
      QuotaAutarquicaNum:"",
      passedyes:false
      
    };
  },
  mounted() {
    
    this.PreparacionGraficafunction();

  },
  methods: {
   
    PreparacionGraficafunction(){
      
      
      this.QuotaAutarquica=this.passvalue["QuotaAutarquica"]

      this.QuotaAutarquicaNum = this.QuotaAutarquica*100
      this.QuotaAutarquicaNum=this.QuotaAutarquicaNum.toFixed(0)
      this.ComplementariQAutarquica=100-this.QuotaAutarquicaNum
     
     console.log ("newlog",this.QuotaAutarquicaNum)
    console.log(this.ComplementariQAutarquica)
    this.chartData = {
        labels: ["Autogenerada", "De xarxa"],
        datasets:[
          {
            backgroundColor: ["#26A300", "#B6FF9F"],
            borderColor: "#2DBF00 ",
            borderWidth: 0.5,
            hoverBackgroundColor: ["#008f39", "#d1e3f7"],
            data: [this.QuotaAutarquicaNum,this.ComplementariQAutarquica]
          }
        ]
      }
      this.passedyes=true;
    }

    
  } 
};
</script>

<style scoped>

#Doughnut{
  width: 300px;
  height: 320px;
  text-align: center;
  display:block;
  margin-left:auto;
  margin-right:auto;
}
</style>