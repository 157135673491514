<template>
  <div id="Emissions">
    <b-modal ref="my-modal" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>No s'han trobat les dades sol·licitades</h4>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="hideModal">Tanca</b-button>
    </b-modal>
    <div id="table">
      <table id="caracteristiques">
        <tr>
          <td>Estalvi CO<sub>2</sub></td>
          <td>Cobertura Solar</td>
        </tr>
        <tr>
          <td>{{EmissionsCO2acc}} tones/any</td>
          <td>{{CSolarInt}} %</td>
        </tr>

      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    idInstalacio: {},
    idComunitat: {},
    anySeleccionat: {},
    mesSeleccionat:{}
  },
  data() {
    return {
      codiInstalacio: this.idInstalacio,
      chartData: null,
      any: this.anySeleccionat,
      mes: this.mesSeleccionat,
      autoConsum: 0,
      EmissionsApi: "",
      emissions: 0,
      EmissionsCO2acc: "0",
      EmissionsCO2: "",
      formattedDate: "",
      e_any: "",
      e_anyt: 0,
      infoapi: "",
      formattedDay: "",
      formattedMonth: "",
      formattedYear: "",
      factorCO2: 0.25,
      comprobacion: false,
      CuotasApi: "",
      text: "",
      CSolartext: "",
      CSolar: "",
      CSolarNum: "",
      CSolarInt: null,
      CAutoconsumtext: "",
      Autoconsum: "",
      AutoconsumNum: "",
      ComplementariAutoconsumNum: "",
      chartDataAutoconsum: null,
      CAutaquicatext: "",
      QuotaAutarquica: "",
      ComplementariQAutarquica: "",
      QuotaAutarquicaNum: "",
      chartDataAutarquica: null,
    };
  },
  mounted() {
    this.EmissionsAxios();
    
    this.CuotasAxios();
  },
  methods: {
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    async EmissionsAxios() {
      console.log("emissions " + this.codiInstalacio);
      var d = new Date();
      this.formattedDate = d.getUTCDate() + "/" + (d.getUTCMonth() + 1) + "/" + d.getUTCFullYear();
      this.formattedDay = d.getUTCDate();
      this.formattedMonth = this.mes;
      this.formattedYear = this.any;
      console.log("ANY_quotes_gen", this.any);
      console.log("mes_quotes_gen", this.mes);

      try {
        const response = await axios.get(`${this.$domini}energiaCE?idInstalacio=${this.idInstalacio}&tipus=1&dia=${this.formattedDay}&mes=${this.mes}&any=${this.any}`, { withCredentials: true, timeout: 20000 });
        this.EmissionsApi = response.data;
        console.log("EMISS 1: ", this.EmissionsApi);
        if (this.EmissionsApi && this.EmissionsApi.eGenerada) {
          console.log("anual", this.EmissionsApi.eGenerada);
          const eGeneradaArray = Object.values(this.EmissionsApi.eGenerada);
          const suma = eGeneradaArray.reduce((acc, valor) => acc + valor, 0);
          console.log("La suma és:", suma);
          this.e_anyt += suma;
        } else {
          console.error("Dades anuals no disponibles o no definides.");
        }
      } catch (err) {
        this.carregant = 0;
        this.$refs['my-modal'].show();
        console.error(err);
      }

      this.e_any = parseFloat(this.e_anyt).toFixed(2);
      console.log("EMISS 2: ", this.e_any);

      this.EmissionsCO2 = this.e_any * this.factorCO2;
      console.log("EMISS 4: ", this.EmissionsCO2);
      this.EmissionsCO2acc = (this.EmissionsCO2 / 1000).toFixed(2); // a tones
      console.log("EMISS 5: ", this.EmissionsCO2acc);
    },
    async CuotasAxios() {
      this.data = new Date;
      this.any = this.data.getYear();
      const dataAvui = new Date();
      this.any = dataAvui.getUTCFullYear();
      console.log("quotesCodiCE " + this.idComunitat);
      console.log("quotesInstalacio " + this.idInstalacio);
      try {
        const response = await axios.get(`${this.$domini}energiaanual_Generador?Instalacio=${this.idInstalacio}&codiCE=${this.idComunitat}&any_=${this.any}`, { withCredentials: true, timeout: 20000 });
        this.CuotasApi = response.data;
        this.CSolar = this.CuotasApi.CoberturaSolar;
        this.CSolarNum = this.CSolar * 100;
        this.CSolarInt = parseFloat(this.CSolarNum).toFixed(2);
      } catch (error) {
        console.error("Error fetching coverage data:", error);
      }
    },
  }
};
</script>

<style scoped>
.valores {
  background-color: #B6FF9F;
  border-radius: 10px;
  font-weight: bolder;
}

.taulaEmissions {
  text-align: center;
  border-collapse: separate;
  border-radius: 10px;
  width: 100%;
  height: 40px;
}

.tones {
  font-size: 17px;
  display: inline;
}

h2 {
  text-align: center;
}

h3 {
  font-size: 25px;
}



</style>
