<template>
  <b-container class="login" v-if="!autenticat">
    <b-row class="d-flex justify-content-center">
      <b class="TitleFormulari">INICI SESSIÓ</b>
    </b-row>

    <form @submit.prevent="loginEVC" class="form">
      <b-row>
        <label class="form-label" for="email">Usuari:</label>
        <input v-model="nomUsuari" class="form-input" id="email" required placeholder="Usuari">
      </b-row>
      <b-row>
        <label class="form-label" for="password">Contrasenya:</label>
        <input v-model="password" class="form-input" type="password" id="password" placeholder="Password">
      </b-row>
      <p v-if="!exit && !autenticat" class="error">Has introduït malament l'usuari o la contrasenya</p>
      <input class="form-submit" type="submit" value="Entra">
    </form>
  </b-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      nomUsuari: "",
      password: "",
      exit: true,
      respostaApi: "",
      autenticat: false,
      respostaApi2: "",
    };
  },
  mounted() {
    this.comprovaAutenticacio();
  },
  methods: {
    async comprovaAutenticacio() {
      try {
        const response = await axios.get(this.$domini + "comprovaSessio", { withCredentials: true });
        this.respostaApi = response.data;
        this.autenticat = this.respostaApi.autenticacio;
        
        if (this.autenticat) {
          const response2 = await axios.get(this.$domini + "obteComunitats", { withCredentials: true });
          this.respostaApi2 = response2.data;
          
          console.log(this.respostaApi2);

          if (this.respostaApi2.missatge == "redireccionar_comunitat") {
            window.location.href = "/llistaComunitats/" + this.respostaApi2.comunitat;
          } else if (this.respostaApi2.missatge == "redireccionar_consum") {
            window.location.href = "/vistaConsum/" + this.respostaApi2.consum;
          } else if (this.respostaApi2.missatge == "llista") {
            window.location.href = "/seleccio";
          }

          this.administrador = this.respostaApi2.administrador;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async loginEVC() {
      try {
        this.exit = true;
        const user = { "username": this.nomUsuari, "password": this.password };
        const response = await axios.post(this.$domini + 'loginEVC', user, { withCredentials: true });
        this.rApi = response.data;
        console.log(this.rApi);

        if (this.rApi.exit) {
          const response2 = await axios.get(this.$domini + "obteComunitats", { withCredentials: true });
          this.respostaApi2 = response2.data;
          console.log(this.respostaApi2);

          if (this.respostaApi2.missatge == "redireccionar_comunitat") {
            window.location.href = "/llistaComunitats/" + this.respostaApi2.comunitat;
          } else if (this.respostaApi2.missatge == "redireccionar_consum") {
            window.location.href = "/vistaConsum/" + this.respostaApi2.consum;
          } else if (this.respostaApi2.missatge == "llista") {
            window.location.href = "/seleccio";
          }
        } else {
          this.exit = false;
        }
      }catch (error) {
        console.error(error);
        this.exit = false;
      }
    },
  },
};
</script>

<style scoped>
.error{
  color: #27c512;
  margin: auto;
  text-align: left;
}

.login{
  align-items: center;
  align-content: center;
}

.form {
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background:  #0c4105c7 ;
  border-radius: 5px;
  padding: 40px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
  font-size:large;
  color: #d9ffd9;
}

.TitleFormulari{
  color: #0c4105c7;
  font-size:xx-large;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.form-label{
  margin-top: 1rem;
  color:  #d9ffd9;
  margin-bottom: 0.5rem;
  border-radius: 5px;
}

.form-input {
  padding: 10px 15px;
  background: none;
  background-image: none;
  border: 1px solid rgb(255, 255, 255);
  color: #d9ffd9;
  border-radius: 5px;
}

.form-submit {
  background:rgb(6, 238, 26);
  background:rgb(6, 238, 26);
  border: none;
  color: white;
  margin-top: 3rem;
  padding: 1rem 0;
  cursor: pointer;
  border-radius: 5px;
}


@media (max-width: 475.98px) {
  .form {
    width: 70%;
    max-width: 100%;
  }

  .form-label{
    font-size: 18px;
  }

  .TitleFormulari{
    font-size:x-large;
  }
}

@media (min-width: 476px) and (max-width: 991.98px) {
  .form {
    width: 90%;
    max-width: 100%;
  }

  .TitleFormulari{
    font-size:x-large;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .form {
    width: 90%;
    max-width: 100%;
  }
  .TitleFormulari{
    font-size:x-large;
  }
}

@media (min-width: 1200px) {
  .form {
    width: 90%;
    max-width: 100%;
  }
  .TitleFormulari{
    font-size:x-large;
  }
}

</style>