<template>
  
  <div class="power-flow">
    <svg width="400" height="400" viewBox="0 0 275 275">
      <!-- Solar Icon -->
      <g class="hover-group">
        <text x="100" y="70" text-anchor="middle" fill="green" font-size="13">{{ formatUnit2(solarPower) }}</text>
        <image
          x="75"
          y="20"
          width="50"
          height="50"
          href="@/assets/solar.png"
          transform="translate(0, -13)"
          id="solar-icon"
          @mouseenter="showPopover"
          @mouseleave="hidePopover"
        />
      </g>

      <!-- House Icon -->
      <text x="30" y="230" text-anchor="middle" fill="green" font-size="13">{{ formatUnit2(consumptionPower) }}</text>
      <image
        x="5"
        y="155"
        width="50"
        height="50"
        href="@/assets/house.png"
        transform="translate(0, 13)"
      />

      <!-- Grid Icon -->
      <text x="170" y="230" text-anchor="middle" fill="green" font-size="13">{{ formatUnit2(Math.abs(gridPower / 1000)) }}</text>
      <image
        x="145"
        y="155"
        width="50"
        height="50"
        href="@/assets/grid.png"
        transform="translate(0, 13)"
      />

      <!-- Leaf Icon at the Center -->
      <image
        v-if="consumptionPower <= solarPower"
        x="100"
        y="120"
        width="70"
        height="70"
        href="@/assets/leaf.png"
        transform="translate(-35, -35)"
      />

      <!-- Plug Icon at the Center (Else Condition) -->
      <image
        v-else
        x="100"
        y="120"
        width="70"
        height="70"
        href="@/assets/plug.png"
        transform="translate(-35, -35)"
      />

      <!-- Arrows with Animated Arrowhead -->
      <path
        :d="arcPathSolarToGrid"
        v-if="gridPower > 0"
        fill="none"
        stroke="green"
        stroke-width="1.5"
        marker-end="url(#arrowhead)"
      >
        <animate
          attributeName="stroke-dasharray"
          from="0, 300"
          to="300, 0"
          dur="1.8s"
          repeatCount="indefinite"
        />
      </path>
      <path
        :d="arcPathSolarToConsumption"
        v-if="solarPower > 0 && consumptionPower > 0"
        fill="none"
        stroke="green"
        stroke-width="1.5"
        marker-end="url(#arrowhead)"
      >
        <animate
          attributeName="stroke-dasharray"
          from="0, 300"
          to="300, 0"
          dur="1.8s"
          repeatCount="indefinite"
        />
      </path>
      <path
        :d="arcPathGridToConsumption"
        v-if="gridPower < 0"
        fill="none"
        stroke="green"
        stroke-width="1.5"
        marker-end="url(#arrowhead)"
      >
        <animate
          attributeName="stroke-dasharray"
          from="0, 300"
          to="300, 0"
          dur="1.8s"
          repeatCount="indefinite"
        />
      </path>
    </svg>

    <b-popover
      ref="popover"
      target="solar-icon"
      placement="right"
      triggers="manual"
      custom-class="fancy-popover"
    >
      <template #title>Generadors Associats</template>
      <table class="table table-striped table-sm">
        <thead>
          <tr>
            <th>Generador</th>
            <th>Coeficient (%)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="value in generadorsApi" :key="value.id">
            <td>
              <a :href="'/vistaGenerador/' + value.id" target="_blank">{{ value.nom }}</a>
            </td>
            <td>{{ (value.beta * 100).toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
    </b-popover>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    solarPower: {
      type: Number,
      required: true,
    },
    consumptionPower: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      generadorsApi: [],
      isHovered: false,
    };
  },
  mounted() {
    this.ini_generadors();
  },
  methods: {
    async ini_generadors() {
      await axios.get(this.$domini + `generadorsConsum?Consum=${this.idInstalacio}&codiCE=${String(this.codiCE)}`, { withCredentials: true, timeout: 2000000 })
        .then((response) => {
          this.generadorsApi = response.data;
          console.log("Generadors", this.generadorsApi);
        });
    },
    formatUnit2(value) {
      if (value >= 1000) {
        return (value / 1000).toFixed(2) + " MW";
      } else if (value < 1) {
        return (value * 1000).toFixed(2) + " W";
      } else {
        return value.toFixed(2) + " kW";
      }
    },
    showPopover() {
      this.$refs.popover.show();
    },
    hidePopover() {
      this.$refs.popover.hide();
    },
  },
  computed: {
    gridPower() {
      return this.solarPower * 1000 - this.consumptionPower * 1000;
    },
    arcPathSolarToGrid() {
      return `M 120 40 A 90 90 0 0 1 170 170`;
    },
    arcPathSolarToConsumption() {
      return `M 80 40 A 90 90 0 0 0 30 170`;
    },
    arcPathGridToConsumption() {
      return `M 150 170 A 90 90 0 0 0 30 170`;
    },
  },
};
</script>

<style scoped>
.power-flow {
  display: flex;
  justify-content: center;
  align-items: center;
padding-left: 100px;
}

svg {
  width: 300px;
  height: 270px;
}
</style>