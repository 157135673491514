<template>
  <div id="esquema" class="encuadrat2">
    <!-- Modal -->
    <b-modal ref="my-modal" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>No s'han trobat les dades sol·licitades</h4>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="hideModal">Tanca</b-button>
    </b-modal>

    <b-container id="taulaBarLine7" fluid>
      <div id="ElemSuperior">
        <b-row>
          <b-col class="d-flex justify-content-center align-items-center">
            <b-button variant="link" @click="decrementDate">
              <b-icon icon="chevron-left"></b-icon>
            </b-button>
            <datepicker id="datepickerD" input-class="triadates" :placeholder="dateString" v-model="model.date" @input="formatPicker" :language="ca" :format="DatePickerFormat"></datepicker>
            <b-button variant="link" @click="incrementDate">
              <b-icon icon="chevron-right"></b-icon>
            </b-button>
          </b-col>
          <b-col colespan="2" class="d-flex align-items-center justify-content-center custom-text-column">
            <div v-if="chartData">
              <p class="custom-text-style">{{ text }}</p>
            </div>
          </b-col>
          <b-col></b-col>
        </b-row>
      </div>

      <br>
      <b-row>
        <div id="graficalineas2" class="chart-container">
          <div class="chart-wrapper">
            <ChartLineBase :chartData="chartData" />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import datepicker from 'vuejs-datepicker';
import { ca } from 'vuejs-datepicker/dist/locale'; // Importació del idioma català per al datepicker
import ChartLineBase from "../components/ChartLineBase";
import axios from "axios";

export default {
  components: {
    datepicker,
    ChartLineBase,
  },
  props: {
    idInstalacio: {},
    asistema: {},
  },
  data() {
    return {
      unitat:'',
      carregant: 0,
      ca: ca,
      formattedDate: '',
      formattedDay: '',
      formattedMonth: '',
      formattedYear: '',
      formattedHour: '',
      infoapi: '',
      model: {
        date: new Date()
      },
      date: {
        dateString: '',
        day: null,
        month: null,
        year: null,
      },
      DatePickerFormat: 'dd/MM/yyyy',
      chartData: '',
      text:""
    };
  },
  mounted() {
    this.iniciaAxios();
  },
  methods: {
    showDate(date) {
      this.date = date;
      
    },  decrementDate() {
      
      let newDate = new Date(this.model.date);
      newDate.setDate(newDate.getDate() - 1);
      this.model.date = new Date(newDate); // Ensure new Date object
      this.formatPicker();
    },
    incrementDate() {
      let newDate = new Date(this.model.date);
      newDate.setDate(newDate.getDate() + 1);
      this.model.date = new Date(newDate); // Ensure new Date object
      this.formatPicker();
    },
    formatPicker: function () {
      var d = new Date(this.model.date);
      this.formattedDate = `${d.getDate()}/${d.getMonth()+1 }/${d.getFullYear()}`;
      this.formattedDay = d.getDate();
      this.formattedMonth = d.getMonth()+1;
      this.formattedYear = d.getFullYear();
      
      console.log("ANY_potCon",this.formattedYear);
      //this.formattedYear = 2023;
      return this.BuscarAxios();
    },
    iniciaAxios() {
      var d = new Date();
      this.formattedDate = `${d.getDate()}/${d.getMonth()+1 }/${d.getFullYear()}`;
      this.formattedDay = d.getDate();
      this.formattedMonth = d.getMonth()+1 ;
      
      //this.formattedYear = 2023;
      this.formattedYear = d.getUTCFullYear();
      console.log("ANY_potGen",this.formattedYear);
      this.dateString = `${this.formattedDay.toString().padStart(2, '0')}/${this.formattedMonth.toString().padStart(2, '0')}/${this.formattedYear.toString()}`;
      this.tipus = 3;
      if (this.asistema === 3) {
      this.unitat = "W";
      } else if (this.asistema === 5) {
          this.unitat = "kW";
      }else if (this.asistema === 7) {
        this.unitat = "kW";
      }
      return this.BuscarAxios();
    },
    
   
    async BuscarAxios() {
      this.carregant = 1;
      await axios
        .get(
          `${this.$domini}potenciaGeneradorCE?id=${this.idInstalacio}&dia=${this.formattedDay}&mes=${this.formattedMonth}&any=${this.formattedYear}`,
         { withCredentials: true},{timeout: 2000000}
        )
        .then((response) => {
          this.infoapi = response.data;
          console.log('potenciaG ', this.infoapi);
          this.text=""
          this.chartData = {
        labels: this.infoapi.temps,
        datasets: [
          {
            label: 'Potència Generada ('+ this.unitat + ')',
            backgroundColor: ['rgba(182, 255, 159, 0.4)'],
            borderColor: ['#0c4105c7'],
            data: this.infoapi.pGenerada,
          },
          {
            label: 'Potència Consumida ('+ this.unitat + ')',
            backgroundColor: ['rgba(222, 144, 60, 0.4)'],
            borderColor: ['#f06000'],
            data: this.infoapi.pConsumida,
          },
        ],
      };
        })
        .catch((err) => {
          this.carregant = 0;
          /*alert(
            "\nError:\n\nNo s'han trobat dades per a la data seleccionada.\nSi us plau, torni-ho a intentar o seleccioni una altra data."
          );*/
          this.text="No s'han trobat dades"
          console.log(err);
          this.chartData = {
        labels: this.infoapi.temps,
        datasets: [
          {
            label: 'Potència Generada ('+ this.unitat + ')',
            backgroundColor: ['rgba(182, 255, 159, 0.4)'],
            borderColor: ['#0c4105c7'],
            data: null,
          },
          {
            label: 'Potència Consumida ('+ this.unitat + ')',
            backgroundColor: ['rgba(222, 144, 60, 0.4)'],
            borderColor: ['#f06000'],
            data: null,
          },
        ],
      };
        });
      this.carregant = 0;

      
    },
  },
};
</script>

<style>

.triadates {
  border: none !important;
  border-bottom: 1px solid #0c4105c7 !important;
  height: 40px;
  text-align: center;
  margin: 6px;
}

.chart-container {
  width: 100%;
}

.encuadrat2 {
  text-align: center;
  width: 100%;
  height: auto;
  padding: 0px;
  margin: 0px;
  border: 3px solid rgb(201, 201, 201);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.custom-text-style {
  color: rgb(31, 31, 32);
  font-size: 16px;
  /* Treure els estils de display i alineació d'aquí */
}

.custom-text-column {
  display: flex;
  align-items: bottom;
  justify-content: center;
}

@media (max-width: 379.98px) {
  .custom-text-style {
    font-size: 12px;
    height: 8px;
  }
}

@media (min-width: 380px) and (max-width: 575.98px) {
  .custom-text-style {
    font-size: 10px;
    height: 10px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .custom-text-style {
    font-size: 10px;
    height: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .custom-text-style {
    font-size: 12px;
    height: 12px;
  }
}

@media (min-width: 1200px) {
  .custom-text-style {
    font-size: 14px;
    height: 14px;
  }
}

@media (max-width: 337.98px) {
  .custom-text-style {
    font-size: 12px;
    height: 8px;
  }
}
</style>