<template>
  <div id="esquema" class="encuadrat2">
    <!-- Modal -->
    <b-modal ref="my-modal" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>No s'han trobat les dades sol·licitades</h4>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="hideModal">Tanca</b-button>
    </b-modal>

    <b-container id="taulaBarLine7" fluid>
      <div id="ElemSuperior">
        <b-row>
          <b-col class="d-flex jusitify-items-center align-items-center">
            <b-button variant="link" @click="decrementDate">
              <b-icon icon="chevron-left"></b-icon>
            </b-button>
            <datepicker id="datepickerD" input-class="triadates" :placeholder="dateString" v-model="model.date" @input="formatPicker" :language="ca" :format="DatePickerFormat"></datepicker>
            <b-button variant="link" @click="incrementDate">
              <b-icon icon="chevron-right"></b-icon>
            </b-button> 
          </b-col>
   
        </b-row>
       
                                
      </div>
      <br>
      <b-row>
        <div id="graficalineas2" class="chart-container">
          
          <div class="chart-wrapper">
            <ChartLineBase :chartData="chartData" />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import datepicker from 'vuejs-datepicker';
import { ca } from 'vuejs-datepicker/dist/locale';
import ChartLineBase from "../components/ChartLineBase";
import axios from "axios";

export default {
  components: {
    datepicker,
    ChartLineBase,
  },
  props: {
    idInstalacio: {},
    idComunitat: {},
    asistema: {}
  },
  data() {
    return {
      
      codiCE: this.idComunitat,
      carregant: 0,
      ca: ca,
      formattedDate: '',
      unitat:'',
      formattedDay: '',
      formattedMonth: '',
      formattedYear: '',
      formattedHour: '',
      infoapi: "",
      infoapi2: "",
      infoapi3: "",
      model: {
        date: new Date()
      },
      date: {
        dateString: '',
        day: null,
        month: null,
        year: null
      },
      DatePickerFormat: "dd/MM/yyyy",
      chartData:'',
      text:"",
    };
  },


  mounted() {
    this.iniciaAxios();
  },

  methods: {
    showDate(date) {
      this.date = date;
    },

    formatPicker: function () {
      var d = new Date(this.model.date);
      this.formattedDate = `${d.getDate()}/${d.getMonth()+1 }/${d.getFullYear()}`;
      this.formattedDay = d.getUTCDate();
      this.formattedMonth = d.getUTCMonth()+1;
      this.formattedYear = d.getUTCFullYear();
      console.log("ANY_potCon",this.formattedYear);
      //this.formattedYear = 2023;
      return this.BuscarAxios();
    },decrementDate() {
      
      let newDate = new Date(this.model.date);
      newDate.setDate(newDate.getDate() - 1);
      this.model.date = new Date(newDate); // Ensure new Date object
      this.formatPicker();
    },
    incrementDate() {
      let newDate = new Date(this.model.date);
      newDate.setDate(newDate.getDate() + 1);
      this.model.date = new Date(newDate); // Ensure new Date object
      this.formatPicker();
    },

    iniciaAxios() {
      var d = new Date();
      this.formattedDay = d.getDate();
      this.formattedDate = `${d.getDate()}/${d.getMonth()+1 }/${d.getFullYear()}`;
      this.formattedMonth = d.getMonth()+1 ;
      this.formattedYear = d.getFullYear();
      console.log("ANY_potCon",this.formattedYear);
      //this.formattedYear = 2023;
      this.dateString = `${this.formattedDay.toString().padStart(2, '0')}/${this.formattedMonth.toString().padStart(2, '0')}/${this.formattedYear.toString()}`;
      this.tipus = 3;
      if (this.asistema === 3 || this.asistema === 4) {
        this.unitat = "W";
      } else if (this.asistema === 5) {
        this.unitat = "kW";
      }else if (this.asistema === 7) {
        this.unitat = "kW";
      }
      return this.BuscarAxios();
    },

    async BuscarAxios() {

      this.carregant = 1;
      console.log("potDia", this.formattedDay)
      console.log("potMes",this.formattedMonth)
      console.log("potAny", this.formattedYear)
      try {
        const response = await axios.get(this.$domini + 'potenciaConsumCE?id=' + this.idInstalacio + '&dia=' + this.formattedDay + '&mes=' + this.formattedMonth + '&any=' + this.formattedYear, { withCredentials: true},{timeout: 2000000});
        this.infoapi = response.data;
        console.log("potConsum1", this.infoapi)
      } catch (err) {
        this.handleError(err);
        
      }


      
      try {
        const response1 = await axios.get(`${this.$domini}energiaCE2?idInstalacio=${this.idInstalacio}&tipus=${this.tipus}&dia=${this.formattedDay}&mes=${this.formattedMonth}&any=${this.formattedYear}`, { withCredentials: true},{timeout: 2000000});
        this.infoapi3 = response1.data;
        console.log("potConsum6", this.infoapi3.eGenerada);
     
  
      } catch (err) {
        this.handleError(err);
        
      }


      this.carregant = 0;

      this.chartData = {
        labels: this.infoapi.temps,
        datasets: [
          {
            label: "Potència Consumida (" + this.unitat + ")",
            backgroundColor: ["rgba(222, 144, 60, 0.4)"],
            borderColor: ["#f06000"],
            data: this.infoapi.pConsumida
          },
          {
            label: "Potència Assignada (" + this.unitat + ")",
            backgroundColor: ['rgba(182, 255, 159, 0.4)'],
            borderColor: ["#0c4105c7"],
            data: this.infoapi3.eGenerada
          },
        ]
      };
    },

    handleError(err) {
      this.carregant = 0;
      
      /*alert("\nError:\n\nNo s'han trobat dades per a la data seleccionada.\nSi us plau, torni-ho a intentar o seleccioni una altra data.");
      */
     console.log(err);
     
     this.chartData = {
        labels: this.infoapi.temps,
        datasets: [
          {
            label: "Potència Consumida (" + this.unitat + ")",
            backgroundColor: ["rgba(222, 144, 60, 0.4)"],
            borderColor: ["#f06000"],
            data: null
          },
          {
            label: "Potència Assignada (" + this.unitat + ")",
            backgroundColor: ['rgba(182, 255, 159, 0.4)'],
            borderColor: ["#0c4105c7"],
            data: null
          },
        ]
      };
    },
  }
};

</script>

<style>
  .encuadrat2 {
    text-align: center;
    width: 100%;
    height: auto;
    padding: 0px;
    margin: 0px;
    border: 3px solid rgb(201, 201, 201);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 576px) {
  .encuadrat2 {
    width: 100%;
  }

}
</style>