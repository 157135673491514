import { render, staticRenderFns } from "./CAutarquica.vue?vue&type=template&id=063dd898&scoped=true&"
import script from "./CAutarquica.vue?vue&type=script&lang=js&"
export * from "./CAutarquica.vue?vue&type=script&lang=js&"
import style0 from "./CAutarquica.vue?vue&type=style&index=0&id=063dd898&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "063dd898",
  null
  
)

export default component.exports