<template>
  <!--
    Mostra la quota autarquica en forma de doughnut
  -->
    <div id="Stack">
       <!--<p v-if="this.comprobacion">Valor mayor al 50</p>
       <p v-else>Valor menor a 50</p>-->
        <ChartDoughnutBase :chart-data="chartDataAutarquica"  />
        </div>
  </template>
  
  <script>
  import ChartDoughnutBase from "../components/ChartDoughnutBase";
  export default {
    components: {
        ChartDoughnutBase,
    },
    props: {
      chartDataAutarquica: {}
    },
    data() {
      return {
        chartDataAutarquicas: {
              labels: ["Autoconsum", "Consumida xarxa"],
              datasets:[
            {
              backgroundColor: ["#26A300", "#111111"],
           
              hoverBackgroundColor: ["#008f39", "#111111"],
              data: [60, 40],
            },
            
        ]
      },  
        codiInstalacio:"",
        any:"",
        CAutarquicaApi:"",
        CAutaquicatext:"", 
        QuotaAutarquica:"",
        ComplementariQAutarquica:"",
        QuotaAutarquicaNum:"",
        
      };
    },
    
  };
  </script>
  
  <style scoped>
  #Stack {
  width: 200px; 
  height: 200px; 
  text-align:center;

}
  
  </style>