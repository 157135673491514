<template>

        <table id="caracteristiques">
            <tr>
                <td>Cobertura Solar</td> 
                <td>Sobirania Energètica</td> 
                <td>Autoconsum</td> 
         
            </tr>
            <tr>
                <td>{{CSolarInt}} %</td>
                <td>{{QuotaAutarquicaNum}} %</td>
                <td>{{AutoconsumNum}} %</td>
            </tr> 
        
        </table>
   
</template>


<script>
import axios from 'axios';
//import CAutarquica from './CAutarquica.vue'

//import CAutoconsum from './CAutoconsum.vue'

export default {
  /*
  components: {
    CAutarquica,

    CAutoconsum,
  },*/
  props:{
        idInstalacio: {},
        idComunitat: {},
        anySeleccionat: {},
        mesSeleccionat:{}
    },
  data() {
    return {
      codiCE: this.idComunitat,
      comprobacion:false, 
      codiInstalacio: this.idInstalacio,
      any:this.anySeleccionat,  
      CuotasApi:"",
      text:"",

      CSolartext:"",
      CSolar:"",
      CSolarNum:"",
      CSolarInt:null,

      CAutoconsumtext:"",
      Autoconsum:"", 
      AutoconsumNum:"",
      ComplementariAutoconsumNum:"",
      chartDataAutoconsum: null,

      CAutaquicatext:"", 
      QuotaAutarquica:"",
      ComplementariQAutarquica:"",
      QuotaAutarquicaNum:"",
    
      chartDataAutarquica: null,
    };
  },
  mounted() {
    this.CuotasAxios();
  },
  methods: {

     async CuotasAxios(){
          this.data= new Date;
          
          console.log("quotes "+this.codiInstalacio);
          
          console.log("ANY_quoCons",this.any);
     
           await axios.get (this.$domini+'energiaanual_Consum?Instalacio='+this.codiInstalacio+'&codiCE='+String(this.codiCE)+'&any='+this.any, { withCredentials: true},{timeout: 2000000})//  /DadesInstalacio?instalacio=89
            .then((response) => {this.CuotasApi = response.data});
            //per CSolar
            this.CSolar=this.CuotasApi.CoberturaSolar
            this.CSolarNum=this.CSolar*100 
            this.CSolarInt=parseFloat(this.CSolarNum)
            this.CSolarInt=this.CSolarInt.toFixed(0)

            //per CAutoconsum
            this.Autoconsum=this.CuotasApi.percAutoconsum

            this.Autoconsum = this.CuotasApi.percAutoconsum;
            this.AutoconsumNum = this.Autoconsum * 100;
            this.AutoconsumNum = this.AutoconsumNum.toFixed(0);
            this.ComplementariAutoconsumNum = 100 - this.AutoconsumNum;

            this.chartDataAutoconsum = {
              labels: [""],
              datasets: [
                {
                  label: "Autoconsum: " + this.AutoconsumNum.toString() + "%",
                  backgroundColor: "#0c4105c7",
                  hoverBackgroundColor: "#008f39",
                  data: [this.AutoconsumNum],
                },
                {
                  label: "Venuda a xarxa: " + this.ComplementariAutoconsumNum.toString() + "%",
                  backgroundColor: "#B6FF9F",
                  hoverBackgroundColor: "#d1e3f7",
                  data: [this.ComplementariAutoconsumNum],
                },
              ],
              options: {
                legend: {
                  display: true,
                  position: 'bottom',
                  labels: {
                    fontSize: 2 // Ajusta la mida de la font aquí
                  }
                }
              }
            };

            // per CAutarquica
            this.QuotaAutarquica = this.CuotasApi.QuotaAutarquica;
            this.QuotaAutarquicaNum = this.QuotaAutarquica * 100;
            this.QuotaAutarquicaNum = this.QuotaAutarquicaNum.toFixed(0);
            this.ComplementariQAutarquica = 100 - this.QuotaAutarquicaNum;

            this.chartDataAutarquica = {
              labels: [""],
              datasets: [
                {
                  label: "Autoconsum: " + this.QuotaAutarquicaNum.toString() + "%",
                  backgroundColor: "#0c4105c7",
                  hoverBackgroundColor: "#008f39",
                  data: [this.QuotaAutarquicaNum],
                },
                {
                  label: "Consumida xarxa: " + this.ComplementariQAutarquica.toString() + "%",
                  backgroundColor: "#B6FF9F",
                  hoverBackgroundColor: "#d1e3f7",
                  data: [this.ComplementariQAutarquica],
                },
              ],
            };

            this.chartOptionsAutarquica = {
              legend: {
                display: true,
                position: 'bottom',
                labels: {
                  fontSize: 14 // Ajusta la mida de la font aquí
                }
              }
            };

            console.log(this.QuotaAutarquicaNum);
    },
  },
};
</script>
<style scoped>


.barra1 {
  text-align: left;

}

.titulo {
  font-size: 15px;
}

#caracteristiques {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  border: none;
  font-size: 15px;
  text-align: center;
}

#caracteristiques td, #caracteristiques th {
  border: none;
  border-radius: 10px;
}

#caracteristiques tr:nth-child(even){
  background-color: #B6FF9F;
  font-weight: bold;
}

@media (max-width: 324.98px) {
  #caracteristiques {
    font-size: 10px;
  }
  .titulo {
    font-size: 10px;
  }
}

@media (min-width: 325px) and (max-width: 374.98px) {
    #caracteristiques {
    font-size:14px;
  }
  .titulo {
    font-size: 14px;
  }
}


@media (min-width: 375px) and (max-width: 575.98px) {
    #caracteristiques {
    font-size:16px;
  }
  .titulo {
    font-size: 16px;
  }
}

@media (min-width: 576px) and (max-width: 789.98px) {
    #caracteristiques {
    font-size: 18px;
  }
  .titulo {
    font-size: 18x;
  }
}


/* For medium devices (tablets, 768px to 991.98px wide) */
@media (min-width: 790px) and (max-width: 995.98px) {
    #caracteristiques {
    font-size: 16px;
  }

  .titulo {
    font-size: 16px;
  }

}

/* For large devices (desktops, 992px to 1199.98px wide) */
@media (min-width: 996px) and (max-width: 1199.98px) {
  #caracteristiques {
  font-size: 16px;
}
  .titulo {
    font-size: 12px;
  }
}

/* For extra-large devices (large desktops, 1200px and above) */
@media (min-width: 1200px) {
    #caracteristiques {
    font-size: 18px;
  }
    .titulo {
    font-size: 18px;
  }
}

</style>